import assert from 'assert'
import { Exclude, Expose, plainToInstance, Transform } from 'class-transformer'
import { IArticleDTO } from '@services/dtos/articles.dto'
import { ArticlesRepository } from '@services/repositories/articles.repository'
import { IArticle } from '@services/types'
import {
	ARTICLES_BASE_URL,
	ARTICLE_PARAM_KEY_KEYWORD,
	ARTICLE_PARAM_KEY_REQUEST
} from '@services/constants'

export class Article implements IArticle {
	@Expose({ name: 'id' })
	id: string = ''

	@Expose({ name: 'title' })
	title: string = ''

	@Expose({ name: 'content' })
	content: string = ''

	@Expose({ name: 'description' })
	description: string = ''

	@Expose({ name: 'language' })
	language: string = ''

	@Expose({ name: 'article_number' })
	number?: number

	@Expose({ name: 'incident_features' })
	@Transform(({ value }) =>
		value ? value.map((request) => request.incident_features_id) : []
	)
	requestIds: string[] = []

	constructor(articleDto: IArticleDTO) {
		this.parse(articleDto)
	}

	@Exclude()
	get link(): string {
		return ARTICLES_BASE_URL + this.id
	}

	public parse(dto: IArticleDTO) {
		Object.assign(this, plainToInstance(Article, dto))
	}

	@Exclude()
	static async fetchArticleByIdAsync(id: string): Promise<Article> {
		assert(id, 'The id field is mandatory to fetch the article')

		const repository = new ArticlesRepository()

		const articleDTO: IArticleDTO = await repository.getArticlesById(id)

		return new Article(articleDTO)
	}

	static async fetchAllArticlesAsync(): Promise<Article[]> {
		const repository = new ArticlesRepository()

		const articlesDTOs: IArticleDTO[] = await repository.getArticles()

		const articles: Article[] = articlesDTOs.map(
			(dto: IArticleDTO) => new Article(dto)
		)

		return articles ?? ([] as Article[])
	}

	static async searchArticlesByKeyword(keyword: string): Promise<Article[]> {
		const repository = new ArticlesRepository()

		const articlesDTOs: IArticleDTO[] = await repository.searchArticles(
			ARTICLE_PARAM_KEY_KEYWORD,
			keyword
		)

		const articles: Article[] = articlesDTOs.map(
			(dto: IArticleDTO) => new Article(dto)
		)

		return articles ?? ([] as Article[])
	}

	static async searchArticlesByRequest(requestId: string): Promise<Article[]> {
		const repository = new ArticlesRepository()

		const articlesDTOs: IArticleDTO[] = await repository.searchArticles(
			ARTICLE_PARAM_KEY_REQUEST,
			requestId
		)

		const articles: Article[] = articlesDTOs.map(
			(dto: IArticleDTO) => new Article(dto)
		)

		return articles ?? ([] as Article[])
	}
}
