import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IArticleSearchStore } from '@services/store/articles/type'

const initialState: IArticleSearchStore = {
	isSearchHeaderOpen: false,
	searchText: '',
	isPageArticles: false,
	searchResults: []
}

export const slice = createSlice({
	name: 'articleSearch',
	initialState,
	reducers: {
		doOpenSearchHeader: (state, action: PayloadAction<boolean>) => {
			state.isSearchHeaderOpen = action.payload
		},
		checkIfIsPageArticles: (state, action: PayloadAction<boolean>) => {
			state.isPageArticles = action.payload
		},
		fireSearch: (
			state,
			action: PayloadAction<Partial<IArticleSearchStore>>
		) => {
			Object.assign(state, action.payload)
		}
	}
})

export default slice.reducer

export const { doOpenSearchHeader, fireSearch, checkIfIsPageArticles } =
	slice.actions
