export default {
	authUserKey: process.env.LOCAL_STORAGE_USER_INFO || 'authUser',
	languageKey: process.env.LOCAL_STORAGE_LANGUAGE || 'language',
	electedTeamMembersKey:
		process.env.LOCAL_STORAGE_ELECTED_TEAM_MEMBERS || 'electedTeamMembers',
	authRedirect: process.env.LOCAL_STORAGE_AUTH_REDIRECT || 'authRedirect',
	activeTab: process.env.LOCAL_STORAGE_ACTIVE_PROFILE_TAB || 'activeTab',
	lastLoginDateKey:
		process.env.LOCAL_STORAGE_LAST_LOGIN_DATE || 'lastLoginDate',
	electorialDistrictsKey:
		process.env.LOCAL_STORAGE_ELECTORIAL_DISTRICT || 'electorialDistricts',
	paymentMessageKey:
		process.env.LOCAL_STORAGE_PAYMENT_MESSAGE || 'paymentMessage'
}
