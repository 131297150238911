import Breakpoints from '@utils/breakpoints'
import { ButtonStyle, Colors, Indexes } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { Article } from '@services/models/articles.model'
import config from '@utils/config'
import { Request } from 'typings/shared'

export type SearchProps = {
	isLoading: boolean
	isHeader?: boolean
	showClearTextIcon?: boolean
	isHomeScreen?: boolean
	onSetIsLoading: (loading: boolean) => void
	onSetRequests: (requests: Request[]) => void
	onNavigateToRequests: () => void
	searchText: string
	setSearchText: React.Dispatch<React.SetStateAction<string>>
	isSearchBoxOpen?: boolean
}

type SearchClassType = {
	form: string
	formHeader: string
	input: string
	btnActions: string
	submitBtn: string
	clearBtn: string
	homeForm: string
	clearBtnHomePage: string
}

export type RequestListCardProps = {
	id: number
	request: Request
	index: number
	requestThemes
	isHomePage?: boolean
	hasParentGroup?: boolean
	isCardOpen?: boolean
}

export const searchClasses: SearchClassType = makeClasses({
	form: {
		display: 'flex',
		alignItems: 'center',
		img: {
			width: '25px',
			[Breakpoints.maxWidth('md')]: {
				width: '20px'
			},
			[Breakpoints.maxWidth('sm')]: {
				width: '18px'
			}
		}
	},
	formHeader: {
		position: 'relative',
		input: {
			height: '80px',
			background: Colors.lightGrey
		}
	},
	submitBtn: {
		...ButtonStyle,
		background: Colors.secondary,
		borderColor: Colors.secondary,
		borderRadius: '50%',
		padding: '15px',
		marginRight: '10px',
		'&:hover': {
			cursor: 'pointer'
		}
	},
	input: {
		fontSize: '20px',
		flex: 1,
		height: '60px',
		border: 'none',
		background: Colors.white,
		borderBottom: `1px solid ${Colors.greyAlto}`,
		'&:focus': {
			border: 0,
			borderBottom: `1px solid ${Colors.greyAlto}`,
			outline: 'none !important',
			outlineWidth: '0 !important'
		},
		[Breakpoints.maxWidth('md')]: {
			fontSize: '18px'
		},
		[Breakpoints.maxWidth('xs')]: {
			fontSize: '16px'
		},
		'&::placeholder': {
			fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
			fontSize: '20px',
			color: Colors.darkGrey,
			[Breakpoints.maxWidth('md')]: {
				fontSize: '18px'
			},
			[Breakpoints.maxWidth('xs')]: {
				fontSize: '12px'
			}
		}
	},
	clearBtn: {
		...ButtonStyle,
		background: Colors.greyIron,
		borderColor: Colors.greyIron,
		borderRadius: '50%',
		padding: '6px',
		marginRight: '30px',
		cursor: 'pointer',
		img: {
			width: '20px'
		},
		'&:hover': {
			background: Colors.greyAlto,
			borderColor: Colors.greyAlto
		},
		[Breakpoints.maxWidth('sm')]: {
			marginRight: '15px'
		}
	},
	clearBtnHomePage: {
		marginRight: '8px',
		background: 'transparent',
		borderColor: '#8990ab',
		padding: '5px ',
		img: {
			width: '24px !important',
			marginRight: '0px !important'
		},
		'&:hover': {
			background: 'transparent',
			borderColor: Colors.greyAlto
		}
	},
	btnActions: {
		display: 'flex',
		alignItems: 'center',
		position: 'absolute',
		height: '100%',
		top: 0,
		right: 0
	},
	homeForm: {
		display: 'flex',
		padding: '0px',
		borderBottom: '1px solid #fff',
		maxWidth: 'calc((2/3)*100%)',
		marginTop: '20px',
		marginBottom: '-20px',
		[Breakpoints.maxWidth('xl')]: {
			maxWidth: '100%'
		},
		img: {
			width: '26px',
			opacity: '0.7',
			marginRight: '15px'
		},
		input: {
			fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
			color: Colors.white,
			fontSize: '20px',
			flex: 1,
			height: '60px',
			border: 'none',
			background: 'none',
			padding: '20px 10px',
			width: '100%',

			'&:focus': {
				border: 0,
				outline: 'none !important',
				outlineWidth: '0 !important'
			},
			[Breakpoints.maxWidth('md')]: {
				fontSize: '18px'
			},
			[Breakpoints.maxWidth('xs')]: {
				fontSize: '16px'
			},
			'&::placeholder': {
				fontFamily: 'Roboto,Arial,Helvetica,sans-serif',
				fontSize: '20px',
				color: Colors.lightGrey5,
				[Breakpoints.maxWidth('md')]: {
					fontSize: '18px'
				},
				[Breakpoints.maxWidth('xs')]: {
					fontSize: '12px'
				}
			}
		}
	}
})

export type ArticleCardProps = {
	article: Article
	index: number
}

type ArticleCardClassType = {
	root: string
	title: string
	content: string
}

export const articleCardClasses: ArticleCardClassType = makeClasses({
	root: {
		padding: '20px 0',
		marginBottom: '10px',
		borderBottom: `1px solid ${Colors.greyAlto}`,
		'&:hover': {
			cursor: 'pointer',
			h3: {
				textDecoration: 'underline'
			}
		}
	},
	title: {
		margin: 0,
		fontSize: '20px',
		fontWeight: 400,
		color: Colors.secondary
	},
	content: {
		marginTop: '10px',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '25px',
		color: Colors.lightBlack
	}
})

type SearchBoxClassType = {
	root: string
	searchBoxBtn: string
	searchBoxBtnOpen: string
	searchContainer: string
	display: string
	hide: string
	section: string
	btnSeeAllResults: string
	sectionMobile: string
	sectionBottom: string
	sectionNoRequestFound: string
}

export const searchBoxClasses: SearchBoxClassType = makeClasses({
	root: {
		position: 'relative',
		height: '100%'
	},
	searchBoxBtn: {
		...ButtonStyle,
		alignItems: 'center',
		border: 0,
		borderRadius: 0,
		height: '100%',
		padding: '0 20px',
		fontSize: '14px',
		transition: '0.5s',
		lineHeight: '60px',
		cursor: 'pointer',
		color: Colors.darkGrey,
		img: {
			width: '20px',
			marginRight: '10px'
		},
		[Breakpoints.maxWidth('laptop')]: {
			background: Colors.grey,
			marginRight: '15px',
			span: {
				display: 'none'
			},
			img: {
				marginRight: 0
			}
		},
		'&:hover': {
			background: Colors.greyAlto,
			color: Colors.darkGrey
		}
	},
	searchBoxBtnOpen: {
		background: Colors.secondary,
		color: Colors.white,
		padding: '0 25px',
		img: {
			marginRight: '0'
		},
		[Breakpoints.maxWidth('xl')]: {
			marginRight: '15px'
		},
		'&:before': {
			content: '""',
			position: 'absolute',
			background: Colors.blackTransparent50,
			top: '100%',
			left: `-${config.sidebar.sidebarWidth}`,
			width: '100vw',
			height: '200vh',
			[Breakpoints.minWidth('xxl')]: {
				left: `-${config.sidebar.sidebarWidthXXL}`
			},
			[Breakpoints.maxWidth('xl')]: {
				left: 0
			},
			[Breakpoints.maxWidth('laptop')]: {
				left: 0
			}
		}
	},
	searchContainer: {
		background: Colors.white,
		position: 'absolute',
		top: '100%',
		left: `-${config.sidebar.sidebarWidth}`,
		width: '100vw',
		[Breakpoints.minWidth('xxl')]: {
			left: `-${config.sidebar.sidebarWidthXXL}`
		},
		[Breakpoints.maxWidth('xl')]: {
			left: 0
		},
		[Breakpoints.maxWidth('laptop')]: {
			left: 0
		}
	},
	display: {
		display: 'block'
	},
	hide: {
		display: 'none'
	},
	section: {
		margin: '0 auto',
		width: '100%',
		maxWidth: `${Breakpoints.values.laptop}px`,
		padding: '30px',
		[Breakpoints.maxWidth('md')]: {
			padding: '30px 20px 15px'
		},
		[Breakpoints.minWidth('sidebar')]: {
			maxWidth: `${Breakpoints.values.laptop}px`
		},
		[Breakpoints.minWidth('sidebarlg')]: {
			maxWidth: `${Breakpoints.values.sidebar}px`
		},
		[Breakpoints.minWidth('sidebarxl')]: {
			maxWidth: `${Breakpoints.values.sidebarxl}px`
		}
	},
	btnSeeAllResults: {
		...ButtonStyle,
		marginTop: '30px',
		borderColor: Colors.secondary,
		background: Colors.secondary,
		color: Colors.white,
		padding: '15px 30px',
		fontSize: '16px',
		'&:hover': {
			cursor: 'pointer',
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2
		}
	},
	sectionMobile: {
		overflow: 'auto',
		maxHeight: 'calc(100vh - 200px)',
		[Breakpoints.maxWidth('md')]: {
			padding: '15px 20px 30px'
		},
		[Breakpoints.maxWidth('sm')]: {
			maxHeight: 'calc(100vh - 185px)'
		}
	},
	sectionBottom: {
		[Breakpoints.maxWidth('md')]: {
			paddingBottom: '30px'
		}
	},
	sectionNoRequestFound: {
		'& h3': {
			margin: '0px 0 15px '
		}
	}
})
