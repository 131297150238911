import Cookies from 'js-cookie'

const Buffer = require('buffer').Buffer

const COOKIE_NAME = 'password-protect'

export const setSessionPassword = (passwordCandidate) => {
	const base64Pass = Buffer.from(passwordCandidate).toString('base64')
	Cookies.set(COOKIE_NAME, base64Pass)
}

export const getSessionPassword = () => {
	const base64Pass = Cookies.get(COOKIE_NAME) || ''
	return Buffer.from(base64Pass, 'base64').toString()
}
