import Breakpoints from '@utils/breakpoints'
import { INavLink } from '@components/ui/sidebar'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import config from '@utils/config'

const logoHeightSiderbarXl: string = '203px'
const logoHeightSiderbar: string = '183px'
const logoHeightLg: string = '163px'

type Classes = {
	root: string
	logoRoot: string
	logoLink: string
	logo: string
	navWrap: string
	navList: string
	navItem: string
	navLinkHasChild: string
	navLink: string
	navLabel: string
	navImg: string
	navInfoWrap: string
	navInfoLink: string
	chevron: string
	chevronImg: string
	subNav: string
	subNavTitle: string
	subNavList: string
	subNavItem: string
	subNavLink: string
	subNavLabel: string
	navActive: string
}

export const classes: Classes = makeClasses({
	root: {
		position: 'fixed' as 'fixed',
		width: config.sidebar.sidebarWidth,
		height: '100vh',
		top: '0',
		left: '0',
		fontSize: '14px',
		background: '#ffffff',
		'z-index': 11,
		transform: 'translateZ(0)',
		transition: 'transform .6s cubic-bezier(.5,0,.3,1)',
		[Breakpoints.maxWidth('xl')]: {
			display: 'none'
		},
		[Breakpoints.minWidth('xxl')]: {
			width: config.sidebar.sidebarWidthXXL,
			fontSize: '16px'
		},
		[Breakpoints.minWidth('lg')]: {
			boxShadow: '2px 0 15px rgb(0 0 0 / 10%)'
		}
	},
	logoRoot: {
		[Breakpoints.maxWidth('xl')]: {
			display: 'none'
		},
		height: logoHeightLg,
		[Breakpoints.minWidth('sidebar')]: {
			height: logoHeightSiderbar
		},
		[Breakpoints.minWidth('sidebarxl')]: {
			height: logoHeightSiderbarXl
		}
	},
	logoLink: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%',
		textDecoration: 'none',
		background: 'transparent',
		border: 'none'
	},
	logo: {
		display: 'block',
		marginTop: '5px'
	},
	navWrap: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column' as 'column',
		justifyContent: 'space-between',
		height: `calc(100vh - ${logoHeightLg})`,
		overflowY: 'scroll',
		[Breakpoints.maxWidth('lg')]: {
			position: 'absolute' as 'absolute',
			top: '80px',
			right: 0,
			width: '100%',
			transformOrigin: 'center top',
			transition: 'transform .6s cubic-bezier(.5,0,.2,1),visibility 0s .6s',
			visibility: 'hidden' as 'hidden',
			overflowY: 'auto' as 'auto',
			transform: 'translateX(100%)',
			backgroundColor: '#ffffff',
			height: 'calc(100vh - 140px)',
			paddingBottom: '70px'
		},
		[Breakpoints.minWidth('sidebar')]: {
			height: `calc(100vh - ${logoHeightSiderbar})`
		},
		[Breakpoints.minWidth('sidebarxl')]: {
			height: `calc(100vh - ${logoHeightSiderbarXl})`
		}
	},
	navList: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column' as 'column',
		margin: 0,
		padding: 0,
		listStyle: 'none',
		width: '100%'
	},
	navItem: {
		borderBottom: '1px solid rgba(0,0,0,0.07)',
		lineHeight: 1,
		paddingLeft: 0,
		marginBottom: 0,
		width: '100%',
		[Breakpoints.minWidth('xxl')]: {
			fontSize: '14px !important'
		},
		'&:hover': {
			zIndex: 2,
			'& > div.sub_nav': {
				transform: 'scaleX(1)',
				transitionDelay: '0s'
			},
			'& .sub_nav_title': {
				fontFamily: "'Roboto'!important",
				opacity: 1,
				transform: 'none',
				transitionDelay: '0.1s',
				transitionDuration: '0.6s',
				transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
			},
			'& .sub_nav_link': {
				opacity: 1,
				transform: 'none',
				transitionDuration: '0.5s',
				transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
			}
		}
	},
	navLinkHasChild: {
		cursor: 'pointer',
		'&:after': {
			transform: 'translate(50%, -50%) rotate(45deg)',
			transitionDelay: '0.2s'
		},
		'&:before': {
			transform: 'scaleX(1)',
			transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
		},
		'& > button': {
			display: 'flex',
			flexDirection: 'row-reverse',
			alignItems: 'center',
			justifyContent: 'space-between'
		}
	},
	navLink: {
		display: 'block',
		textDecoration: 'none',
		background: 'transparent',
		border: 'none',
		color: Colors.primary,
		padding: '0',
		margin: 0,
		borderRadius: 0,
		width: '100%',
		overflow: 'hidden',
		textAlign: 'left',
		position: 'relative',
		'&:hover': {
			background: Colors.porcelainGrey
		},
		'&:before': {
			content: '""',
			position: 'absolute',
			transition: 'transform .3s cubic-bezier(.5,0,.3,1)'
		}
	},
	navLabel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		fontWeight: 400,
		transition: 'transform .3s cubic-bezier(0,0,.2,1)',
		lineHeight: '19px',
		// fontSize: '16px',
		padding: '19px 24px 19px 21px',
		fontFamily: "'Roboto'",
		color: Colors.primary,
		[Breakpoints.minWidth('xxl')]: {
			padding: '14px 22px !important'
		},
		'@media (max-height:740px) ': {
			padding: '10px 22px !important'
		},
		[Breakpoints.minWidth('sidebar')]: {
			lineHeight: '21px',
			padding: '25px 30px'
		},
		'&:hover': {
			paddingRight: '22px',
			transform: 'translate3d(10px,0,0)'
		}
	},
	navImg: {
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '10px',
		maxWidth: '22px',
		maxHeight: '22px',
		width: '100%',
		height: '100%',
		'@media (max-height:740px) ': {
			maxWidth: '18px'
		}
	},
	navInfoWrap: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
		width: '100%',
		maxWidth: '100%',
		padding: '19px 24px 19px 21px'
	},
	navInfoLink: {
		display: 'flex',
		alignItems: 'center',
		background: 'transparent',
		border: 'none',
		width: '100%',
		margin: 0,
		padding: '5px',
		color: '#0054a6',
		lineHeight: 'auto',
		fontWeight: 400,
		textDecoration: 'none',
		fontSize: '14px',
		[Breakpoints.minWidth('sidebar')]: {
			fontSize: '14px'
		},
		'&:hover': {
			textDecoration: 'underline',
			background: 'transparent',
			borderRadius: 'none'
		}
	},
	chevron: {
		float: 'right',
		padding: '30px'
	},
	chevronImg: {
		display: 'inline-flex',
		alignItems: 'center',
		maxWidth: '22px',
		maxHeight: '22px',
		height: '100%',
		width: '15px',
		verticalAlign: 'middle',
		marginRight: 0
	},
	subNav: {
		width: '100%',
		backgroundColor: '#222d65',
		borderRight: '1px solid rgba(255,255,255,.1)',
		transformOrigin: 'center left',
		transition: 'transform .3s cubic-bezier(.5,0,.3,1) .3s',
		color: '#fff',
		overflowX: 'hidden',
		overflowY: 'auto',
		WebkitOverflowScrolling: 'touch',
		boxShadow: 'inset 8px 0 15px rgba(0,0,0,0.2)',
		position: 'absolute',
		top: 0,
		left: '100%',
		height: '100vh',
		transform: 'scaleX(0)'
	},
	subNavTitle: {
		WebkitTextSizeAdjust: '100%',
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		listStyle: 'none',
		padding: '6rem 1.5rem 0',
		fontSize: '1.1rem',
		letterSpacing: '0.5px',
		margin: '0',
		fontWeight: 800,
		color: '#8db7e1',
		textTransform: 'uppercase',
		lineHeight: 1.3,
		height: logoHeightLg,
		[Breakpoints.minWidth('sidebar')]: {
			height: logoHeightSiderbar
		},
		[Breakpoints.minWidth('sidebarxl')]: {
			height: logoHeightSiderbarXl
		},
		display: 'flex',
		alignItems: 'center',
		opacity: 0,
		transform: 'translateX(-100%)',
		transition:
			'opacity 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19)'
	},
	subNavList: {
		WebkitTextSizeAdjust: '100%',
		lineHeight: 2,
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		color: '#fff',
		margin: '0',
		padding: '0',
		listStyle: 'none'
	},
	subNavItem: {
		WebkitTextSizeAdjust: '100%',
		lineHeight: 2,
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		color: '#fff',
		listStyle: 'none',
		borderBottom: '1px solid rgba(255,255,255,0.1)',
		transition: 'background-color .3s cubic-bezier(0,0,.2,1)',
		position: 'relative',
		fontSize: '1rem',
		padding: '1.325rem 1.5rem',
		'&:hover': {
			backgroundColor: Colors.whiteTransparent
		},
		[Breakpoints.minWidth('sidebar')]: {
			lineHeight: '21px',
			padding: '27px 30px'
		}
	},
	subNavLink: {
		WebkitTextSizeAdjust: '100%',
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		listStyle: 'none',
		fontSize: '14px',
		display: 'block',
		position: 'relative',
		color: '#fff',
		width: '100%',
		textAlign: 'left',
		opacity: 0,
		transform: 'translateX(-100%)',
		transition:
			'opacity .3s cubic-bezier(.55,.055,.675,.19),transform .3s cubic-bezier(.55,.055,.675,.19)',
		textDecoration: 'none',
		lineHeight: '18px',
		[Breakpoints.minWidth('sidebar')]: {
			fontSize: '16px'
		}
	},
	subNavLabel: {
		display: 'block',
		transition: 'transform .3s cubic-bezier(0,0,.2,1)'
	},
	navActive: {
		backgroundColor: Colors.ligthGrey2
	}
})

export type ISidebar = {
	navItems: INavLink[]
}
