import { JsonString } from '@services/types'
import { IAddressDTO } from '@services/dtos/addresses.dto'
import {
	IActivityLogItemDTO,
	IApplicantDTO
} from '@services/dtos/_miscellaneous.dto'
import { IInvoiceDTO } from './invoice.dto'

export interface IRequestConfigsDTO {
	property_type?: number
	equipment_type?: number
	on_the_waterside?: number
	dangerous?: number
	tax_information?: number
	speed_signaling_intervention?: number
	program_list?: number
	nature_gathering?: number
	collection_location?: number
	collection_sector?: number
	lodging_sector?: number
	container_provided_by_city?: number
	immediate_attention?: boolean
	emergency_type?: number
	new_bin?: number
	number_on_bin?: string
	container_collection?: number
	covered_sewer_intervention?: number
	place_type?: number
	location_place?: number
	unclean_origin?: number
	location_lighting?: number
	lighting_intervention?: number
	sector_off?: number
	signaling_intervention?: number
	signaling_type?: number
	dropped_stop_sign?: number
	harsh_signage?: number
	required_files?: File[]
	road_users_security?: number
	wish?: number
	subject: number
	// Form TN fields
	subvention_chosen?: number
	child_first_name?: string
	child_last_name?: string
	child_birth_date?: string
	adult_residant_count?: number
	children_residant_count?: number
	first_owners_phone_number?: string
	first_owners_email?: string
	first_owners_first_name?: string
	first_owners_last_name?: string
	second_owners_phone_number?: string
	second_owners_email?: string
	second_owners_first_name?: string
	second_owners_last_name?: string
	co_applicants?: ICoApplicantDTO[]
	applicant?: IApplicantDTO
	concerned_info?: IConcernedInfoDTO
	concerned_last_name?: 'string'
	concerned_organization_name?: 'string'
	concerned_first_name?: 'string'
	concernedHasLavalAdresse: 'boolean'
	concerned_address?: 'string'
	concerned_appartment?: 'string'
	concerned_email?: 'string'
	concerned_telephone?: 'string'
	concerned_city?: 'string'
	concerned_province?: 'string'
	concerned_postal_code?: 'string'
	concerned_country?: 'string'
	concerned_state?: 'string'

	business_name?: 'string'
	business_telephone?: 'string'
	business_email?: 'string'
	business_address?: 'string'
	business_appartment?: 'string'
	business_city?: 'string'
	business_postal_code?: 'string'
	business_state?: 'string'
	business_country?: 'string'
	vehicules?: IVehicleInformationDTO[]
	pesticide_certificats?: IPesticideCertificatesDTO[]

	building_address?: 'string'
}

export interface IRequestDTO {
	incident_id?: string
	case_origin_code: string
	customer_id: string
	incident_type_id: string
	overview: string
	description?: string
	created_on?: string
	modified_on?: string
	ticket_number?: string
	status_code_portal?: number
	cancel_reason?: number
	portal_metadata?: JsonString
	location?: IAddressDTO
	configs?: IRequestConfigsDTO
	additional_informations?: IAdditionalInformationDTO
	incident_form_id: string
	form_type_id: string
	name?: string
	applicant: IApplicantDTO
	incident_form: IRequestConfigsDTO
	invoice: IInvoiceDTO
}

export interface IRequestConsentDTO {
	consent_id?: string
	is_followed: boolean
	source: number
	incident_id: string
	elected_team_id?: string
	customer_id: string
	created_on?: string
	revoked_on?: string
}

export interface IRequestMessageDTO {
	id: string
	subject: string
	description: string
	created_on: string
}

export interface IRequestElectTeamDTO {
	id: string
	subject: string
	description: string
}

export interface IRequestAttachmentDTO {
	incident_id: string
	file: File
}

export type RequestPartialsDTO =
	| IRequestMessageDTO[]
	| IRequestConsentDTO[]
	| IActivityLogItemDTO[]

// @TODO -> TO CHECK
export const REQUEST_FIELDS_SET = new Set([
	'incident_id',
	'customer_id',
	'incident_type_id',
	'overview',
	'description',
	'apartmentOrOffice',
	'created_on',
	'modified_on',
	'ticket_number',
	'status_code_portal',
	'cancel_reason',
	'portal_metadata',
	'form_type_id',
	'incident_form_id'
])

export type IAdditionalInformationDTO = {
	unread_emails: number
}

export interface IRequestNumericTransactionDTO {
	id: string
	incident_id: string
	incident_form_id: string
	subject: string
	description: string
	category: string
}

export interface IRequestIncidentFormDTO extends IRequestConfigsDTO {
	applicant?: IApplicantDTO
	incident_id: string
	form_id: string
}
export interface ICoApplicantDTO {
	first_name: string
	last_name: string
	telephone: string
	email: string
}

export interface IConcernedInfoDTO {
	organization_name: string
	first_name: string
	last_name: string
	telephone: string
	email: string
	address: string
	appartment: string
	city: string
	postal_code: string
	state: string
	country: string
}

export interface IPlantedTreeDTO {
	species: string
	diameter: number
	cost: number
	location: string
}

export interface IPesticideCertificatesDTO {
	holder_name: string
	certificat_number: string
	category: string
	issue_date: string
	expiration_date: string
}

export interface IVehicleInformationDTO {
	brand: string
	model: string
	year: string
	color: string
	registration_number: string
	business_identified: boolean
}
