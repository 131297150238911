import React, { FC, useContext, useEffect, useRef } from 'react'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { LanguageEnum } from '@services/constants'

type ClassType = {
	root: string
	dropdown: string
	dropdownItem: string
	langSelected: string
}

const classes: ClassType = makeClasses({
	root: {
		position: 'relative' as 'relative',
		height: '60px',
		width: '60px',
		alightItems: 'center',
		transition: 'ease-in 0.3s',
		'&:hover': {
			background: 'transparent',
			boxShadow: `inset 0 -4px 0 0 ${Colors.lightBlue}`,
			transition: 'ease-in 0.3s'
		}
	},
	dropdown: {
		display: 'none',
		position: 'absolute' as 'absolute',
		top: '70px',
		left: 0,
		flexDirection: 'column' as 'column',
		marginTop: '0px',
		backgroundColor: Colors.white,
		width: '100%',
		overflow: 'auto' as 'auto',
		boxShadow: '0px 8px 16px 0px rgb(0 0 0 / 15%)',
		'z-index': 1,
		['&.dropdownActive']: {
			display: 'flex'
		}
	},
	dropdownItem: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '10px 0',
		width: '100%',
		cursor: 'pointer',
		color: Colors.darkGrey,
		'&:hover': {
			background: Colors.porcelainGrey
		}
	},
	langSelected: {
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center' as any,
		justifyContent: 'center',
		width: '100%',
		height: '100%',
		cursor: 'pointer',
		color: Colors.darkGrey,
		'&:hover': {
			background: Colors.ligthGrey2
		}
	}
})

const LanguageSwitcher: FC = () => {
	const appContext = useContext<AppContextProps>(AppStateContext)

	const refDropdownContainer = useRef<HTMLDivElement>(null)
	const refDropdown = useRef<HTMLDivElement>(null)

	const onSelectLang = (langSelected: LanguageEnum) => {
		if (langSelected === LanguageEnum.EN) {
			appContext.setLanguage(LanguageEnum.FR as LanguageEnum)
			return
		}

		appContext.setLanguage(LanguageEnum.EN as LanguageEnum)
	}

	const onToggleDropdown = () =>
		refDropdown.current?.classList.toggle('dropdownActive')

	const onHandleOnClose = (e) => {
		if (
			refDropdownContainer &&
			!refDropdownContainer.current?.contains(e.target)
		) {
			refDropdown.current?.classList.remove('dropdownActive')
		}
	}

	const displayLang = (lang: LanguageEnum): string => {
		if (lang === LanguageEnum.EN)
			return String(LanguageEnum.FR).split('-')[0].toUpperCase()

		return LanguageEnum.EN.toUpperCase()
	}

	useEffect(() => {
		document.addEventListener('mousedown', onHandleOnClose)

		return () => {
			// Cleanup the event listener
			document.removeEventListener('mousedown', onHandleOnClose)
		}
	}, [])

	return (
		<div className={classes.root} ref={refDropdownContainer}>
			<div
				className={classes.langSelected}
				onClick={() => onSelectLang(appContext.language as LanguageEnum)}
			>
				{displayLang(appContext.language as LanguageEnum)}
			</div>
		</div>
	)
}
export default LanguageSwitcher
