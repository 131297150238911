import Breakpoints from '@utils/breakpoints'
import { ButtonStyle, Colors, Indexes } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	leftRoot: string
	root: string
	leftRootlogo: string
	leftRootlogoLink: string
	searchBox: string
	leftFormSearchRoot: string
	leftFormSubmit: string
	leftFormInput: string
	centerMobileInput: string
	mobileCenterMobileInput: string
	rightRoot: string
	rightRootSearchRoot: string
	Badge: string
	rightRootLink: string
	rightRootIcon: string
	bellRootIcon: string
	rightRootIconRoot: string
	rightRootUserRootLabel: string
	rightRootUserRootIcon: string
	rightRootUserRootLink: string
	dropdown: string
	dropdownLink: string
	dropdownLinkLogout: string
	userText: string
	chevronDownIcon: string
	logoLeftIcon: string
	userWrapper: string
	searchButton: string
	centerSearch: string
	centerSearchWrapper: string
	buttonWrapper: string
	buttonSearch: string
	searchBottomIcon: string
	rightRootSearchIcon: string
	button: string
	btnSignIn: string
	btnCreateAccount: string
	rootIndex: string
	dropdownItem: string
}

export const classes: ClassType = makeClasses({
	button: {
		...ButtonStyle,
		padding: '10px 20px',
		fontSize: '12px',
		'&:hover': {
			color: Colors.white,
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2
		}
	},
	btnSignIn: {
		color: Colors.white,
		background: Colors.secondary,
		borderColor: Colors.secondary,
		marginRight: '20px',
		'&:active': {
			color: Colors.white
		},
		'&:visited': {
			color: Colors.white
		}
	},
	btnCreateAccount: {
		color: Colors.secondary,
		borderColor: Colors.secondary,
		margin: '0 10px',
		[Breakpoints.maxWidth('sm')]: {
			display: 'none'
		}
	},
	rootIndex: {
		zIndex: Indexes.index5
	},
	root: {
		position: 'fixed' as 'fixed',
		top: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		background: Colors.greyIron,
		color: Colors.darkBlue,
		width: '100%',
		height: '60px',
		[Breakpoints.widthBetween('xl', 'xxl')]: {
			left: '13rem',
			maxWidth: 'calc(100% - 13rem)'
		},
		[Breakpoints.minWidth('xxl')]: {
			left: '17rem',
			maxWidth: 'calc(100% - 17rem)'
		}
	},
	centerSearch: {
		display: 'none',
		[Breakpoints.maxWidth('md')]: {
			display: 'none',
			position: 'fixed' as any,
			top: '60px',
			left: 0,
			width: '100%',
			height: '60px',
			justifyContent: 'center',
			'&.mobile-search-active': {
				display: 'flex'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			display: 'none',
			top: '60px',
			left: '2.5%',
			color: Colors.white,
			width: '95%',
			'&.mobile-search-active': {
				display: 'flex'
			}
		}
	},
	centerSearchWrapper: {
		[Breakpoints.maxWidth('md')]: {
			margin: 'auto',
			backgroundColor: Colors.white,
			width: '400px',
			height: '100%',
			display: 'flex',
			alignItems: 'center',
			padding: '16px',
			borderRadius: '0.25rem',
			boxShadow: '0 1rem 3rem rgb(0 0 0 / 18%)'
		},
		[Breakpoints.maxWidth('sm')]: {
			display: 'flex',
			top: '60px',
			left: '2.5%',
			color: Colors.white,
			width: '95%'
		}
	},
	leftRoot: {
		display: 'flex',
		height: '100%',
		alignItems: 'center'
	},
	leftRootlogo: {
		height: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	leftRootlogoLink: {
		display: 'flex',
		textDecoration: 'none',
		[Breakpoints.minWidth('xl')]: {
			display: 'none'
		}
	},
	searchBox: {
		position: 'relative' as any,
		alignItems: 'center',
		width: '100%',
		display: 'flex'
	},
	leftFormSearchRoot: {
		display: 'none',
		padding: '11px 25px',
		[Breakpoints.minWidth('md')]: {
			position: 'relative' as 'relative',
			display: 'flex',
			alignItems: 'center',
			maxWidth: '470px',
			width: '100%',
			minWidth: '470px'
		}
	},
	leftFormSubmit: {
		position: 'absolute' as 'absolute',
		background: 'transparent',
		right: 0,
		top: 0,
		padding: 0,
		margin: 0,
		border: 'none',
		visibility: 'hidden' as 'hidden',
		width: '80px',
		height: '80px',
		opacity: 0,
		transition: 'visibility 0.3s linear, opacity 0.3s linear',
		'&.btn-submit-active': {
			visibility: 'visible' as 'visible',
			transition: 'ease-in 0.2s',
			opacity: 1
		}
	},
	leftFormInput: {
		boxSizing: 'border-box' as any,
		margin: 0,
		fontFamily: 'inherit',
		display: 'block',
		padding: '7.5px 1rem',
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: 1.5,
		color: Colors.darkBlue,
		backgroundClip: 'padding-box',
		width: '350px',
		border: 'none' as any,
		height: '38px',
		paddingRight: '20px',
		backgroundColor: Colors.white,
		boxShadow: 'none',
		borderRadius: '30px'
	},
	centerMobileInput: {
		backgroundColor: Colors.greyIron,
		width: '480px',
		boxSizing: 'border-box' as any,
		margin: 0,
		height: '38px',
		fontFamily: 'inherit',
		display: 'block',
		padding: '7.5px .75rem',
		fontSize: '.8125rem',
		fontWeight: 400,
		lineHeight: 1.5,
		color: Colors.darkBlue,
		backgroundClip: 'padding-box',
		border: 'none' as any,
		paddingRight: '20px',
		boxShadow: 'none',
		borderRadius: '30px'
	},
	mobileCenterMobileInput: {
		backgroundColor: Colors.white
	},
	rightRoot: {
		display: 'flex',
		alignItems: 'center'
	},
	rightRootSearchRoot: {
		[Breakpoints.minWidth('lg')]: {
			display: 'none'
		}
	},
	Badge: {
		display: 'inline-block',
		padding: '.25em .4em',
		fontSize: '65%',
		fontWeight: 500,
		lineHeight: 1,
		color: Colors.white,
		textAlign: 'center' as any,
		whiteSpace: 'nowrap' as any,
		verticalAlign: 'baseline',
		backgroundColor: Colors.purple,
		borderRadius: '50rem !important',
		paddingLeft: '5px',
		paddingRight: '5px',
		position: 'absolute' as any,
		top: '9px',
		marginLeft: '25px'
	},
	rightRootLink: {
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center'
	},
	rightRootIcon: {
		width: '25px'
	},
	bellRootIcon: {
		width: '30px'
	},
	rightRootIconRoot: {
		display: 'flex',
		alignItems: 'center',
		textAlign: 'center' as any,
		borderRadius: 0,
		height: '60px',
		padding: '0.47rem 0.75rem',
		cursor: 'pointer',
		color: Colors.primary,
		'&:hover': {
			background: Colors.ligthGrey2
		}
	},
	rightRootUserRootLabel: {
		paddingRight: '15px',
		fontSize: '14px',
		[Breakpoints.maxWidth('lg')]: {
			display: 'none'
		}
	},
	rightRootUserRootIcon: {
		width: '25px'
	},
	rightRootUserRootLink: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 15px',
		color: Colors.darkGrey,
		textDecoration: 'none',
		[Breakpoints.minWidth('sm')]: {
			padding: '0 30px 0 0'
		}
	},
	dropdown: {
		display: 'none' as 'none',
		padding: '.5rem 0',
		fontSize: '16px',
		color: Colors.darkBlue,
		textAlign: 'left' as 'left',
		listStyle: 'none',
		backgroundColor: Colors.white,
		backgroundClip: 'padding-box',
		borderRadius: '.25rem',
		boxShadow: `0 5px 15px ${Colors.blackTransparent10}`,
		zIndex: 1000,
		left: 'auto !important',
		right: '10px !important',
		position: 'absolute' as 'absolute',
		inset: '0px 0px auto auto',
		margin: 0,
		transform: 'translate3d(0.328125px, 60px, 0)',
		transition: 'all .5s ease-in-out',
		['&.dropdownActive']: {
			display: 'block'
		}
	},
	dropdownLink: {
		display: 'flex',
		alignItems: 'center',
		color: Colors.black,
		padding: '17px 24px',
		fontSize: '15px',
		height: '45px',
		textDecoration: 'none',
		'&:hover': {
			backgroundColor: Colors.porcelainGrey
		}
	},
	dropdownItem: {
		marginBottom: '10px'
	},
	dropdownLinkLogout: {
		borderTop: `1px solid ${Colors.greyIron}`,
		marginBottom: 'unset'
	},
	userText: {
		fontSize: '0.8em',
		textDecoration: 'none',
		color: Colors.darkGrey,
		padding: '5px'
	},
	chevronDownIcon: { width: '12px' },
	logoLeftIcon: { width: '84px' },
	userWrapper: {
		[Breakpoints.maxWidth('lg')]: {
			display: 'none'
		}
	},
	searchButton: {
		width: '50px'
	},
	buttonWrapper: {
		marginLeft: '-38px'
	},
	buttonSearch: {
		background: Colors.secondary,
		borderRadius: '50%',
		width: '38px',
		height: '38px',
		border: 'none',
		cursor: 'pointer'
	},
	searchBottomIcon: {
		height: '24px',
		verticalAlign: 'middle'
	},
	rightRootSearchIcon: {
		[Breakpoints.minWidth('md')]: {
			display: 'none'
		}
	}
})
