import React, { FC } from 'react'
import parse from 'html-react-parser'
import { formatHtmlForStrapiText } from '@utils/methods'

type Props = {
	// can be of type : markdowm, html or string
	content?: string
}

const Text: FC<Props> = ({ content }) => {
	return <>{parse(formatHtmlForStrapiText(content))}</>
}

export default Text
