import assert from 'assert'
import { IContactDTO } from '@services/dtos/contacts.dto'
import {
	GENDERS,
	GenderEnum,
	LANGUAGES,
	LanguageEnum,
	LANGUAGES_DICTIONARY,
	GENDERS_DICTIONARY,
	CommunicationMeanEnum,
	COMMINUCATION_MEANS,
	COMMUNICATION_MEAN_DICTIONARY
} from '@services/constants'
import { IContact } from '@services/types'
import { AccountInfo } from '@azure/msal-browser'
import { capitalize } from '@utils/methods'
import {
	Expose,
	Transform,
	plainToInstance,
	instanceToPlain,
	Exclude,
	TransformFnParams
} from 'class-transformer'
import { Address } from '@services/models/addresses.model'
import ContactsRepository from '@services/repositories/contacts.repository'
import { EMAIL_NOTIFICATION_OPTION } from '@services/constants'

export class Contact implements IContact {
	@Expose({ name: 'contact_id' })
	id: string | null = null

	@Expose({ name: 'external_user_identifier' })
	identityId: AccountInfo['localAccountId'] = ''

	@Expose({ name: 'salutation' })
	@Transform(({ value }) => (GENDERS[value] as GenderEnum) ?? GenderEnum.FEMALE)
	gender: GenderEnum = GenderEnum.FEMALE

	@Expose({ name: 'first_name' })
	firstName: string = ''

	@Expose({ name: 'last_name' })
	lastName: string = ''

	@Expose({ name: 'phone_daytime' })
	@Transform(
		(options) =>
			Contact.formatPhoneNumberWithMail(options, 'phoneNumber', 'mail'),
		{ toPlainOnly: true }
	)
	@Transform(
		(options) =>
			Contact.formatPhoneNumberMailValue(options, 'phone_daytime', 0),
		{ toClassOnly: true }
	)
	phoneNumber: string = ''

	@Expose({ name: 'phone_evening' })
	@Transform(
		(options) =>
			Contact.formatPhoneNumberWithMail(options, 'phoneNumberEvening', 'mail2'),
		{ toPlainOnly: true }
	)
	@Transform(
		(options) =>
			Contact.formatPhoneNumberMailValue(options, 'phone_evening', 0),
		{ toClassOnly: true }
	)
	phoneNumberEvening: string = ''

	@Expose({ name: 'phone_other' })
	@Transform(
		(options) =>
			Contact.formatPhoneNumberWithMail(options, 'phoneNumberOther', 'mail3'),
		{ toPlainOnly: true }
	)
	@Transform(
		(options) => Contact.formatPhoneNumberMailValue(options, 'phone_other', 0),
		{ toClassOnly: true }
	)
	phoneNumberOther: string = ''

	@Expose({ name: 'email' })
	email: string = ''

	@Expose({ name: 'email_notification_option' })
	emailNotification: boolean = EMAIL_NOTIFICATION_OPTION.YES

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'mail' })
	@Transform(
		(options) =>
			Contact.formatPhoneNumberMailValue(options, 'phone_daytime', 1, 'mail'),
		{ toClassOnly: true }
	)
	mail?: string = ''

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'mail2' })
	@Transform(
		(options) =>
			Contact.formatPhoneNumberMailValue(options, 'phone_evening', 1, 'mail'),
		{ toClassOnly: true }
	)
	mail2?: string = ''

	@Exclude({ toPlainOnly: true })
	@Expose({ name: 'mail3' })
	@Transform(
		(options) =>
			Contact.formatPhoneNumberMailValue(options, 'phone_other', 1, 'mail'),
		{ toClassOnly: true }
	)
	mail3?: string = ''

	@Expose({ name: 'personal_infos' })
	personalInfos: string = ''

	/**
	 * @function formatPhoneNumberMailValue
	 * @param object is the options from the class transformer
	 * @param field is the phone number field that that need to be splitted
	 * @param rank is the position of the wanted element
	 * @returns string
	 */

	@Exclude()
	static formatPhoneNumberMailValue = (
		object: TransformFnParams,
		field: string,
		rank: number,
		target?: string
	) => {
		if (object.obj[field] && object.obj[field].includes(',')) {
			return object.obj[field]?.split(',')[rank]?.trim()
		}

		return target == 'mail' ? '' : object.obj[field]
	}

	/**
	 * @function formatPhoneNumberWithMail
	 * @param object is the options from the class transformer
	 * @param field is the phone number field that that need to be formatted with mail
	 * @param mail is mail field that will be combined
	 * @returns string
	 */

	@Exclude()
	static formatPhoneNumberWithMail = (
		object: TransformFnParams,
		field: string,
		mail: string
	) => {
		if (object.obj[mail]) {
			return `${object.obj[field]}, ${object.obj[mail]}`
		}

		return object.obj[field]
	}

	@Expose({ name: 'preferred_contact_method' })
	@Transform(
		({ value }) => {
			if (value === COMMUNICATION_MEAN_DICTIONARY['mon-dossier']) {
				return CommunicationMeanEnum.MON_DOSSIER
			}

			return (
				(COMMINUCATION_MEANS[value] as CommunicationMeanEnum) ??
				CommunicationMeanEnum.NO_PREFERENCE
			)
		},
		{ toClassOnly: true }
	)
	preferredContactMethod: CommunicationMeanEnum =
		CommunicationMeanEnum.MON_DOSSIER

	@Expose({ name: 'language' })
	@Transform(
		({ value }) => (LANGUAGES[value] as LanguageEnum) ?? LanguageEnum.FR
	)
	language: LanguageEnum = LanguageEnum.FR

	@Expose({ name: 'location' })
	@Transform(({ value }) => new Address(value), { toClassOnly: true })
	@Transform(({ value }) => Address.serialize(value), {
		toPlainOnly: true
	})
	address: Address

	@Exclude()
	_repository: ContactsRepository

	get fullName(): string | null {
		let fullName: string | null = null

		if (this.firstName || this.lastName) {
			fullName = ''

			if (this.firstName) {
				fullName += capitalize(this.firstName)
			}

			if (this.lastName) {
				fullName += ' ' + capitalize(this.lastName)
			}

			fullName.trim()
		}

		return fullName
	}

	constructor(contactDTO?: IContactDTO) {
		let address = new Address()

		if (contactDTO) {
			const contact: Contact | null = Contact.parse(contactDTO)

			Object.assign(this, contact)

			if (contact.address) {
				address = contact.address
			}
		}

		this.address = address

		this._repository = new ContactsRepository()
	}

	static parse = (dto: IContactDTO): Contact => {
		return plainToInstance(Contact, dto)
	}

	static serialize = (model: Contact): IContactDTO => {
		const dto = instanceToPlain(model)

		if (model?.language) {
			dto.language = LANGUAGES_DICTIONARY[model.language] || 1
		}

		if (model?.gender) {
			dto.salutation = GENDERS_DICTIONARY[model.gender] || 1
		}

		if (model?.preferredContactMethod) {
			dto.preferred_contact_method =
				COMMUNICATION_MEAN_DICTIONARY[model.preferredContactMethod] ||
				COMMUNICATION_MEAN_DICTIONARY['mon-dossier']
		}

		return dto
	}

	@Exclude()
	public applyAccountValues(account: AccountInfo) {
		this.email = account.username
		this.identityId = account.localAccountId
	}

	@Exclude()
	public async saveAsync() {
		assert(this.email, 'Can not save a Contact without a valid email address')

		const dto = Contact.serialize(this)

		assert(!!dto, 'Can not save empty contact')

		if (this.id) {
			await this._repository.patchContact(this.id, dto)

			return
		}

		this.id = await this._repository.postContact(dto)
	}
}
