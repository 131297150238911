import React, { FC, ReactNode } from 'react'
import { makeStyles } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import { Global } from '@emotion/react'

const styles: { root: string } = makeStyles({
	root: `
		body {
			background-color: ${Colors.ligthGrey2};
		}
		* {
			box-sizing: border-box;
		}
	`
})

type Props = {
	children: ReactNode
	wrapperClass?: string
}

const LayoutRequestWithProvider: FC<Props> = ({ children, wrapperClass }) => {
	return (
		<div className={wrapperClass}>
			<Global styles={styles.root} />
			{children}
		</div>
	)
}

export default LayoutRequestWithProvider
