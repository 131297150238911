import assert from 'assert'
import { AccountInfo } from '@azure/msal-common'
import { Exclude, instanceToPlain } from 'class-transformer'
import {
	IContact,
	IRequestsRepository,
	IUser,
	IActivityLogRepository
} from '@services/types'
import { IUserDTO } from '@services/dtos/users.dto'
import UsersRepository from '@services/repositories/users.repository'
import { Contact } from '@services/models/contacts.model'
import { IContactDTO } from '@services/dtos/contacts.dto'
import { Request } from '@services/models/requests.model'
import RequestsRepository from '@services/repositories/requests.repository'
import { IRequestDTO } from '@services/dtos/requests.dto'
import { ActivityLogRepository } from '@services/repositories'
import { IActivityLogItemDTO } from '@services/dtos/_miscellaneous.dto'
import { ActivityLogItem } from '@services/models/_miscellaneous.model'
import { RequestPartialsEnum } from '@services/constants'

// TODO: add namespace accross multiple files namespace Models {
export class User implements IUser {
	// TODO: write a dto for the AccountInfo Object
	@Exclude()
	identity: AccountInfo

	@Exclude()
	profile: Contact | null = null

	@Exclude()
	private _hasProfile: boolean = false

	@Exclude()
	private _requestsRepository?: IRequestsRepository

	@Exclude()
	private _activityLogRepository?: IActivityLogRepository

	@Exclude()
	private _myRequests?: Request[]

	@Exclude()
	private _myRequestsMap: Map<string, Request>

	constructor(identity: AccountInfo, profile?: Contact | null) {
		if (!profile || !User.isValideProfile(profile)) {
			throw new Error(
				'Cannot instanciate a User without a valid profile; call the build function instead'
			)
		}

		this._myRequestsMap = new Map<string, Request>()
		this._requestsRepository = new RequestsRepository()
		this._activityLogRepository = new ActivityLogRepository()
		this.identity = identity
		this.profile = profile
		this._hasProfile = true
	}

	@Exclude()
	static buildAsync = async (
		identity: AccountInfo,
		profile?: Contact | null
	): Promise<User> => {
		let user: User
		const repository = new UsersRepository()

		if (!profile || !User.isValideProfile(profile)) {
			const contactDTO = await repository.getProfile(identity.localAccountId)

			user = new User(identity, new Contact(contactDTO))
		} else {
			user = new User(identity, profile)
		}

		await user.fetchRequests()

		return user
	}

	// TODO: to update with class-validator and json-rule-engines
	@Exclude()
	static isValideProfile = (profile: IContact): boolean => {
		// validate profile
		return true
	}

	@Exclude()
	get hasProfile(): boolean {
		return this._hasProfile
	}

	/**
	 * Summary: Fetches all the requests created by the current user.
	 *
	 * Description: Fetches all the requests sharing the field customer_id.
	 *
	 * @since      0.3.9
	 * @since      0.4.4                  added param withPartials.
	 * @param      {boolean} withPartials also fetch the partials of the requests.
	 * @access     public
	 *
	 * @return {Promise<IRequest[]>}      Promise of list of Requests instances.
	 */
	@Exclude()
	public async fetchRequests(
		withPartials: boolean = false,
		partial?: RequestPartialsEnum
	): Promise<Request[]> {
		assert(
			this.hasProfile && this.profile?.id,
			'Can not fetch requests for a user not having a profile'
		)

		const requestsDTOList: IRequestDTO[] | undefined =
			await this._requestsRepository?.searchRequestsBy(
				'customerId',
				this.profile.id,
				true
			)

		const requests: Request[] | undefined = requestsDTOList?.map(
			(requestDTO) => new Request(this, requestDTO)
		)

		if (withPartials && requests) {
			for (const request of requests) {
				await Request.updatePartials(request)
			}
		}

		if (partial && requests) {
			for (const request of requests) {
				await Request.getPartial(request, partial)
			}
		}

		this._myRequests = requests

		requests?.forEach((request) => {
			if (request.id) {
				this._myRequestsMap.set(request.id, request)
			}
		})

		return requests ?? ([] as Request[])
	}

	@Exclude()
	public async fetchRecentActivities(): Promise<ActivityLogItem[]> {
		assert(
			this.hasProfile && this.profile?.id,
			'Can not fetch recent activities for a user not having a profile'
		)

		const recentActivitiesDTOList: IActivityLogItemDTO[] | undefined =
			await this._activityLogRepository?.searchActivitiesByResourceIdAsync(
				'customer_id',
				this.profile.id
			)

		const recentActivities: ActivityLogItem[] | undefined =
			recentActivitiesDTOList?.map(
				(recentActivitiesDTO) => new ActivityLogItem(recentActivitiesDTO)
			)

		return recentActivities ?? ([] as ActivityLogItem[])
	}

	@Exclude()
	get requests(): Request[] {
		return this._myRequests ?? ([] as Request[])
	}

	@Exclude()
	public getRequest(requestId: string): Request | undefined {
		return this._myRequestsMap.get(requestId)
	}

	@Exclude()
	public setRequest(request: Request): void {
		if (request.id) {
			this._myRequestsMap.set(request.id, request)
		}
	}

	@Exclude()
	public hasRequest(requestId: string): boolean {
		return this._myRequestsMap.has(requestId)
	}

	@Exclude()
	static parse = async (userDTO: IUserDTO): Promise<User> => {
		const user = await User.buildAsync(
			userDTO.identity,
			// userDTO.profile ? Contact.parse(userDTO.profile) : null
			null
		)

		return user
	}

	@Exclude()
	static serialize = (user: User): IUserDTO => {
		const profile: IContactDTO = user.profile
			? Contact.serialize(user.profile)
			: {}
		const identity = instanceToPlain(user.identity) as AccountInfo

		return { profile, identity }
	}
}
