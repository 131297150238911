import React, {
	FC,
	FormEvent,
	RefObject,
	useContext,
	useEffect,
	useRef,
	useState
} from 'react'
import { Icon } from '@components/ui/Icon'
import { closeGreyIcon, magnifierWhiteIcon } from '@images/icons'
import * as pageUtils from '@components/ui/requestSearch/__requestSearch.utils'
import {
	getIsSearchHeaderOpen,
	getSearchText,
	getIsPageArticles
} from '@services/store/articles/selector'
import { useAppDispatch, useAppSelector } from '@services/store'
import { fireSearch } from '@services/store/articles'
import { formatStrapiText } from '@utils/methods'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { Collapse } from 'react-collapse'
import { joinClasses } from '@utils/styles'
import { debounce } from 'lodash'
import axios from 'axios'
import {
	numberMaxOfRequest,
	REQUEST_HIDDEN,
	strapiDataRes
} from '@pages/auth/requests/create/__index.utils'

const SearchRequests: FC<pageUtils.SearchProps> = ({
	isLoading,
	isHeader,
	showClearTextIcon,
	isHomeScreen,
	onSetIsLoading,
	onSetRequests,
	onNavigateToRequests,
	searchText,
	setSearchText,
	isSearchBoxOpen
}) => {
	const { pageData, language } = useContext<AppContextProps>(AppStateContext)
	const dispatch = useAppDispatch()

	const isPageArticles: boolean = useAppSelector(getIsPageArticles)

	const inputRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (searchText == '') {
			clearSearchText()
			return
		}

		fetchRequests()
	}, [searchText, language])

	const loadRequestsDebounce = debounce((e) => {
		let search = e.target.value
		if (search.length > 2 || search.length === 0) {
			setSearchText(search.toLowerCase())
		}
	}, 1000)

	const multiSearchOr = (text, searchWords) => {
		// create a regular expression from searchwords using join and |. Add "gi".
		// Example: ["ANY", "UNATTENDED","HELLO"] becomes
		// "ANY|UNATTENDED|HELLO","gi"
		// "|" means OR. "gi" means GLOBALLY and CASEINSENSITIVE
		var searchExp = new RegExp(searchWords.join('|'), 'gi')
		// regularExpression.test(string) returns true or false
		return searchExp.test(text)
	}

	const fetchRequests = () => {
		onSetIsLoading(true)

		axios
			.get(
				`${process.env.STRAPI_API}/api/ptc-requests?pagination%5BpageSize%5D=${numberMaxOfRequest}`
			)
			.then(async (res) => {
				const data = res.data as strapiDataRes

				let searchWords = searchText
					.split(' ')
					.filter((word) => word.length > 2)

				const hiddenRequestList = data.data.map((request) => {
					if (REQUEST_HIDDEN[request.attributes.Cacher])
						return request.attributes.request_id
				})

				let requests: any[] = []
				pageData?.collections?.requestThemes.forEach((group) => {
					group.requests.forEach((request) => {
						if (
							!hiddenRequestList.includes(request.requestId) &&
							(multiSearchOr(request.title, searchWords) ||
								multiSearchOr(request.description, searchWords)
								|| multiSearchOr(request?.tags, searchWords)
							)
						) {
							requests.push(request)
						}
					})
				})

				onSetRequests(requests)

				dispatch(
					fireSearch({
						searchText
					})
				)

				triggerSearchBoxToggleAction()
			})
			.catch((e) => {
				onSetRequests([])
			})
			.finally(() => setTimeout(() => onSetIsLoading(false), 500))
	}

	const onClearSearchText = (e: FormEvent<HTMLButtonElement>) => {
		e.preventDefault()
		clearSearchText()
	}

	const clearSearchText = () => {
		setSearchText('')
		if (inputRef.current) inputRef.current!.value = ''
		onSetRequests([])
		setSearchText('')
		dispatch(
			fireSearch({
				searchText: '',
				searchResults: []
			})
		)
	}

	// const onSubmitSearchRequests = (e: FormEvent<HTMLFormElement>) => {
	// 	onNavigateToRequests()
	// }

	useEffect(() => {
		if (inputRef.current) inputRef.current!.value = searchText
	}, [searchText])

	const triggerSearchBoxToggleAction = () => {
		if (isSearchBoxOpen) {
			return inputRef.current?.focus()
		}
	}

	useEffect(() => {
		if (inputRef !== null) {
			triggerSearchBoxToggleAction()
		}

		if (isSearchBoxOpen == true) {
			clearSearchText()
		}
	}, [isSearchBoxOpen])

	useEffect(() => {
		return () => {
			clearSearchText()
		}
	}, [])

	if (isHomeScreen) {
		return (
			<div className={joinClasses([pageUtils.searchClasses.homeForm])}>
				<Icon src={magnifierWhiteIcon} />
				<input
					type="text"
					placeholder={formatStrapiText(pageData?.assets?.header_inputSearch)}
					onChange={loadRequestsDebounce}
					ref={inputRef}
					id="homePageSearchInput"
				/>
				<div className={isHeader ? pageUtils.searchClasses.btnActions : ''}>
					<Collapse isOpened={!!showClearTextIcon && searchText !== ''}>
						<button
							onClick={onClearSearchText}
							type="button"
							className={joinClasses([
								pageUtils.searchClasses.clearBtn,
								pageUtils.searchClasses.clearBtnHomePage
							])}
						>
							<Icon src={closeGreyIcon} />
						</button>
					</Collapse>
				</div>
			</div>
		)
	}

	return (
		<div
			className={joinClasses([
				pageUtils.searchClasses.form,
				isHeader ? pageUtils.searchClasses.formHeader : ''
			])}
		>
			<input
				className={joinClasses([
					pageUtils.searchClasses.input,
					isHeader ? pageUtils.searchClasses.formHeader : ''
				])}
				type="text"
				placeholder={formatStrapiText(pageData?.assets?.header_inputSearch)}
				onChange={loadRequestsDebounce}
				ref={inputRef}
			/>
			<div className={isHeader ? pageUtils.searchClasses.btnActions : ''}>
				<Collapse isOpened={!!showClearTextIcon && searchText !== ''}>
					<button
						onClick={onClearSearchText}
						type="button"
						className={pageUtils.searchClasses.clearBtn}
					>
						<Icon src={closeGreyIcon} />
					</button>
				</Collapse>
				<button
					className={pageUtils.searchClasses.submitBtn}
					onClick={() => onNavigateToRequests()}
				>
					<Icon src={magnifierWhiteIcon} />
				</button>
			</div>
		</div>
	)
}

export default SearchRequests
