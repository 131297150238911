import React, { FC, useContext } from 'react'
import DesktopSidebar from '@components/ui/sidebar/desktop.sidebar'
import MobileSidebar from '@components/ui/sidebar/mobile.sidebar'
import config from '@utils/config'
import { formatStrapiText, getIcon } from '@utils/methods'
import { RequestTheme } from 'typings/shared'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { homeIcon, myRequestIcon, menuIcon } from '@images/icons'
import { useIsAuthenticated } from '@azure/msal-react'

export interface INavLink {
	text: string
	icon: string
	active: boolean
	link: string
	items?: NavLinkItemsType[]
}

type NavLinkItemsType = {
	link: string
	text: string
}

const Sidebar: FC = () => {
	const {
		pageData: { pages, collections },
		pathname,
		urlParams
	} = useContext<AppContextProps>(AppStateContext)

	const isAuthenticated: boolean = useIsAuthenticated()
	const maxItemsMobile: number = config.sidebar.maxItemMobile
	const maxItems: number = config.sidebar.maxItem
	let isFirst: boolean = true
	let count: number = 0

	const home: INavLink = {
		text: formatStrapiText(pages?.['home']?.title),
		icon: homeIcon,
		active: false,
		link: '/'
	}

	const navList: INavLink[] = [home]

	const navListMobile: INavLink[] = [home]

	const navLinkMyRequest: INavLink = {
		text: formatStrapiText(pages?.['my-requests']?.title),
		icon: myRequestIcon,
		active: false,
		link: config.request.baseUrl
	}

	// For mobile we will use only the first ${maxItemsMobile} themes...
	collections?.requestThemes
		?.filter((theme) => theme.name != 'other')
		?.forEach((request: RequestTheme) => {
			if (request?.name == 'reclamation') {
				const requestId = request.requests.find(
					(req) => req.name == 'claimNotice'
				)?.requestId

				const navItemTemp: INavLink = {
					text: formatStrapiText(request?.title),
					icon: getIcon(request?.name),
					active: isFirst,
					link: `${config.request.create.baseURL}/${requestId}`
				}

				if (request.name) {
					navList.push(navItemTemp)
					isFirst = false
					count++
				}
			} else {
				const navItemTemp: INavLink = {
					text: formatStrapiText(request?.title),
					icon: getIcon(request?.name),
					active: isFirst,
					link: `${config.request.create.baseURLWidthParam}${request.name}`
				}

				if (
					count < maxItems &&
					request.name &&
					request.name !== 'declarations'
				) {
					navList.push(navItemTemp)
				}

				if (count < maxItemsMobile && request.name) {
					navListMobile.push(navItemTemp)
				}

				if (request.name) {
					isFirst = false
					count++
				}
			}
		})

	if (isAuthenticated) {
		navList.push(navLinkMyRequest)
	}

	navListMobile.push({ text: 'Menu', icon: menuIcon, active: false, link: '#' })

	const navItems = (
		pathname: string,
		urlParams: URLSearchParams,
		navList: INavLink[]
	): INavLink[] => {
		return navList.map((value: INavLink) => {
			if (value.link.includes(config.request.create.baseURL)) {
				value.active =
					`${pathname}?group=${urlParams.get('group')}` === value.link

				return value
			}

			value.active = pathname === value.link

			return value
		})
	}

	return (
		<>
			<DesktopSidebar navItems={navItems(pathname, urlParams, navList)} />
			<MobileSidebar
				navItems={navItems(pathname, urlParams, navListMobile)}
				menuItems={navItems(pathname, urlParams, navList)}
			/>
		</>
	)
}

export default Sidebar
