import { css } from '@emotion/css'
import { css as rcss } from '@emotion/react'

const makeClasses = (list: any) => {
	// TODO: fix Style class typing
	let classes: any = {}

	Object.entries(list).forEach(([key, value]) => {
		assign(classes, { [key]: css(value as any) })
	})

	return classes
}

const makeStyles = (arg: string | Record<string, string>) => {
	if (typeof arg === 'object') {
		let styles: any = {}

		Object.entries(arg).forEach(([key, value]) => {
			assign(styles, { [key]: rcss(`${value}`) })
		})

		return styles
	}

	return {
		styles: rcss`${arg}`
	}
}

const joinClasses = (classes: string[]): string => {
	return classes.join(' ')
}

function assign<T, U>(target: T, source: U): asserts target is T & U {
	Object.assign(target, source)
}

export { makeClasses, makeStyles, joinClasses }
