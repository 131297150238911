import { IAddressDTO } from '@services/dtos/addresses.dto'

export interface IContactDTO {
	contact_id?: number | string
	salutation?: number | string
	first_name?: string
	last_name?: string
	phone_daytime?: string
	phone_evening?: string
	email_notification_option?: boolean
	language?: number | string
	external_user_identifier?: string
	email?: string
	location?: IAddressDTO
	preferred_contact_method?: number
	personal_infos?: string
}

export const CONTACT_FIELDS_SET = new Set([
	'contact_id',
	'salutation',
	'first_name',
	'last_name',
	'phone_daytime',
	'phone_evening',
	'email_notification_option',
	'language',
	'external_user_identifier',
	'email',
	'location',
	'personal_infos'
])
