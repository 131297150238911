// A user represent a real person in the real life.
// He as an identity in B2C to access the application and a contact informations in Dynamics

import { IContactDTO } from '@services/dtos/contacts.dto'
import { AccountInfo } from '@azure/msal-common'
import ContactsRepository from '@services/repositories/contacts.repository'

export default class UsersRepository {
	contactRepository: ContactsRepository

	constructor() {
		this.contactRepository = new ContactsRepository()
	}

	public getProfile = async (identityId: string): Promise<IContactDTO> => {
		// We'll always get the profile using the external_user_identifier
		try {
			const [profile]: IContactDTO[] =
				await this.contactRepository.searchContactsBy(
					'externalUserIdentifier',
					identityId
				)

			if (!profile) {
				throw new Error('No profile found for the identity: ' + identityId)
			}

			return profile
		} catch (error: any) {
			console.error(error?.message)
			throw new Error('Error while getting contact: ' + JSON.stringify(error))
		}
	}

	public createContact = async (contactInfo: IContactDTO): Promise<string> => {
		try {
			const contact = await this.contactRepository.postContact(contactInfo)

			if (!contact) {
				throw new Error('Could not create contact for: ' + contactInfo)
			}

			return contact
		} catch (error: any) {
			console.error(error?.message)
			throw new Error('Error while creating contact: ' + JSON.stringify(error))
		}
	}

	public getIdentity = async (): Promise<AccountInfo> => {
		throw new Error('No Implemented')
	}
}
