import { REQUESTS_IDS } from '@services/constants'

const otherSubject = {
	requestId: 'other-subject'
}

export default {
	baseUrl: '/auth/requests',
	create: {
		baseURL: '/auth/requests/create',
		baseURLWidthParam: '/auth/requests/create?group=',
		profileURL: '/auth/profile',
		nbPlainSteps: 2,
		nbPDFSteps: 5,
		templateType: {
			PLAIN: 'plain',
			PDF: 'pdf'
		},
		group: {
			default: process.env.REQUEST_NAME_DEFAULT,
			other: process.env.REQUEST_GROUP_OTHER
		},
		otherRequest: process.env.OTHER_REQUEST_NAME,
		materialsOrWasteNotCollected: {
			requestTypeListIncludes: 'materialsOrWaste_',
			bulkyItems: 'bulkyItems',
			assestStrapiName: 'request_form_materialsOrWaste_notification_'
		},
		binLostStoleIncorrectlyReplace: {
			requestTypeListIncludes: 'binLostStoleIncorrectlyReplace_',
			binImproperlyReplacedKey: 'binImproperlyReplaced',
			binLostOrStolen: 'lostStolenBin'
		},
		TrafficLightAndPedestrianLight: {
			requestTypeListIncludes: 'trafficLightAndPedestrianLight_',
			trafficLight: 'traficLight',
			pedestrianLight: 'pedestriantLight'
		},
		potHoleStreet: {
			requestTypeListIncludes: 'potholes_',
			potholesDamagedPavement: 'potholes_damagedPavement'
		},
		floorLampAndLighting: {
			requestTypeListIncludes: 'floorLampAndLighting_'
		},
		fieldsToExcludeFromConfigAndMetadata: ['requestType', 'subject'],
		fieldsToExcludeFromConfig: [
			'reportConcernVisual',
			'reportedProblem',
			'propertyCollectionHousing',
			'itemToRepair',
			'wish',
			'lightingLocated',
			'repairAll',
			'requestAbout'
		],
		requiredFieldsToAddInDetails: ['itemToRepair']
	},
	input: {
		textareaMaxChar: 1200,
		textareaMaxCharForOtherRequest: 1800
	},
	file: {
		pdf: 'pdf',
		accept:
			'.BMP, .GIF, .JPEG, .JPG, .PDF, .PNG, .bmp, .gif, .jpeg, .jpg, .pdf, .png',
		maxSize: 6000000,
		acceptImageRegex: /\.(bmp|gif|jpeg|jpg|png)$/
	},
	otherSubject,
	searchArticles: {
		otherSubject,
		covid: {
			keyword: 'covid'
		},
		multipleRequests: [
			//NOTE: I commented this because it is prevention the 311 information from displaying

			// 'bin-lost-stole-or-incorrectly-replace',
			// 'traffic-light-and-pedestrian-light',
			// 'materials-or-waste-not-collected',
			// 'pot-hole',
			// 'floor-lamp-and-lighting',
			REQUESTS_IDS.treeDonation
		] as string[]
	}
}
