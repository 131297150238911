import { useEffect, useState } from 'react'
import { Colors, Constants } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import {
	chevronBlueIcon,
	chevronDownBlueIcon,
	logoLavalLIcon,
	userWhiteIcon
} from '@images/icons'

type ClassType = {
	row: string
	row2: string
	col12: string
	action: string
	padding: string
	historyCard: string
	h3: string
	content: string
	mobileContent: string
	desktopContent: string
	timelineBlock: string
	marquer: string
	notif: string
	notifCityzen: string
	timelineContent: string
	dropdown: string
	dropbtn: string
	dropbtnDisable: string
	dropbtnImg: string
	dropdownContent: string
	dropdownLink: string
	dropdownMenu: string
	historyCardTitle: string
	historyText: string
	historyTextSpace: string
	attachmentText: string
	modalContent: string
	text: string
	headerStyle: string
	carousselButton: string
	carousselPrevButton: string
	mobileView: string
	desktopView: string
	disabled: string
	blocView: string
	view: string
	link: string
	attachment: string
	disableClick: string
	blockNotRead: string
	negativeMargins: string
}

export const classes: ClassType = makeClasses({
	row: {
		display: 'flex',
		msFlexWrap: 'wrap',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px'
	},
	row2: {
		display: 'flex',
		msFlexWrap: 'wrap',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px',
		justifyContent: 'space-between',
		[Breakpoints.maxWidth('md')]: {
			padding: '0 20px'
		}
	},
	text: {
		margin: '0 0 15px',
		padding: 0,
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '25px'
	},
	col12: {
		flex: '0 0 100%'
	},
	action: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '30px'
	},
	historyCard: {
		flex: '0 0 32.333333%',
		backgroundColor: Colors.white,
		padding: '40px',
		marginBottom: '30px',
		height: 'fit-content',
		[Breakpoints.maxWidth('lg')]: {
			flex: '0 0 100%',
			position: 'relative',
			width: '85vw'
		},
		[Breakpoints.maxWidth('xl')]: {
			padding: '30px'
		},
		[Breakpoints.maxWidth('sm')]: {
			marginBottom: '40px',
			padding: '30px'
		}
	},
	h3: {
		color: Colors.darkBlue1,
		fontWeight: '700',
		margin: '0 0 15px !important',
		padding: 0,
		fontFamily: Constants
	},
	headerStyle: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	carousselButton: {
		width: '30px',
		height: '30px',
		content: '" ."',
		background: `url(${chevronBlueIcon}) ${Colors.lightBlueTransparent50}`,
		backgroundSize: '18px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		borderRadius: '50%',
		border: '0px solid transparent',
		margin: '0px 10px',
		cursor: 'pointer',
		display: 'inline-block',
		':last-child': {
			margin: '0px'
		},
		':hover': {
			backgroundColor: `${Colors.lightBlueTransparent70}`
		},
		[Breakpoints.minWidth('lg')]: {
			display: 'none'
		}
	},
	carousselPrevButton: {
		transform: 'rotate(180deg)'
	},
	content: {
		width: '100%',
		padding: '0px 0px',
		position: 'relative'
	},
	mobileContent: {
		display: 'flex',
		padding: '0px 0px',
		position: 'relative',
		overflowX: 'auto',
		overflowY: 'overlay',
		textAlign: 'center',
		'::before': {
			content: "''",
			position: 'absolute',
			marginLeft: 0,
			background: Colors.lightGrey5,
			zIndex: -1,
			top: 'auto',
			height: '1px',
			left: '0px',
			width: '100%',
			marginTop: '25px'
		}
	},
	desktopContent: {
		width: '100% !important',
		textAlign: 'left',
		'::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: '25px',
			marginLeft: 0,
			width: '1px',
			height: 'calc(100% - 62px)',
			background: Colors.lightGrey5,
			zIndex: 1
		}
	},
	timelineBlock: {
		width: '100% !important',
		display: 'flex',
		clear: 'both',
		[Breakpoints.maxWidth('lg')]: {
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
			width: '50% !important',
			marginLeft: '0px',
			marginRight: '30px'
		},
		[Breakpoints.maxWidth('md')]: {
			width: '80% !important',
			marginBottom: 0
		},
		[Breakpoints.maxWidth('sm')]: {
			width: '80% !important',
			marginRight: '20px'
		}
	},
	marquer: {
		minWidth: '50px',
		width: '50px',
		height: '50px',
		minHeight: '50px',
		border: `5px solid ${Colors.white}`,
		borderRadius: '50%',
		background: `url(${userWhiteIcon}) ${Colors.backgroundConnected}`,
		backgroundSize: '15px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		marginTop: '10px',
		zIndex: 9,
		marginRight: '10px',
		[Breakpoints.maxWidth('lg')]: {
			marginTop: 0,
			borderColor: Colors.white
		},
		[Breakpoints.maxWidth('sm')]: {
			borderWidth: '3px'
		}
	},
	notif: {
		_background: `url(${logoLavalLIcon}) #e6e7e8`,
		get background() {
			return this._background
		},
		set background(value) {
			this._background = value
		},
		minWidth: '50px',
		minHeight: '50px',
		backgroundSize: '25px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		[Breakpoints.maxWidth('lg')]: {
			marginLeft: '40%'
		}
	},
	notifCityzen: {
		[Breakpoints.maxWidth('lg')]: {
			marginLeft: '40%'
		}
	},
	timelineContent: {
		marginBottom: '15px!important',
		background: 'none',
		width: '100%',
		padding: '15px 5px 5px',
		color: `${Colors.darkGrey}`,
		marginTop: '5px',
		boxSizing: 'inherit !important',
		[Breakpoints.maxWidth('lg')]: {
			zIndex: 2,
			marginTop: 0,
			background: Colors.white,
			padding: '15px 20px'
		}
	},
	dropdown: {
		float: 'right',
		position: 'relative',
		display: 'inline-block',
		background: 'none',
		cursor: 'pointer'
	},
	dropbtn: {
		borderColor: 'transparent',
		backgroundColor: Colors.white,
		borderRadius: '50%',
		padding: '7px',
		lineHeight: '15px',
		cursor: 'pointer',
		'&:disabled': {
			cursor: 'no-drop'
		},
		'&:not(:disbled)': {
			cursor: 'pointer'
		},
		':hover': {
			backgroundColor: Colors.greyIron,
			outline: 'none'
		}
	},
	dropbtnDisable: {
		display: 'none !important'
	},
	dropbtnImg: {
		width: '15px',
		verticalAlign: 'middle',
		borderStyle: 'none',
		marginRight: '2px'
	},
	dropdownContent: {
		display: 'none',
		position: 'absolute',
		backgroundColor: Colors.white,
		right: 0,
		overflow: 'auto',
		boxShadow: '0px 2px 16px rgb(0 0 0 / 15%)',
		zIndex: 8,
		minWidth: 0,
		whiteSpace: 'nowrap',
		marginTop: 0,
		textAlign: 'center',
		overflowX: 'hidden'
	},
	dropdownMenu: {
		zIndex: 9,
		willChange: 'transform',
		right: '0px !important',
		top: '0px',
		left: '0px',
		textAlign: 'center',
		border: 'none',
		padding: '0px 0px',
		margin: 0,
		[Breakpoints.widthBetween('laptop', 1080)]: {
			padding: '0px 28px',
			marginRight: '-36px',
			fontSize: '14px'
		}
	},
	dropdownLink: {
		color: Colors.black,
		padding: '12px 16px',
		textDecoration: 'none',
		display: 'block',
		':hover': {
			background: Colors.darkWhite
		}
	},
	historyCardTitle: {
		fontSize: '14px',
		fontWeight: 600,
		color: Colors.darkGrey2
	},
	historyText: {
		fontSize: '14px',
		lineHeight: '1.5em',
		color: Colors.brown,
		marginBottom: 0,
		marginTop: '5px',
		'& p': {
			margin: '0px'
		}
	},
	historyTextSpace: {
		height: '50px'
	},
	attachmentText: {
		color: Colors.darkGrey,
		lineHeight: '15px',
		fontWeight: 400,
		paddingLeft: '5px'
	},

	modalContent: {
		'& fieldset': {
			margin: '0 0 30px 0',
			border: 0,
			padding: 0,
			clear: 'both',
			'& > div textarea': {
				background: Colors.white,
				width: '100%',
				height: '150px',
				display: 'block',
				border: `1px solid ${Colors.grey}`,
				padding: '8px 12px',
				fontWeight: 500,
				fontSize: '15px',
				color: Colors.grey,
				resize: 'vertical'
			}
		},
		'& label': {
			display: 'block',
			margin: '5px 0',
			padding: 0,
			fontSize: '16px',
			fontWeight: 'bold',
			color: Colors.lightBlack,
			lineHeight: '1.2em'
		},
		'& select': {
			display: 'block',
			padding: '0 12px',
			width: '100%',
			height: '40px',
			lineHeight: '25px',
			fontWeight: 500,
			fontSize: '15px',
			color: Colors.lightBlack,
			border: `1px solid ${Colors.grey}`,
			boxShadow: 0,
			appearance: 'none',
			backgroundImage: `url(${chevronDownBlueIcon})`,
			backgroundColor: Colors.white,
			backgroundRepeat: 'no-repeat,repeat',
			backgroundPosition: 'right 0.7em top 50%,0 0',
			backgroundSize: '24px'
		}
	},

	desktopView: {
		[Breakpoints.maxWidth('lg')]: {
			display: 'none'
		}
	},
	mobileView: {
		[Breakpoints.minWidth('lg')]: {
			display: 'none'
		}
	},
	disabled: {
		backgroundColor: `${Colors.lightBlueTransparent20} !important`
	},
	blocView: {
		background: Colors.darkWhite,
		padding: '12px',
		fontSize: '13px',
		lineHeight: '1.5',
	},
	blockNotRead: {
		borderLeft: "3px solid #aaa",
		fontWeight: 'bold'
	},
	view: {
		display: '-webkit-box',
		WebkitLineClamp: '1',
		WebkitBoxOrient: 'vertical',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		p: {
			margin: 0
		},
		[Breakpoints.maxWidth('sm')]: {
			WebkitLineClamp: '2'
		}
	},
	link: {
		textDecoration: 'none',
		cursor: 'pointer',
		color: Colors.secondary,
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	attachment: {
		marginTop: '0.5rem',
		marginRight: '4px',
		backgroundColor: Colors.white,
		padding: '5px',
		display: 'inline-block'
	},
	disableClick: {
		pointerEvents: 'none',
		cursor: 'not-allowed',
		'&:hover': {
			cursor: 'not-allowed'
		},
		'& a': {
			pointerEvents: 'none !important',
			cursor: 'not-allowed !important',
			'&:hover': {
				cursor: 'not-allowed !important'
			}
		}
	},
	negativeMargins: {
		'& table table':{
			height: 'auto !important',
		}
	}
})

export const laodSlider = () => {
	const settings = {
		container: '.slider',
		loop: false,
		autoplay: false,
		autowidth: true,
		responsive: {},
		prevButton: '.slider-control-prev',
		nextButton: '.slider-control-next',
		controlsContainer: '.slider-control',
		nav: false,
		slideBy: 1,
		speed: 500
	}
	settings.responsive[`${Breakpoints.values.lg}`] = {
		items: 2
	}
	settings.responsive[`${Breakpoints.values.md}`] = {
		items: 2
	}
	settings.responsive[`${Breakpoints.values.sm}`] = {
		items: 1
	}

	const [slider, setSlider] = useState<any>()
	useEffect(() => {
		import('tiny-slider').then(({ tns }) => {
			setSlider(tns(settings))
		})
	}, [])

	const next = document.querySelector(
		'.slider-control-next'
	) as HTMLButtonElement
	const prev = document.querySelector(
		'.slider-control-prev'
	) as HTMLButtonElement
	const container = document.querySelector('.tns-inner') as HTMLDivElement
	const label = document.querySelector('.tns-visually-hidden') as HTMLDivElement

	next?.addEventListener('click', () => slider.goTo('next'))
	prev?.addEventListener('click', () => slider.goTo('prev'))
	if (container && label) {
		container.style.overflowX = 'auto'
		label.style.display = 'none'
	}
}
