import React, { FC, useContext } from 'react'
import { useMsal } from '@azure/msal-react'
import { Icon } from '@components/ui/Icon'
import { logoutIcon } from '@images/icons'
import { makeClasses } from '@utils/styles'
import Link from '@components/ui/link'
import { AppStateContext } from '@components/layouts/DynamicLayout'

type Props = {
	text: string
	className?: string
	hasIcon?: boolean
}

type ClassType = {
	wrapper: string
	icon: string
}

const classes: ClassType = makeClasses({
	wrapper: {
		marginRight: '10px'
	},
	icon: {
		width: '18px',
		verticalAlign: 'middle'
	}
})

export const SignOutButton: FC<Props> = ({ text, className, hasIcon }) => {
	const { instance } = useMsal()
	const { setAuthUser } = useContext(AppStateContext)

	const handleLogout = () => {
		setAuthUser(undefined)
		instance.logoutRedirect()
	}

	return (
		<Link
			to="#"
			className={className}
			onClick={handleLogout}
			key="logoutRedirect"
		>
			{hasIcon && (
				<div className={classes.wrapper}>
					<Icon src={logoutIcon} className={classes.icon} />
				</div>
			)}{' '}
			{text}
		</Link>
	)
}
