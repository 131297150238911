import {
	chevronDownBlueIcon,
	downloadIcon,
	logoLavalLIcon,
	pdfIcon,
	thumbnail3,
	thumbnail4,
	userWhiteIcon
} from '@images/icons'
import { Colors, Constants } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import { Request } from 'typings/shared'
import { Request as RequestModel } from '@services/models'
import config from '@utils/config'

export const dummyMessages = {
	message1: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
	message2: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel.
	Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
	
	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel.
	
	Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
	
	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel.
	
	Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
	message3: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel.
	Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
	
	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel.
	
	Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
	
	Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque posuere ultrices accumsan. Aliquam cursus blandit lectus in dapibus. Aliquam a velit erat. Maecenas luctus augue ante, in scelerisque magna tincidunt vitae. Sed egestas nec ante eget tempor. Phasellus sed dapibus augue. Donec ornare ipsum at dui pellentesque, nec porta mi ultrices. Quisque suscipit feugiat diam, eget convallis mi sagittis id. Nam ultricies aliquam erat nec sodales. Nunc vehicula, turpis quis venenatis fermentum, leo dolor porttitor enim, vel auctor dolor urna quis lorem. Ut consequat eros ultrices ultricies scelerisque. In pellentesque lacus sodales dui sodales fringilla. Mauris egestas est erat, id aliquet turpis maximus vel.
	
	Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Quisque ipsum tortor, ultricies id diam et, pharetra ornare orci. Aenean quis euismod tortor, commodo varius dolor. Sed auctor congue elementum. Nunc pretium vestibulum ex quis laoreet. Cras eu rutrum magna. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`
}

type ClassType = {
	row: string
	row2: string
	col12: string
	action: string
	padding: string
	historyCard: string
	h3: string
	content: string
	timelineBlock: string
	marquer: string
	notif: string
	timelineContent: string
	dropdown: string
	dropbtn: string
	dropbtnImg: string
	dropdownContent: string
	dropdownLink: string
	historyCardTitle: string
	historyText: string
	attachmentText: string
	requestInfoSection: string
	block1: string
	col: string
	colXl4: string
	colSm6: string
	colXl8: string
	borderBottom: string
	accordion: string
	panel: string
	accordeonActive: string
	button: string
	text: string
	files: string
	name: string
	img: string
	date: string
	last: string
	messages: string
	unread: string
	subject: string
	elu: string
	eluName: string
	eluButton: string
	revoqued: string
	outlined: string
	modalContent: string
	modalHeader: string
	modalTitle: string
	close: string
	modalFooter: string
	modalBtn: string
	modalBody: string
	required: string
	description: string
	download: string
	modalBtnOulined: string
	dropdownMenu: string
	inputError: string
	textError: string
	textDescription: string
	bloc: string
	container: string
}

export const classes: ClassType = makeClasses({
	row: {
		display: 'flex',
		msFlexWrap: 'wrap',
		width: 'auto',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px'
	},
	row2: {
		display: 'flex',
		msFlexWrap: 'wrap',
		flexWrap: 'wrap',
		marginRight: '-20px',
		marginLeft: '-20px',
		justifyContent: 'space-between'
	},
	col12: {
		flex: '0 0 100%',
		maxWidth: '100%'
	},
	action: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '30px'
	},
	date: {
		fontWeight: 500,
		color: Colors.darkGrey,
		fontSize: '14px',
		whiteSpace: 'nowrap',
		paddingLeft: '10px',
		[Breakpoints.maxWidth('sm')]: {
			display: 'block !important'
		}
	},
	historyCard: {
		flex: '0 0 32.333333%',
		maxWidth: '32.333333%',
		backgroundColor: Colors.white,
		padding: '40px',
		marginBottom: '30px',
		height: 'fit-content',
		[Breakpoints.maxWidth('lg')]: {
			flex: '0 0 100%',
			maxWidth: '100%',
			position: 'relative',
			width: '100%',
			padding: '0 !important',
			backgroundColor: 'transparent'
		},
		[Breakpoints.maxWidth('xl')]: {
			padding: '30px'
		},
		[Breakpoints.maxWidth('sm')]: {
			marginBottom: '40px'
		}
	},
	h3: {
		color: Colors.darkBlue1,
		fontWeight: '700',
		margin: '0 0 15px !important',
		padding: 0,
		fontFamily: Constants
	},
	content: {
		width: '100%',
		padding: '0px 0',
		margin: '0px auto',
		position: 'relative',
		'::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: '25px',
			marginLeft: 0,
			width: '1px',
			height: 'calc(100% - 50px)',
			background: Colors.lightGrey5,
			zIndex: 1
		},
		[Breakpoints.maxWidth('lg')]: {
			display: 'flex',
			overflowX: 'auto',
			'::before': {
				top: 'auto',
				width: 'calc(100% - 80px)',
				height: '1px',
				left: '0px',
				marginTop: '25px'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			'::before': {
				width: 'calc(100% - 40px)',
				hight: '1px'
			}
		}
	},
	timelineBlock: {
		width: '100%',
		display: 'flex',
		clear: 'both',
		[Breakpoints.maxWidth('lg')]: {
			justifyContent: 'flex-start',
			minWidth: '50%',
			marginLeft: '0px',
			marginRight: '30px'
		},
		[Breakpoints.maxWidth('md')]: {
			minWidth: '80%',
			marginBottom: 0
		},
		[Breakpoints.maxWidth('sm')]: {
			minWidth: '80%',
			marginRight: '20px'
		}
	},
	marquer: {
		minWidth: '50px',
		width: '50px',
		height: '50px',
		minHeight: '50px',
		border: `5px solid ${Colors.white}`,
		borderRadius: '50%',
		background: `url(${userWhiteIcon}) ${Colors.backgroundConnected}`,
		backgroundSize: '15px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		marginTop: '10px',
		zIndex: 9,
		marginRight: '10px',
		[Breakpoints.maxWidth('lg')]: {
			marginTop: 0,
			borderColor: Colors.ligthGrey2
		},
		[Breakpoints.maxWidth('sm')]: {
			borderWidth: '3px'
		}
	},
	notif: {
		background: `url(${logoLavalLIcon}) #e6e7e8`,
		minWidth: '50px',
		minHeight: '50px',
		backgroundSize: '25px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center'
	},
	timelineContent: {
		marginBottom: '15px!important',
		background: 'none',
		width: '100%',
		padding: '15px 5px 5px',
		color: `${Colors.darkGrey}`,
		marginTop: '5px',
		boxSizing: 'inherit !important',
		[Breakpoints.maxWidth('lg')]: {
			zIndex: 2,
			marginTop: 0,
			background: Colors.white,
			padding: '15px 20px'
		}
	},
	dropdown: {
		float: 'right',
		position: 'relative',
		display: 'inline-block',
		background: 'none',
		cursor: 'pointer'
	},
	dropbtn: {
		borderColor: 'transparent',
		backgroundColor: Colors.white,
		borderRadius: '50%',
		padding: '7px',
		lineHeight: '15px',
		'&:disabled': {
			cursor: 'no-drop'
		},
		'&:not(:disbled)': {
			cursor: 'pointer'
		},
		':hover': {
			backgroundColor: Colors.greyIron,
			outline: 'none'
		}
	},
	dropbtnImg: {
		width: '15px',
		verticalAlign: 'middle',
		borderStyle: 'none'
	},
	dropdownContent: {
		display: 'none',
		position: 'absolute',
		backgroundColor: Colors.white,
		right: 0,
		overflow: 'auto',
		boxShadow: '0px 2px 16px rgb(0 0 0 / 15%)',
		zIndex: 8,
		minWidth: 0,
		whiteSpace: 'nowrap',
		marginTop: 0,
		textAlign: 'center'
	},
	dropdownMenu: {
		zIndex: 9,
		willChange: 'transform',
		right: '0px !important',
		top: '0px',
		left: '0px',
		textAlign: 'center',
		border: 'none',
		padding: '0px 0px',
		'& li': {
			marginBottom: 0,
			fontSize: '14px',
			lineHeight: '20px'
		}
	},
	dropdownLink: {
		color: Colors.secondary,
		padding: '12px 16px',
		textDecoration: 'none',
		display: 'block',
		':hover': {
			background: Colors.darkWhite
		}
	},
	historyCardTitle: {
		fontSize: '14px',
		fontWeight: 600,
		color: Colors.darkGrey2
	},
	historyText: {
		fontSize: '14px',
		lineHeight: '1.5em',
		color: Colors.brown,
		marginBottom: 0,
		marginTop: '5px'
	},
	attachmentText: {
		color: Colors.darkGrey,
		lineHeight: '15px',
		fontWeight: 400,
		paddingLeft: '5px'
	},
	requestInfoSection: {
		position: 'relative',
		width: '100%',
		[Breakpoints.minWidth('lg')]: {
			flex: '0 0 65.666667%',
			maxWidth: '65.666667%'
		},
		[Breakpoints.minWidth('md')]: {
			flex: '0 0 100%'
		}
	},
	block1: {
		background: Colors.white,
		width: '100%',
		padding: '40px',
		marginBottom: '30px'
	},
	borderBottom: {
		marginBottom: 0,
		borderBottom: `1px solid ${Colors.greyIron} !important`
	},
	colSm6: {
		[Breakpoints.minWidth('sm')]: {
			flex: '0 0 50%',
			maxWidth: '50%'
		}
	},
	colXl4: {
		[Breakpoints.minWidth('xl')]: {
			flex: '0 0 33.333333%',
			maxWidth: '33.333333%'
		}
	},
	colXl8: {
		[Breakpoints.minWidth('xl')]: {
			flex: '0 0 66.666667%',
			maxWidth: '66.666667%'
		}
	},
	col: {
		position: 'relative',
		padding: '5px 15px',
		width: '100%'
	},
	accordion: {
		position: 'relative',
		cursor: 'pointer',
		borderTop: '1px solid rgba(0,0,0,0.1)',
		margin: 0,
		padding: '15px 0 15px 30px',
		fontSize: '16px',
		lineHeight: '20px',
		fontFamily: '"Roboto", Arial, Helvetica, sans-serif',
		fontWeight: 'normal',
		color: Colors.lightBlack,
		textDecoration: 'none',
		display: 'block',
		width: '100%',
		borderBottom: 'none',
		borderRight: 'none',
		borderLeft: 'none',
		textAlign: 'left',
		background: 'none transparent !important',
		verticalAlign: 'middle',
		textTransform: 'none',
		userSelect: 'none',
		borderRadius: 0,
		'::before': {
			content: '""',
			position: 'absolute',
			top: '12px',
			left: 0,
			width: '25px',
			height: '25px',
			background: `url(${chevronDownBlueIcon})`,
			backgroundSize: '25px',
			backgroundPosition: 'center'
		},
		':hover': {
			textDecoration: 'none',
			background: 'transparent !important'
		},
		':focus': {
			textDecoration: 'none',
			outline: 'none'
		}
	},
	accordeonActive: {
		color: Colors.secondary,
		fontWeight: 500,
		'::before': {
			backgroundSize: '25px',
			backgroundPosition: 'center',
			transform: 'rotate(180deg)'
		}
	},
	panel: {
		padding: '0 0 15px 30px',
		display: 'none',
		overflow: 'hidden'
	},
	text: {
		margin: '0 0 5px',
		padding: 0,
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '25px',
		wordBreak: 'break-word',
		'& p': {
			margin: '0px'
		}
	},
	messages: {
		borderCollapse: 'collapse',
		verticalAlign: 'middle',
		background: 'none',
		width: '100%',
		[Breakpoints.maxWidth('sm')]: {
			padding: '10px 0'
		},
		'& a': {
			color: Colors.secondary,
			textDecoration: 'none',
			':visited': {
				color: Colors.secondary
			},
			':hover': {
				textDecorationLine: 'underline'
			}
		},
		'& tr': {
			borderBottom: `1px solid ${Colors.greyIron}`
		},
		'& td:first-child': {
			paddingRight: '10px',
			width: '30px'
		},
		'& td': {
			padding: '15px'
		},
		'& tr:last-child': {
			borderBottom: 'none'
		},
		'& td:last-child': {
			textAlign: 'right'
		}
	},
	unread: {
		'& img': {
			width: '20px',
			marginRight: 0,
			backgound: 'red'
		}
	},
	subject: {
		color: Colors.secondary,
		fontSize: '16px',
		cursor: 'pointer'
	},
	outlined: {
		background: Colors.secondary,
		color: `${Colors.white} !important`,
		width: 'fit-content'
	},
	modalContent: {
		'& fieldset': {
			margin: '0 0 30px 0',
			border: 0,
			padding: 0,
			clear: 'both',
			'& > div textarea': {
				background: Colors.white,
				width: '100%',
				height: '150px',
				display: 'block',
				border: `1px solid ${Colors.grey}`,
				padding: '8px 12px',
				fontWeight: 500,
				fontSize: '15px',
				color: Colors.black,
				resize: 'vertical'
			},
			'& > section': {
				padding: '0px',
				'& a + div': {
					paddingTop: '20px'
				}
			}
		},
		'& label': {
			display: 'block',
			margin: '5px 0',
			padding: 0,
			fontSize: '16px',
			fontWeight: 'bold',
			color: Colors.lightBlack,
			lineHeight: '1.2em'
		},
		'& select': {
			display: 'block',
			padding: '0 12px',
			width: '100%',
			height: '40px',
			lineHeight: '25px',
			fontWeight: 500,
			fontSize: '15px',
			color: Colors.lightBlack,
			border: `1px solid ${Colors.grey}`,
			boxShadow: 0,
			appearance: 'none',
			backgroundImage: `url(${chevronDownBlueIcon})`,
			backgroundColor: Colors.white,
			backgroundRepeat: 'no-repeat,repeat',
			backgroundPosition: 'right 0.7em top 50%,0 0',
			backgroundSize: '24px'
		},
		[Breakpoints.maxWidth('md')]: {
			width: '100%'
		}
	},
	modalHeader: {
		display: 'flex',
		msFlexAlign: 'start',
		alignItems: 'flex-start',
		msFlexPack: 'justify',
		justifyContent: 'space-between',
		borderTopLeftRadius: '.3rem',
		borderTopRightRadius: '.3rem',
		borderBottom: 'none !important',
		padding: '30px 30px 0'
	},
	modalTitle: {
		fontSize: '22px',
		fontWeight: 700,
		color: Colors.darkBlue1,
		marginBottom: 0,
		lineHeight: 1.5
	},
	close: {
		padding: '10px',
		margin: '-1rem -1rem -1rem auto',
		fontSize: '1.5rem',
		fontWeight: 700,
		lineHeight: 1,
		color: Colors.black,
		textShadow: `0 1px 0 ${Colors.white}`,
		backgroundColor: 'transparent',
		cursor: 'pointer',
		border: 0,
		'& img': {
			width: '25px'
		}
	},
	modalFooter: {
		display: 'flex',
		msFlexAlign: 'center',
		alignItems: 'center',
		msFlexPack: 'end',
		borderBottomRightRadius: '.3rem',
		borderBottomLeftRadius: '.3rem',
		padding: '0 30px 30px',
		borderTop: 0,
		justifyContent: 'flex-start'
	},
	modalBtn: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		display: 'inline-block',
		fontWeight: 500,
		color: Colors.white,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		background: Colors.secondary,
		textDecoration: 'none',
		':hover': {
			background: Colors.darkBlue2
		}
	},
	modalBtnOulined: {
		cursor: 'pointer',
		padding: '15px 30px',
		fontSize: '16px',
		display: 'inline-block',
		fontWeight: 500,
		lineHeight: '1em',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		textDecoration: 'none',
		margin: '0 10px',
		border: `1px solid ${Colors.secondary}`,
		background: Colors.white,
		color: Colors.secondary,
		':hover': {
			background: Colors.darkBlue2,
			color: Colors.white
		}
	},
	modalBody: {
		padding: '30px',
		position: 'relative',
		flex: '1 1 auto'
	},
	required: {
		color: Colors.ligthPurple,
		marginLeft: '5px'
	},
	description: {
		clear: 'both',
		display: 'block',
		margin: '5px 0 8px',
		padding: 0,
		fontSize: '0.85em',
		lineHeight: '1.25em',
		color: Colors.lightGrey2
	},
	download: {
		padding: '9px 20px 9px 32px',
		fontSize: '14px',
		backgroundImage: `url(${downloadIcon})`,
		backgroundSize: '25px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '5px 3px',
		color: `${Colors.white} !important`,
		':hover': {
			backgroundImage: `url(${downloadIcon})`,
			backgroundSize: '25px',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '5px 3px'
		}
	},
	inputError: {
		border: `1px solid ${Colors.errorRed}`,
		'&:focus': {
			borderColor: Colors.errorRed,
			boxShadow: `0 0 0 2px ${Colors.errorRedTransparent20}`
		}
	},
	textError: {
		color: `${Colors.errorRed} !important`
	},
	textDescription: {
		margin: '0 0 8px',
		fontSize: '0.85em',
		lineHeight: '2.25em'
	},
	bloc: {
		background: Colors.white,
		padding: '40px',
		marginBottom: '30px'
	},
	container: {
		display: 'grid',
		flexWrap: 'wrap',
		marginRight: '-15px',
		marginLeft: '-15px',
		lineHeight: '1.62em',
		[Breakpoints.minWidth('lg')]: {
			gridTemplateColumns: '58.333333%41.666667%'
		}
	}
})

export const dummyHistory = [
	{
		origin: 'citizen',
		date: '2022-03-30T13:40:00.000Z',
		text: 'Vous avez ajouté une information à la requête.',
		attatchment: 2,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'citizen',
		date: '2021-10-14T13:40:00.000Z',
		text: 'Vous avez ajouté une information à la requête.',
		attatchment: 2,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'town',
		date: '2022-02-07T13:09:56.456Z',
		text: 'Le message Sujet du message a été publié.',
		attatchment: 2,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'town',
		date: '2022-02-17T13:09:56.456Z',
		text: 'Le statut de la requête a été modifié pour En traitement.',
		attatchment: 0,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'town',
		date: '2022-03-07T13:09:56.456Z',
		text: 'Le message Sujet du message a été publié.',
		attatchment: 1,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'citizen',
		date: '2022-03-17T13:09:56.456Z',
		text: 'Vous avez ajouté un consentement à un élu.',
		attatchment: 0,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'citizen',
		date: '2022-03-27T13:09:56.456Z',
		text: 'Vous avez ajouté une information à la requête.',
		attatchment: 0,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	},
	{
		origin: 'town',
		date: '2022-03-28T13:07:37.140Z',
		text: 'Le statut de la requête a été modifié pour Fermé.',
		attatchment: 0,
		communication:
			'Pour faire suite à votre demande, nous avons créé une requête portant le numéro RC-06704-K1M1. Elle sera prise en charge par le service concerné dans les meilleurs délais.'
	}
]

export const dummyFiles = [
	{
		link: thumbnail3,
		thumbnail: thumbnail3,
		name: 'nom-du-fichier-9348603940350934-4309583094903580.jpg',
		isPdf: false,
		date: '2022-01-01 09:33'
	},
	{
		link: thumbnail3,
		thumbnail: pdfIcon,
		name: 'nom-du-fichier-934350934-4309583094903580.jpg',
		isPdf: true,
		date: '2022-01-01 09:33'
	},
	{
		link: thumbnail4,
		thumbnail: thumbnail4,
		name: 'nom-du-fichier-9348603940350934-4309580.jpg',
		isPdf: false,
		date: '2022-01-01 09:33'
	}
]

export const dummyConsent = [
	{
		id: '0',
		author: 'District 05 - Daniel Hébert',
		dateAdd: new Date('2021-01-01 10:00')
	},
	{
		id: '1',
		author: 'District 06 - Sandra Desmeules',
		dateAdd: new Date('2021-01-01 10:00'),
		dateRevoked: new Date('2021-01-01 10:00')
	},
	{
		id: '2',
		author: 'District 07 - Aram Elagoz',
		dateAdd: new Date('2021-01-01 10:00')
	}
]

export const getRequestTitle = (
	requests: Request[],
	request: RequestModel
): string => {
	const requestId: string =
		request?.typeId == null
			? config.request.otherSubject.requestId
			: request?.typeId

	let requestFound: Request | undefined = requests?.find(
		(r) => r.requestId === requestId || r.name === request?.title
	)

	// forcer le titre de autre requete en cas d'une requete non existante dans mon dossier
	if (!requestFound) {
		requestFound = requests?.find(
			(r) => r.requestId === config.request.otherSubject.requestId
		)
	}

	return requestFound ? requestFound.title : ''
}

export const getRequestName = (requests: Request[], request: RequestModel) => {
	return requests.find((req) => req.requestId === request?.typeId)?.name
}

export const handleAccordeon = (e) => {
	const el = document.getElementById(e.target.id) as HTMLButtonElement
	const nextEl = el.nextElementSibling! as HTMLDivElement

	if (el && el.classList.contains(classes.accordeonActive)) {
		el.classList.remove(classes.accordeonActive)
		nextEl.style.display = 'none'
	} else if (el) {
		el.classList.add(classes.accordeonActive)
		nextEl.style.display = 'block'
	}
}

export type RequestDetailsPropsType = {
	id: string
	setBreadCrumbTitle: (title: string) => void
}

export enum MODAL {
	ADD_INFORMATION = 'addInfo',
	CANCEL = 'cancel',
	CONSENT = 'consent'
}
