import React, { FC, useEffect, createRef, RefObject, useState } from 'react'
import { Link as GatsbyLink, GatsbyLinkProps, navigate } from 'gatsby'
import config from '@utils/config'
import Authentication, {
	AuthenticationActionType,
	AuthenticationType
} from '@components/auth/Authentication'

interface CustomGatsbyLink
	extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {
	children: any
	activeClassName?: string
	partiallyActive?: boolean
	ref?: RefObject<HTMLAnchorElement>
}

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop and
// it will be pass only to GatsbyLink
const Link: FC<CustomGatsbyLink> = ({
	children,
	to,
	activeClassName,
	partiallyActive,
	ref,
	...other
}) => {
	const { authenticationAction }: AuthenticationType = Authentication()

	const [target, setTarget] = useState<string>('_blank')
	const [rel, setRel] = useState<string>('noopener noreferrer')
	// Assumes that any internal link (intended for Gatsby)
	// will start with exactly one slash, and that anything else is external.
	const internal = /^\/(?!\/)/.test(to)
	const { signup, signin } = config.auth

	if (!ref) {
		ref = createRef<HTMLAnchorElement>()
	}

	const preventActionIfAnchorType = (e: MouseEvent) => {
		e.preventDefault()
	}

	useEffect(() => {
		if (to !== '' && to?.includes('#')) {
			setTarget('')
			setRel('')
		}

		if (to === '#') {
			ref?.current?.addEventListener('click', (e: MouseEvent) =>
				preventActionIfAnchorType(e)
			)
		}

		return () => {
			if (to === '#') {
				ref?.current?.removeEventListener('click', (e: MouseEvent) =>
					preventActionIfAnchorType(e)
				)
			}
		}
	}, [])

	if (to === signup || to === signin) {
		return (
			<a
				href={to}
				{...other}
				ref={ref}
				onClick={authenticationAction(to as AuthenticationActionType)}
			>
				{children}
			</a>
		)
	}

	// Use Gatsby Link for internal links, and <a> for others
	if (internal) {
		return (
			<GatsbyLink
				to={to}
				activeClassName={activeClassName}
				partiallyActive={partiallyActive}
				ref={ref.current?.toString()}
				{...other}
			>
				{children}
			</GatsbyLink>
		)
	}

	return (
		<a href={to} {...other} ref={ref} {...{ target, rel }}>
			{children}
		</a>
	)
}

export { navigate }

export default Link
