import React, { FC, useEffect } from 'react'
import { navigate } from 'gatsby'
import Authentication, { AuthenticationType } from './Authentication'

const RedirectComponent: FC = () => {
	const { authenticationSignin }: AuthenticationType = Authentication()

	useEffect(() => {
		authenticationSignin()
	}, [])

	return null
}

export default RedirectComponent
