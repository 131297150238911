import React, { ReactElement, useContext, useState } from 'react'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { LanguageEnum } from '@services/constants'
import {
	authorityCreateAccount,
	authRedirectPage,
	loginRequest
} from '@utils/authConfig'
import config from '@utils/config'
import Modal from '@components/ui/modal'
import Text from '@components/ui/text'
import { navigate } from '@components/ui/link'

export type AuthenticationActionType = 'signup' | 'signin'

export type AuthenticationType = {
	isAuthenticated: boolean
	authenticationAction: (action: AuthenticationActionType) => (e) => void
	authenticationSignin: () => void
	authenticationSignup: () => void
	authenticationModal: () => ReactElement
	authenticationRedirectSignIn: (link: string) => void
}

const Authentication = (): AuthenticationType => {
	const { instance } = useMsal()
	const { language, pageData } = useContext<AppContextProps>(AppStateContext)

	const isAuthenticated: boolean = useIsAuthenticated()

	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

	/*
		Signup and Signin values are need it to be available to trigger the authentication 'signin' | 'signup'
		These values will most be used in strapi as anchor links to trigger these actions.
		values: '#signin' & '#signup'
	*/
	const { signup, signin } = config.auth

	const extraQueryParameters = {
		ui_locales: `${language || LanguageEnum.FR}`
	}

	const Signup = () => {
		instance.loginRedirect({
			...loginRequest,
			...authorityCreateAccount,
			extraQueryParameters
		})
	}

	const Signin = () => {
		instance.loginRedirect({
			...loginRequest,
			extraQueryParameters
		})
	}

	const Redirect = (link: string) => {
		if (!isAuthenticated) {
			const authRedirect: string = config.localStorage.authRedirect

			localStorage.setItem(authRedirect, link)

			instance.loginRedirect({
				...loginRequest,
				redirectUri: authRedirectPage,
				extraQueryParameters
			})

			return
		}

		navigate(link)
	}

	const AuthenticatedModal = () => {
		return (
			<Modal
				isOpen={isOpenModal}
				onSetIsOpen={setIsOpenModal}
				type={'info'}
				cancelButtonText={pageData?.assets?.authenticated_modal_cancel_btn_info}
			>
				<Text content={pageData?.assets?.authenticated_modal_text_info} />
			</Modal>
		)
	}

	const AuthenticationAction = (action: AuthenticationActionType) => (e) => {
		e.preventDefault()

		if (isAuthenticated) {
			return setIsOpenModal(true)
		}

		switch (action) {
			case signup as AuthenticationActionType:
				Signup()
				break
			case signin as AuthenticationActionType:
				Signin()
				break
			default:
				break
		}
	}

	return {
		isAuthenticated,
		authenticationSignin: Signin,
		authenticationSignup: Signup,
		authenticationRedirectSignIn: Redirect,
		authenticationAction: AuthenticationAction,
		authenticationModal: AuthenticatedModal
	}
}

export default Authentication
