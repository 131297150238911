export default {
	baseUrl: '/articles',
	baseUrlMatch: /^\/articles$|^\/articles\/$/,
	detailsPathMatch: /\/articles\/[a-z-A-Z0-9-]*/,
	searchLinkText: '#search',
	relatedRequests: [
		{
			requestId: '20f655e4-0736-e911-a989-000d3af49c6c',
			relatedRequestId: 'materials-or-waste-not-collected'
		},
		{
			requestId: '1af655e4-0736-e911-a989-000d3af49c6c',
			relatedRequestId: 'materials-or-waste-not-collected'
		},
		{
			requestId: '26f655e4-0736-e911-a989-000d3af49c6c',
			relatedRequestId: 'materials-or-waste-not-collected'
		},
		{
			requestId: '12f655e4-0736-e911-a989-000d3af49c6c',
			relatedRequestId: 'materials-or-waste-not-collected'
		},
		{
			requestId: '30f655e4-0736-e911-a989-000d3af49c6c',
			relatedRequestId: 'bin-lost-stole-or-incorrectly-replace'
		},
		{
			requestId: '32f655e4-0736-e911-a989-000d3af49c6c',
			relatedRequestId: 'bin-lost-stole-or-incorrectly-replace'
		},
		{
			requestId: 'd45727f0-0c2f-e911-a98f-000d3af49fdb',
			relatedRequestId: 'traffic-light-and-pedestrian-light'
		},
		{
			requestId: 'd05727f0-0c2f-e911-a98f-000d3af49fdb',
			relatedRequestId: 'traffic-light-and-pedestrian-light'
		},
		{
			requestId: 'c61d0d63-a76d-e911-a980-000d3af49211',
			relatedRequestId: 'pot-hole'
		},
		{
			requestId: 'e45727f0-0c2f-e911-a98f-000d3af49fdb',
			relatedRequestId: 'pot-hole'
		},
		{
			requestId: '025827f0-0c2f-e911-a98f-000d3af49fdb',
			relatedRequestId: 'pot-hole'
		}
	]
}
