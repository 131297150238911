import Breakpoints from '@utils/breakpoints'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	section: string
	h2: string
	h4: string
	img: string
	ul: string
	li: string
	link: string
	rowTitle: string
	liImg: string
	rowSection: string
	otherRequest: string
	button: string
	subtitle: string
	filter: string
	outlineButton: string
	dropdown: string
	dropdownActive: string
	checkbox: string
	checkboxContainer: string
	checkboxInputs: string
	label: string
	textContent: string
	accordion: string
	btn: string
	tag: string
	btnTitle: string
	category: string
	panel: string
	icon: string
	buttonActive: string
	loader: string
	requestInfoSection: string
}

export const classes: ClassType = makeClasses({
	section: {
		margin: '50px',
		[Breakpoints.maxWidth('sm')]: {
			margin: '20px 20px'
		}
	},
	img: {
		width: '40px',
		[Breakpoints.maxWidth('sm')]: {
			width: '35px'
		}
	},
	h4: {
		fontSize: '22px',
		margin: '0 10px 20px 0',
		fontWeight: 500,
		color: Colors.lightBlack,
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '20px'
		}
	},
	li: {
		display: 'flex',
		alignItems: 'flex-start',
		marginBottom: '10px',
		paddingLeft: 0
	},
	ul: {
		listStyle: 'none',
		padding: '25px 15px 15px 20px',
		marginBottom: '40px',
		lineHeight: '22px',
		background: Colors.white
	},
	link: {
		textDecoration: 'none',
		color: Colors.secondary,
		':hover': {
			textDecoration: 'underline'
		}
	},
	rowTitle: {
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		marginBottom: '30px'
	},
	liImg: {
		width: '20px',
		marginTop: '1px',
		marginRight: '5px'
	},
	rowSection: {
		marginBottom: '20px'
	},
	otherRequest: {
		background: Colors.secondary,
		padding: '40px 50px',
		fontSize: '22px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '30px'
		},
		p: {
			color: Colors.white,
			margin: 0
		}
	},
	button: {
		...ButtonStyle,
		color: Colors.white,
		borderColor: Colors.white,
		justifyContent: 'center',
		width: '100%',
		fontSize: '16px',
		maxWidth: '155px',
		padding: '15px 0',
		marginTop: '16px'
	},
	subtitle: {
		borderBottom: `1px solid ${Colors.lightGrey6}`,
		paddingBottom: '30px',
		marginBottom: '30px',
		fontSize: '20px',
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '16px'
		}
	},
	outlineButton: {
		background: 'none',
		border: `1px solid ${Colors.secondary}`,
		color: Colors.secondary,
		padding: '15px 30px',
		fontSize: '16px',
		cursor: 'pointer',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		marginBottom: '30px',
		fontWeight: 500,
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		gap: '5px',
		':hover': {
			color: Colors.white,
			borderColor: Colors.darkBlue2,
			background: Colors.darkBlue2
		},
		[Breakpoints.maxWidth('sm')]: {
			padding: '10px 20px',
			fontSize: '13px'
		}
	},
	label: {
		position: 'relative',
		paddingLeft: '27px',
		fontWeight: 'normal',
		cursor: 'pointer',
		lineHeight: '17px',
		margin: '5px 0',
		':before': {
			content: "''",
			position: 'absolute',
			top: '0',
			left: '0',
			width: '16px',
			height: '16px',
			border: '1px solid #a1a1a1',
			background: '#fff',
			outline: 'none !important'
		},
		':after': {
			content: '" "',
			position: 'absolute',
			top: '4px',
			left: '4px',
			width: '10px',
			height: '10px',
			background: '#0054A6',
			outline: 'none',
			opacity: '0',
			MsFilter: 'alpha(opacity=0)',
			filter: 'alpha(opacity=0)',
			transition: 'all .15s ease-out'
		}
	},
	dropdown: {
		display: 'none',
		marginTop: '-25px',
		padding: '20px',
		fontSize: '16px',
		position: 'absolute',
		backgroundColor: '#fff',
		minWidth: '220px',
		boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
		zIndex: '1',
		borderRadius: '3px'
	},
	dropdownActive: {
		display: 'block !important'
	},
	checkbox: {
		margin: '10px 0 0',
		border: '0',
		padding: '0',
		clear: 'both'
	},
	checkboxContainer: {
		marginBottom: '15px',
		'& input:checked ~ label:after': {
			opacity: '.99'
		}
	},
	checkboxInputs: {
		position: 'absolute',
		verticalAlign: 'baseline',
		clip: 'rect(1px, 1px, 1px, 1px)'
	},
	textContent: {
		'& p, li': {
			margin: '0 0 15px',
			padding: '0',
			fontWeight: '400',
			fontSize: '18px',
			lineHeight: '25px'
		}
	},
	panel: {
		display: 'block',
		padding: '30px 40px 50px 30px',
		[Breakpoints.maxWidth('sm')]: {
			padding: '20px 20px 30px 10px'
		}
	},
	btn: {
		padding: '15px 30px',
		fontSize: '16px',
		display: 'inline-block',
		fontWeight: '500',
		lineHeight: '1em',
		MozBorderRadius: '23px',
		borderRadius: '23px',
		transition: 'all 0.35s ease',
		MsTransition: 'all 0.35s ease',
		background: '#0054A6',
		textDecoration: 'none',
		color: Colors.white,
		cursor: 'pointer',
		border: '1px solid #0054a6',
		':hover': {
			color: Colors.white,
			borderColor: Colors.darkBlue2,
			background: Colors.darkBlue2
		}
	},
	btnTitle: {
		fontSize: '22px',
		fontWeight: '500',
		marginBottom: '15px',
		display: 'inline-block',
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '18px'
		}
	},
	tag: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: '10px'
	},
	category: {
		fontSize: '13px',
		background: '#eee',
		padding: '3px 15px',
		color: '#0054a6',
		borderRadius: '23px',
		fontWeight: '500',
		whiteSpace: 'nowrap',
		display: 'inline-block'
	},
	accordion: {
		backgroundColor: Colors.white,
		marginBottom: '15px',
		':hover': {
			boxShadow: '0 0 20px rgba(0,0,0,0.1)',
			transition: '0.3s'
		},
		'& .button': {
			padding: '30px 30px 30px 40px',
			width: '100%',
			textAlign: 'left',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-start',
			gap: '30px',
			overflow: 'visible',
			margin: '0',
			outline: '0',
			border: '0',
			background: 'none transparent',
			color: 'inherit',
			verticalAlign: 'middle',
			textTransform: 'none',
			font: 'inherit',
			lineHeight: 'normal',
			userSelect: 'none',
			marginLeft: '20px',
			':before': {
				top: 'unset !important',
				[Breakpoints.maxWidth('sm')]: {
					left: '-5px'
				}
			},
			':after': {
				top: 'unset !important',
				[Breakpoints.maxWidth('sm')]: {
					left: '-5px'
				}
			},
			'& + div[aria-hidden=false]': {
				borderTop: `1px solid ${Colors.lightGrey6}`
			},
			[Breakpoints.maxWidth('sm')]: {
				padding: '20px 20px 20px 20px'
			}
		}
	},
	icon: {
		width: '20px',
		height: '20px'
	},
	buttonActive: {
		color: Colors.white,
		borderColor: Colors.darkBlue2,
		background: Colors.darkBlue2
	},
	filter: {
		width: 'max-content'
	},
	loader: {
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '20px',
		height: '20px',
		margin: '0px 15px',
		animation: 'spin 2s linear infinite'
	},
	requestInfoSection: {
		fontWeight: '600',
		fontSize: '17px',
		lineHeight: '25px',
		margin: '10px 0px 20px',

		span: {
			color: Colors.secondary
		}
	}
})

export type strapiRes = {
	id: number
	attributes: {
		request_id: string
		Cacher: number
		title: string
		description: string
		name: string
	}
}

export type strapiDataRes = {
	data: strapiRes[]
	meta: {
		pagination: {
			pageCount: number
		}
	}
}

export const REQUEST_HIDDEN = {
	Oui: true,
	Non: false
}

export const numberMaxOfRequest = 10000

export const buttonTextDic = {
	reports: 'create_page_make_a_report',
	programsAndGrants: 'create_page_fill_out_the_application_form',
	informationsRequests: 'page_create_request_make_an_information_request',
	registrationsAndApplications: 'create_page_complete_the_registration_form',
	reclamation: 'create_page_complete_the_reclamation_form',
	permetsAuthorizationsAndCertificates:
		'create_page_fill_out_the_application_form',
	declarations: 'create_page_make_a_declaration_form',
	other: 'create_page_fill_out_the_application_form'
}
