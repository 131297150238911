import Breakpoints from '@utils/breakpoints'
import config from '@utils/config'

export const Colors = {
	primary: '#222d65',
	red: 'red',
	secondary: '#0054a6',
	secondaryBackground: 'rgba(0, 84, 166, 0.2)',
	secondaryLighten1: '#337ab7',
	purple: '#642850',
	lightPurple: 'rgba(100,40,80, 0.2)',
	white: '#fff',
	backgroundConnected: '#8db7e1',
	primaryConnected: '#aaa',
	grey: '#cccccc',
	brown: '#333333',
	porcelainGrey: '#f6f7f8',
	lightGrey: '#f3f3f3',
	lightGrey2: '#999999',
	lightGrey3: '#a1a1a1',
	lightGrey4: '#f2f2f3',
	lightGrey5: '#ccd1d9',
	lightGrey6: '#c9c9c9',
	lightGrey7: '#dee2e6',
	lightGray8: '#c6c6c6',
	lightYellow: '#ffb91e',
	lightGreen: '#009c4c',
	blue: '#3498db',
	lightBlue: '#8db7e1',
	lightBlueTransparent70: 'rgba(141,183,225,0.7)',
	lightBlueTransparent50: 'rgba(141,183,225,0.5)',
	lightBlueTransparent20: 'rgba(141,183,225,0.2)',
	darkGrey: '#666666',
	darkGrey2: '#888',
	black: '#000000',
	black2: '#212529',
	lightGreyTransparent: 'rgba(0,0,0,0.07)',
	blackTransparent10: 'rgba(0,0,0,0.1)',
	blackTransparent15: 'rgba(0,0,0,0.15)',
	blackTransparent20: 'rgba(0,0,0,0.2)',
	blackTransparent30: 'rgba(0,0,0,0.3)',
	blackTransparent50: 'rgba(0,0,0,0.5)',
	lightBlack: '#0a0a0a',
	greyIron: '#e6e7e8',
	greyAlto: '#dddddd',
	ligthGrey2: '#f7f7f8',
	whiteTransparent: 'rgba(255, 255, 255, 0.07)',
	whiteTransparent2: 'rgba(255,255,255, .1)',
	darkBlue: '#495057',
	darkBlue2: '#01236a',
	transparentBlack: 'rgba(0,0,0,.175)',
	ligthPurple: '#a81b8d',
	errorRed: '#e12324',
	errorRedTransparent20: 'rgba(225,35,36,0.2)',
	veryDarkCyan: '#006b35',
	vividOrange: '#f68b1f',
	darkBlue1: '#222d65',
	darkWhite: '#eee',
	dark: 'rgb(51,51,51)'
}

export const Indexes = {
	index1: 10,
	index5: 500,
	index10: 1000,
	index11: 1100
}

export const Constants = {
	fontStack: 'Roboto, Arial, Helvetica, sans-serif'
}

const Container = {
	margin: '40px',
	background: '#fff',
	height: 'calc(100vh - 80px)',
	width: 'calc(100% - 80px)',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column' as 'column'
}

export const ButtonLinkTypeEnum = {
	PRIMARY_BUTTON: 'primaryButton',
	DISABLED_BUTTON: 'disabledButton',
	SECONDARY_BUTTON: 'secondaryButton',
	LINK: 'link',
	BUTTON: 'button'
}

export const Partials = {
	Container
}

export const TransitionStyles = {
	entering: { opacity: 0, visibility: 'hidden' },
	entered: { opacity: 1, visibility: 'visible' },
	exiting: { opacity: 1, visibility: 'visible' },
	exited: { opacity: 0, visibility: 'hidden' }
}

export const CSSTransitionStyles = {
	enter: {
		opacity: 0
	},
	enterActive: {
		opacity: 1,
		transition: `opacity ${config.transition.duration}ms ease-in`
	},
	exit: {
		opacity: 1
	},
	exitActive: {
		opacity: 0,
		transition: `opacity ${config.transition.duration}ms ease-in`
	}
}

export const ButtonStyle = {
	position: 'relative' as 'relative',
	cursor: 'pointer',
	display: 'inline-flex',
	alignItems: 'center',
	background: 'transparent',
	fontWeight: 500,
	lineHeight: 1,
	border: '1px solid',
	borderRadius: '23px',
	transition: 'all 0.35s ease',
	textDecoration: 'none',
	':hover': {
		color: Colors.white,
		borderColor: Colors.darkBlue2,
		background: Colors.darkBlue2
	}
}

export const CreateRequestStyle = {
	container: {
		maxWidth: '1060px',
		padding: '40px 80px',
		margin: 'auto',
		[Breakpoints.maxWidth('xl')]: {
			padding: '40px'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '0'
		}
	},
	section: {
		background: Colors.white,
		padding: '40px',
		marginTop: '20px',
		p: {
			margin: 0
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '30px 20px'
		}
	},
	h3: {
		color: Colors.primary,
		marginTop: 0,
		marginBottom: '25px'
	}
}
