import { PublicClientApplication } from '@azure/msal-browser'

// Config object to be passed to Msal on creation
export const msalConfig = {
	auth: {
		clientId: `${process.env.CLIENT_ID}`,
		authority: `${process.env.AUTHORITY}`,
		knownAuthorities: process.env.KNOWN_AUTHORITIES?.split(', '),
		redirectUri: '/auth',
		postLogoutRedirectUri: '/',
		navigateToLoginRequestUrl: false
	},
	cache: {
		cacheLocation: 'localStorage' // The user should stay connect if he/she opens a new tab.
	}
}

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
	scopes: ['openid', 'offline_access']
}

export const authorityCreateAccount = {
	authority: `${process.env.AUTHORITY_CREATE_ACCOUNT}`
}

export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me'
}

export const authRedirectPage: string = '/auth-redirect'

export const acquireAccessToken = async () => {
	const msalInstance = new PublicClientApplication(msalConfig)
	msalInstance.enableAccountStorageEvents()
	const activeAccount = msalInstance.getActiveAccount() // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
	const accounts = msalInstance.getAllAccounts()

	if (!activeAccount) {
		if (accounts.length === 0) {
			/*
			 * User is not signed in. Throw error or wait for user to login.
			 * Do not attempt to log a user in outside of the context of MsalProvider
			 */
			throw new Error('Error while acquiring token. User is not signed it.')
		}
		msalInstance.setActiveAccount(accounts[0])
	}
	const request = {
		scopes: loginRequest.scopes,
		account: activeAccount || accounts[0]
	}

	try {
		const authResult = await msalInstance.acquireTokenSilent(request)
		return authResult.idToken
	} catch (error: any) {
		const errorCode = error.errorCode
		if (
			errorCode === 'consent_required' ||
			errorCode === 'interaction_required'
		) {
			await msalInstance.acquireTokenRedirect(request)
		}
		throw error
	}
}
