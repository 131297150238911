import { ICustomDate } from '@services/types'
import { formatDate, formatDateAndTime, formatTime } from '@utils/methods'

export class CustomDate implements ICustomDate {
	localDate: Date
	UTCDateString: string
	ISODateString: string
	localDateString: string
	localTimeString: string
	localDateAndTimeString: string

	constructor(UTCDateString: string) {
		this.UTCDateString = new Date(UTCDateString).toUTCString()
		this.ISODateString = new Date(UTCDateString).toISOString()
		this.localDate = new Date(UTCDateString)
		this.localDateString = formatDate(UTCDateString)
		this.localTimeString = formatTime(UTCDateString)
		this.localDateAndTimeString = formatDateAndTime(UTCDateString)
	}

	static diff = (date1?: CustomDate, date2?: CustomDate): number => {
		if (!date1) {
			return 0
		}

		return date1.localDate.getTime() - (!!date2 ? date2.localDate.getTime() : 0)
	}

	static currentDate = () => {
		const date = new Date().toISOString().match(/\d{4}-\d{2}-\d{2}/)!

		return date[0]
	}
}
