import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ISidebarState } from '@services/store/sidebar/type'

const initialState: ISidebarState = {
	isSidebarOpen: false
}

export const slice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		doOpenSidebar: (state, action: PayloadAction<boolean>) => {
			state.isSidebarOpen = action.payload
		}
	}
})

export default slice.reducer
export const { doOpenSidebar } = slice.actions
