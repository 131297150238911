import Breakpoints from '@utils/breakpoints'
import { Colors } from '@utils/css-variables'
import { makeClasses } from '@utils/styles'
import { INavLink } from '.'

const logoHeightLg: string = '163px'
const menuBottomContainerHeight: string = '76px'
const menuBottomContainerHeightMobile: string = '63px'

export type ISidebar = {
	navItems: INavLink[]
	menuItems: INavLink[]
}

type MenuClasses = {
	root: string
	logoRoot: string
	logoLink: string
	logo: string
	navWrap: string
	navList: string
	navItem: string
	navLinkHasChild: string
	navLink: string
	navLabel: string
	navImg: string
	navInfoWrap: string
	navInfoLink: string
	navLinkActive: string
	chevron: string
	chevronImg: string
	subNav: string
	subNavList: string
	subNavItem: string
	subNavLink: string
	subNavLabel: string
	navActive: string
}

type Classes = {
	root: string
	listRoot: string
	listItem: string
	listItemNotActive: string
	listItemLink: string
	listItemIcon: string
	listItemLabel: string
	menuButton: string
	noConnectedListItem: string
}

export const menuClasses: MenuClasses = makeClasses({
	root: {
		position: 'fixed' as 'fixed',
		width: '80%',
		height: '100vh',
		left: '-100%',
		top: '0',
		background: Colors.white,
		'z-index': 11,
		boxShadow: `2px 0 15px ${Colors.blackTransparent10}`,
		[Breakpoints.minWidth('xl')]: {
			display: 'none'
		},
		opacity: 1,
		transition: 'left .4s linear',
		'&.fade': {
			left: '0',
			opacity: 1,
			overflow: 'auto'
		}
	},
	logoRoot: {
		[Breakpoints.minWidth('xl')]: {
			display: 'none'
		},
		height: logoHeightLg,
		display: 'flex',
		justifyContent: 'center'
	},
	logoLink: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		textDecoration: 'none',
		background: 'transparent',
		border: 'none',
		padding: '0 55px'
	},
	logo: {
		display: 'block'
	},
	navWrap: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column' as 'column',
		justifyContent: 'space-between',
		height: `calc(100vh - ${logoHeightLg})`,
		[Breakpoints.minWidth('xl')]: {
			position: 'absolute' as 'absolute',
			top: '80px',
			right: 0,
			width: '100%',
			transformOrigin: 'center top',
			transition: 'transform .6s cubic-bezier(.5,0,.2,1),visibility 0s .6s',
			visibility: 'hidden' as 'hidden',
			overflowY: 'auto' as 'auto',
			transform: 'translateX(100%)',
			backgroundColor: Colors.white,
			height: 'calc(100vh - 140px)',
			paddingBottom: '70px'
		}
	},
	navList: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column' as 'column',
		margin: 0,
		padding: 0,
		listStyle: 'none',
		width: '100%'
	},
	navItem: {
		borderBottom: `1px solid ${Colors.lightGreyTransparent}`,
		lineHeight: 1,
		paddingLeft: 0,
		marginBottom: 0,
		width: '100%',
		'&:not(.no-hover):hover': {
			zIndex: 2,
			'& .chevron_up': {
				transform: 'rotateZ(179deg)',
				transitionDelay: '0.1s'
			},
			'& div.sub_nav': {
				//transform: 'scaleY(1)',
				//height: 'auto',
				//transition: 'transform .3s, height .4s'
				opacity: 1,
				height: 'auto'
			},
			'& .sub_nav_title': {
				opacity: 1,
				transform: 'none',
				transitionDelay: '0.1s',
				transitionDuration: '0.6s',
				transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
			},
			'& .sub_nav_link': {
				opacity: 1,
				transform: 'none',
				transitionDuration: '0.4s',
				transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
			}
		}
	},
	navLinkHasChild: {
		cursor: 'pointer',
		'&:after': {
			transform: 'translate(50%, -50%) rotate(45deg)',
			transitionDelay: '0.2s'
		},
		'&:before': {
			transform: 'scaleX(1)',
			transitionTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
		},
		'& button': {
			display: 'flex',
			flexDirection: 'row-reverse',
			alignItems: 'center',
			justifyContent: 'space-between'
		}
	},
	navLink: {
		display: 'block',
		textDecoration: 'none',
		background: 'transparent',
		border: 'none',
		color: Colors.brown,
		padding: '0',
		margin: 0,
		borderRadius: 0,
		width: '100%',
		overflow: 'hidden',
		textAlign: 'left',
		position: 'relative',
		'&:hover': {
			background: Colors.porcelainGrey
		},
		'&:before': {
			content: '""',
			position: 'absolute',
			transition: 'transform .3s cubic-bezier(.5,0,.3,1)'
		}
	},
	navLabel: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		fontFamily: "'Roboto'!important",
		fontWeight: 400,
		transition: 'transform .3s cubic-bezier(0,0,.2,1)',
		lineHeight: '19px',
		fontSize: '16px',
		padding: '19px 24px 19px 21px',
		color: Colors.primary,
		[Breakpoints.widthBetween('sidebar', 'sidebarxl')]: {
			lineHeight: '21px',
			padding: '25px 30px'
		},
		[Breakpoints.minWidth('sidebarxl')]: {
			lineHeight: '21px',
			padding: '25px 30px'
		},
		'&:hover': {
			paddingRight: '20px',
			transform: 'translate3d(10px,0,0)'
		}
	},
	navImg: {
		display: 'inline-flex',
		alignItems: 'center',
		marginRight: '10px',
		maxWidth: '22px',
		maxHeight: '22px',
		width: '100%',
		height: '100%'
	},
	navInfoWrap: {
		display: 'flex',
		flexDirection: 'column' as 'column',
		alignItems: 'center',
		width: '100%',
		maxWidth: '100%',
		padding: '30px'
	},
	navInfoLink: {
		display: 'flex',
		alignItems: 'center',
		background: 'transparent',
		border: 'none',
		padding: '5px',
		width: '100%',
		margin: 0,
		color: Colors.secondary,
		lineHeight: '25px',
		fontWeight: 400,
		textDecoration: 'none',
		fontSize: '14px',
		'&:hover': {
			textDecoration: 'underline',
			background: 'transparent',
			borderRadius: 'none'
		}
	},
	navLinkActive: {
		backgroundColor: Colors.porcelainGrey,
		paddingLeft: '10px'
	},
	chevron: {
		float: 'right',
		padding: '19px 24px 19px 21px'
	},
	chevronImg: {
		display: 'inline-flex',
		alignItems: 'center',
		maxWidth: '10px',
		maxHeight: '10px',
		height: '100%',
		width: '15px',
		verticalAlign: 'middle',
		marginRight: 0
	},
	subNav: {
		width: '100%',
		backgroundColor: Colors.primary,
		borderRight: `1px solid ${Colors.whiteTransparent2}`,
		//transform: 'scaleY(0)',
		transformOrigin: 'center top',
		//transition: 'transform .3s cubic-bezier(.5,0,.3,1) .3s',
		color: Colors.white,
		overflowX: 'hidden',
		overflowY: 'auto',
		WebkitOverflowScrolling: 'touch',
		boxShadow: `inset 8px 0 15px ${Colors.blackTransparent20}`,
		position: 'relative',
		transition: 'opacity 0.4s ease-out, height .4s',
		opacity: 0,
		height: 0,
		overflow: 'hidden'
	},
	subNavList: {
		WebkitTextSizeAdjust: '100%',
		lineHeight: 2,
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		color: Colors.white,
		margin: '0',
		padding: '0',
		listStyle: 'none'
	},
	subNavItem: {
		WebkitTextSizeAdjust: '100%',
		lineHeight: 2,
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		color: Colors.white,
		listStyle: 'none',
		borderBottom: `1px solid ${Colors.whiteTransparent2}`,
		transition: 'background-color .3s cubic-bezier(0,0,.2,1)',
		position: 'relative',
		fontSize: '1rem',
		padding: '1.63rem 1.5rem',
		'&:hover': {
			backgroundColor: Colors.whiteTransparent
		}
	},
	subNavLink: {
		WebkitTextSizeAdjust: '100%',
		WebkitFontSmoothing: 'antialiased',
		fontFamily: "'Roboto'",
		listStyle: 'none',
		fontSize: '1rem',
		display: 'block',
		position: 'relative',
		color: Colors.white,
		width: '100%',
		textAlign: 'left',
		opacity: 0,
		transform: 'translateY(100%)',
		transition:
			'opacity .3s cubic-bezier(.55,.055,.675,.19),transform .3s cubic-bezier(.55,.055,.675,.19)',
		textDecoration: 'none',
		lineHeight: '18px'
	},
	subNavLabel: {
		display: 'block',
		transition: 'transform .3s cubic-bezier(0,0,.2,1)'
	},
	navActive: {
		'.nav-link': {
			backgroundColor: Colors.ligthGrey2
		}
	}
})

export const classes: Classes = makeClasses({
	root: {
		background: Colors.white,
		position: 'fixed' as 'fixed',
		bottom: 0,
		width: '100%',
		'z-index': 11,
		borderTop: `1px solid ${Colors.blackTransparent20}`,
		[Breakpoints.minWidth('xl')]: {
			display: 'none'
		}
	},
	noConnectedListItem: {
		maxWidth: '25%!important'
	},
	listRoot: {
		listStyle: 'none',
		paddingLeft: 0,
		margin: 0,
		display: 'flex',
		alignItems: 'center',
		maxWidth: '100%',
		width: '100%'
	},
	listItem: {
		display: 'flex',
		maxWidth: '20%',
		width: '100%',
		margin: 0,
		height: menuBottomContainerHeight,
		[Breakpoints.maxWidth('sm')]: {
			height: menuBottomContainerHeightMobile
		}
	},
	listItemNotActive: {
		'&:hover': {
			background: Colors.greyIron
		}
	},
	listItemLink: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		width: '100%',
		padding: '17px',
		color: `${Colors.darkGrey} !important`,
		textDecoration: 'none',
		[Breakpoints.maxWidth('sm')]: {
			padding: '10px'
		},
		'&.list-item-active': {
			background: Colors.darkWhite,
			color: `${Colors.primary} !important`
		}
	},
	listItemIcon: {
		width: '25px',
		height: '25px',
		[Breakpoints.maxWidth('sm')]: {
			width: '20px',
			height: '20px'
		}
	},
	listItemLabel: {
		display: 'block',
		fontSize: '12px',
		textAlign: 'center',
		paddingTop: '8px',
		lineHeight: 1,
		[Breakpoints.maxWidth('sm')]: {
			paddingTop: '5px',
			fontSize: '9px'
		}
	},
	menuButton: {
		'& > div': {
			width: '100%',
			height: menuBottomContainerHeight,
			[Breakpoints.maxWidth('sm')]: {
				height: menuBottomContainerHeightMobile
			}
		}
	}
})
