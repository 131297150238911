import assert from 'assert'
import { ICommentDTO } from '@services/dtos/comments.dto'
import { ICommentsAttachmentDTO } from '@services/dtos/comments.dto'
import { CommentRepository } from '@services/repositories/comments.repository'
import { IComment, ICommentsAttachment } from '@services/types'
import { CustomDate } from '@services/models/shared.model'
import {
	Exclude,
	Expose,
	instanceToPlain,
	plainToInstance,
	Transform,
	Type
} from 'class-transformer'
import { IRequestDTO } from '@services/dtos/requests.dto'
import { ATTACHMENT_COMMENTS_FIELDS_NAME } from '@services/constants'
import { toFile } from '@utils/methods'

export class CommentsAttachment implements ICommentsAttachment {
	@Expose({ name: 'parent_object_id' })
	@Exclude({ toPlainOnly: true })
	parentObjectId?: string

	@Expose({ name: 'portal_comment_id' })
	@Exclude({ toPlainOnly: true })
	portalCommentId?: string

	@Expose({ name: 'id' })
	@Exclude({ toPlainOnly: true })
	id?: string = ''

	@Expose({ name: 'file_size' })
	fileSize?: number

	@Expose({ name: 'created_on' })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	@Transform(
		({ value }) => {
			return value.ISODateString
		},
		{ toPlainOnly: true }
	)
	dateAdd?: CustomDate

	@Expose({ name: 'mime_type' })
	mimeType: string = ''

	@Expose({ name: 'file_content' })
	fileContent?: Promise<string> | string

	@Expose({ name: 'file_name' })
	fileName: string = ''

	@Exclude()
	public parse(dto: ICommentsAttachment | ICommentsAttachmentDTO) {
		Object.assign(this, plainToInstance(CommentsAttachment, dto))
	}

	@Exclude()
	static serialize(
		CommentsAttachment: ICommentsAttachment
	): ICommentsAttachmentDTO {
		const dto = instanceToPlain(CommentsAttachment)

		return dto as ICommentsAttachmentDTO
	}

	@Exclude()
	public async toFile() {
		if (this.fileContent) {
			return await toFile(
				this.fileName,
				this.mimeType,
				this.fileContent as string
			)
		}
	}

	constructor(dto?: ICommentsAttachment | ICommentsAttachmentDTO) {
		if (dto) {
			this.parse(dto)
		}
	}
}

export class Comment implements IComment {
	@Expose()
	@Exclude({ toPlainOnly: true })
	id?: string = ''

	@Expose({ name: 'incident_id' })
	requestId: string = ''

	@Expose({ name: 'subject' })
	subject: string = ''

	@Expose({ name: 'description' })
	comment: string = ''

	@Expose({ name: 'created_on' })
	@Transform(({ value }) => new CustomDate(value), { toClassOnly: true })
	@Transform(({ value }) => value.ISODateString, { toPlainOnly: true })
	dateAdd?: CustomDate

	@Expose({ name: 'portal_message_read' })
	portalMessageRead: boolean = true

	@Expose({ name: 'attachments' })
	@Type(() => CommentsAttachment)
	attachment?: CommentsAttachment[]

	@Exclude()
	files?: File[]

	@Expose({ name: 'document_type' })
	// RequestCategoryEnum or just string ?
	documentType?: string = ''

	@Exclude()
	_isParsed: boolean = false

	@Exclude()
	private _repository: CommentRepository

	@Exclude()
	public parse(dto: ICommentDTO) {
		Object.assign(this, plainToInstance(Comment, dto))
	}

	constructor(dto?: IComment | ICommentDTO) {
		if (dto) {
			dto._isParsed ? this.parse(dto as ICommentDTO) : Object.assign(this, dto)
		}
		this._repository = new CommentRepository()
	}

	@Exclude()
	static serialize(comments: IComment): ICommentDTO {
		const dto = instanceToPlain(comments, {})

		return dto as ICommentDTO
	}

	@Exclude()
	public async saveAsync() {
		const dto = Comment.serialize(this)

		assert(!!dto, 'Can not save empty comments')

		this.id = await this._repository.postComment(dto)

		await this.saveAttachmentAsync()

		return this.id
	}

	static async build(
		requestId: IRequestDTO['incident_id']
	): Promise<Comment[]> {
		const repository = new CommentRepository()
		const dto = await repository.getComments(requestId)

		return dto.map((comment) => {
			comment._isParsed = true
			return new Comment(comment)
		})
	}

	@Exclude()
	public async saveAttachmentAsync() {
		assert(
			this.id,
			'Can not save an attachment if the request was not created before'
		)

		assert(this.files, 'At least a file is needed')

		for (const file of this.files) {
			const attachmentData = new FormData()

			attachmentData.append(ATTACHMENT_COMMENTS_FIELDS_NAME.file, file)
			attachmentData.append(
				ATTACHMENT_COMMENTS_FIELDS_NAME.commentId,
				`${this.id}`
			)

			await this._repository.postAttachment(attachmentData)
		}
	}
}
