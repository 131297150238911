import {
	IAddressCoordinatesDTO,
	IAddressDTO
} from '@services/dtos/addresses.dto'
import { IAddress, IAddressCoordinates } from '@services/types'
import {
	ADDRESS_IN_MUNICIPALITY,
	ADDRESS_LATITUDE,
	ADDRESS_LONGITUDE
} from '@services/constants'
import config from '@utils/config'
import {
	Exclude,
	Expose,
	instanceToPlain,
	plainToInstance,
	Transform
} from 'class-transformer'
import { Engine } from 'json-rules-engine'

export class Address implements IAddress {
	@Expose({ name: 'address' })
	address: string = ''

	@Expose({ name: 'appartment' })
	apartment: string = ''

	@Expose({ name: 'city' })
	city: string = ''

	@Expose({ name: 'state_or_province' })
	state: string = ''

	@Expose({ name: 'postal_code' })
	postalCode: string = ''

	@Expose({ name: 'country' })
	country: string = ''

	@Exclude()
	cadastralAddress: string = ''

	@Expose({ name: 'coordinates' })
	@Transform(({ value }) => new AddressCoordinates(value), {
		toClassOnly: true
	})
	@Transform(({ value }) => AddressCoordinates.serialize(value), {
		toPlainOnly: true
	})
	coordinates?: AddressCoordinates | undefined

	@Exclude()
	private _engine: Engine

	@Exclude()
	inMunicipality: boolean = true

	@Exclude()
	hasAddressEnteredManually?: boolean

	@Exclude()
	inLaval = async (): Promise<boolean> => {
		if (!this.coordinates) {
			return false
			// TODO: should we throw here ?
		}

		const { events } = await this._engine.run(this.coordinates)

		return events?.length > 0
			? events[0].type === ADDRESS_IN_MUNICIPALITY
			: false
	}

	constructor(addressDTO?: IAddressDTO) {
		this._engine = new Engine()
		this._engine.addRule({
			conditions: {
				all: [
					{
						all: [
							{
								fact: ADDRESS_LATITUDE,
								operator: 'lessThanInclusive',
								value: config.geolocation.MUNICIPALITY_BOUNDARY_BOX.latitude.max
							},
							{
								fact: ADDRESS_LATITUDE,
								operator: 'greaterThanInclusive',
								value: config.geolocation.MUNICIPALITY_BOUNDARY_BOX.latitude.min
							}
						]
					},
					{
						all: [
							{
								fact: ADDRESS_LONGITUDE,
								operator: 'lessThanInclusive',
								value:
									config.geolocation.MUNICIPALITY_BOUNDARY_BOX.longitude.max
							},
							{
								fact: ADDRESS_LONGITUDE,
								operator: 'greaterThanInclusive',
								value:
									config.geolocation.MUNICIPALITY_BOUNDARY_BOX.longitude.min
							}
						]
					}
				]
			},
			event: {
				// define the event to fire when the conditions evaluate truthy
				type: ADDRESS_IN_MUNICIPALITY,
				params: {
					message: 'Address in municipality - Laval'
				}
			}
		})

		if (addressDTO) {
			this.parse(addressDTO)
		}

		if (this.coordinates) {
			this._engine
				.run(this.coordinates)
				.then(
					({ events }) =>
						(this.inMunicipality =
							events?.length > 0
								? events[0].type === ADDRESS_IN_MUNICIPALITY
								: false)
				)
		} else {
			this.coordinates = new AddressCoordinates()
		}
	}

	@Exclude()
	update(address: Partial<Address>) {
		Object.assign(this, address)
	}

	@Exclude()
	parse(dto: IAddressDTO) {
		Object.assign(this, plainToInstance(Address, dto))
	}

	static serialize(address: Address): IAddressDTO {
		return instanceToPlain(address)
	}

	@Exclude()
	prepareProperties() {
		if (this.inMunicipality) {
			this.city = config.geolocation.MUNICIPALITY_ADDRESS.city
			this.country = config.geolocation.MUNICIPALITY_ADDRESS.country
			this.state = config.geolocation.MUNICIPALITY_ADDRESS.state

			return
		}

		this.coordinates = undefined
	}
}

export class AddressCoordinates implements IAddressCoordinates {
	@Expose({ name: 'latitude' })
	latitude?: number

	@Expose({ name: 'longitude' })
	longitude?: number

	constructor(addressCoordinatesDTO?: IAddressCoordinatesDTO) {
		if (addressCoordinatesDTO) {
			this.parse(addressCoordinatesDTO)
		}
	}

	@Exclude()
	update(addressCoordinates: Partial<AddressCoordinates>) {
		Object.assign(this, addressCoordinates)
	}

	@Exclude()
	validate() {
		return this.latitude && this.longitude
	}

	@Exclude()
	parse(addressCoordinatesDTO: IAddressCoordinatesDTO) {
		Object.assign(
			this,
			plainToInstance(AddressCoordinates, addressCoordinatesDTO)
		)
	}

	static serialize(
		addressCoordinates?: AddressCoordinates
	): IAddressCoordinatesDTO {
		return addressCoordinates
			? instanceToPlain(addressCoordinates)
			: ({} as IAddressCoordinatesDTO)
	}
}
