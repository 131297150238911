import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'

export const classes = makeClasses({
	root: {
		position: 'relative' as 'relative',
		bottom: 'auto',
		width: '100%',
		background: Colors.lightBlack,
		color: Colors.white,
		padding: '20px 30px 15px',
		fontSize: '13px',
		lineHeight: '25px',
		fontWeight: 400,
		display: 'flex',
		flexDirection: 'column' as 'column',
		[Breakpoints.minWidth('md')]: {
			flexDirection: 'row' as 'row',
			justifyContent: 'space-between'
		}
	},
	compactRoot: {
		padding: '10px 30px 10px !important',
		position: 'absolute',
		left: 0,
		bottom: 0
	},
	logo: {
		width: '70px',
		height: '25px'
	},
	link: {
		display: 'inline-flex',
		alignItems: 'center',
		color: Colors.lightBlue,
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		},
		'&:visited': {
			color: Colors.lightBlue
		},
		'&:active': {
			color: Colors.lightBlue
		}
	},
	div: {
		textAlign: 'center' as 'center'
	},
	compactDiv: {
		margin: 'auto !important'
	},
	span: {
		display: 'inline-flex',
		alignItems: 'flex-end',
		padding: '0 10px',
		p: {
			margin: 0
		}
	},
	socials: {
		listStyle: 'none',
		paddingLeft: 0,
		margin: 0,
		height: '30px',
		li: {
			display: 'inline-flex',
			paddingLeft: 0,
			margin: '0 5px',
			a: {
				display: 'inline-block',
				height: '30px'
			},
			img: {
				width: '30px',
				height: '30px'
			}
		},
		[Breakpoints.maxWidth('md')]: {
			marginTop: '5px'
		}
	},
	copyRight: {}
})

export const whoCanSeeThisPageLink: string =
	'https://www.quebec.ca/en/gouvernement/politiques-orientations/langue-francaise/modernization-charter-french-language#c214034'
