type Breakpoint =
	| 'xxs'
	| 'xs'
	| 'sm'
	| 'md'
	| 'lg'
	| 'laptop'
	| 'xl'
	| 'xxl'
	| 'sidebar'
	| 'sidebarlg'
	| 'sidebarxl' // 'xxs' will be default

interface IBreakpoints {
	keys: Breakpoint[]
	keysToExcludes: Breakpoint[]
	values: { [key in Breakpoint]: number }
	minWidth: (key: Breakpoint | number) => string
	maxWidth: (key: Breakpoint | number, noMedia?: boolean) => string
	widthBetween: (start: Breakpoint | number, end: Breakpoint | number) => string
	only: (key: Breakpoint) => string
}

// Todo: split Media object and methods (maxWidth, minWidth) and breakpoints values...
const Breakpoints: IBreakpoints = {
	keys: [
		'xxs',
		'xs',
		'sm',
		'md',
		'lg',
		'laptop',
		'xl',
		'xxl',
		'sidebar',
		'sidebarlg',
		'sidebarxl'
	],
	values: {
		xxs: 0,
		xs: 426, // mobile Large
		sm: 576,
		md: 768,
		lg: 992,
		laptop: 1024,
		xl: 1200,
		xxl: 1400,
		sidebar: 1600,
		sidebarlg: 1700,
		sidebarxl: 1800
	},
	keysToExcludes: ['xxl', 'sidebar', 'sidebarlg', 'sidebarxl'], // keys to excludes for only function
	minWidth: (key: Breakpoint | number) => {
		if (Breakpoints.keys.includes(key as Breakpoint))
			return `@media (min-width: ${Breakpoints.values[key]}px)`
		return `@media (min-width: ${key}px)`
	},
	maxWidth: (key: Breakpoint | number, noMedia: boolean = false) => {
		let media: string = '@media '
		if (noMedia) media = ''
		if (Breakpoints.keys.includes(key as Breakpoint)) {
			if (key === 'xxs')
				throw new Error(`Invalid key value, '${key}' is not permitted.`)
			return `${media}(max-width: ${Breakpoints.values[key] - 1}px)`
		}
		return `${media}(max-width: ${key}px)`
	},
	widthBetween: (start: Breakpoint | number, end: Breakpoint | number) =>
		`${Breakpoints.minWidth(start)} and ${Breakpoints.maxWidth(end, true)}`,
	only: (key: Breakpoint) => {
		if (Breakpoints.keysToExcludes.includes(key))
			return Breakpoints.minWidth(key)
		const pos: number = Breakpoints.keys.findIndex((b: Breakpoint) => b === key)
		const max: Breakpoint = Breakpoints.keys[pos + 1]
		return `${Breakpoints.minWidth(key)} and ${Breakpoints.maxWidth(max)}`
	}
}

export default Breakpoints
