import React, { FC, useContext, useRef, Fragment } from 'react'

import Link, { navigate } from '@components/ui/link'
import logoLaval from '@images/logo-laval.svg'
import { Icon } from '@components/ui/Icon'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import { chevronRightIcon, externalLink } from '@images/icons'
import { INavLink } from '@components/ui/sidebar'
import * as pageUtils from '@components/ui/sidebar/__desktop.sidebar.utils'
import { joinClasses } from '@utils/styles'
import config from '@utils/config'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	LanguageEnum
} from '@services/constants'
import { authRedirectPage, loginRequest } from '@utils/authConfig'

const DesktopSidebar: FC<pageUtils.ISidebar> = ({ navItems }) => {
	const subNavRef = useRef<HTMLDivElement>(null)
	const { pageData, language } = useContext(AppStateContext)
	const isUserConnected: boolean = useIsAuthenticated()
	const { instance } = useMsal()

	const onNavigateToRequest = (link: string) => {
		if (
			!isUserConnected &&
			link.includes(IDENTIFIERS_OF_TN_REQUEST_TYPE.CLAIM_NOTICE)
		) {
			const authRedirect: string = config.localStorage.authRedirect
			// this will send the right language to display on Azure AD B2C
			const extraQueryParameters = {
				ui_locales: `${language || LanguageEnum.FR}`
			}

			localStorage.setItem(authRedirect, link)

			instance.loginRedirect({
				...loginRequest,
				redirectUri: authRedirectPage,
				extraQueryParameters
			})

			return
		}

		navigate(link)
	}

	return (
		<div className={pageUtils.classes.root}>
			<div className={pageUtils.classes.logoRoot}>
				<Link
					to={config.homepage.baseURL}
					className={pageUtils.classes.logoLink}
				>
					<img className={pageUtils.classes.logo} src={logoLaval} alt="logo" />
				</Link>
			</div>
			<div className={pageUtils.classes.navWrap}>
				<ul className={pageUtils.classes.navList}>
					{navItems.map(
						({ icon, link, text, items, active }: INavLink, index: number) => (
							<Fragment key={index}>
								{items ? (
									<li
										key={index}
										className={joinClasses([
											pageUtils.classes.navItem,
											pageUtils.classes.navLinkHasChild,
											active ? pageUtils.classes.navActive : ''
										])}
									>
										<button
											className={`${pageUtils.classes.navLink} ${pageUtils.classes.navLinkHasChild}`}
										>
											<span className={pageUtils.classes.chevron}>
												<Icon
													className={pageUtils.classes.chevronImg}
													src={chevronRightIcon}
												/>
											</span>
											<span className={`${pageUtils.classes.navLabel}`}>
												<Icon src={icon} className={pageUtils.classes.navImg} />
												{text}
											</span>
										</button>
										<div
											ref={subNavRef}
											className={`${pageUtils.classes.subNav} sub_nav`}
										>
											<p
												className={`${pageUtils.classes.subNavTitle} sub_nav_title`}
											>
												Sous-menu
											</p>
											<ul className={pageUtils.classes.subNavList}>
												{items.map(({ link, text }, index: number) => (
													<li
														key={index}
														className={pageUtils.classes.subNavItem}
													>
														<Link
															to={link}
															className={`${pageUtils.classes.subNavLink} sub_nav_link`}
														>
															{text}
														</Link>
													</li>
												))}
											</ul>
										</div>
									</li>
								) : (
									<li
										key={index}
										className={
											pageUtils.classes.navItem +
											` ${active && pageUtils.classes.navActive}`
										}
									>
										<Link
											to={link}
											onClick={() => onNavigateToRequest(link)}
											className={pageUtils.classes.navLink}
										>
											<span className={pageUtils.classes.navLabel}>
												<Icon src={icon} className={pageUtils.classes.navImg} />
												{text}
											</span>
										</Link>
									</li>
								)}
							</Fragment>
						)
					)}
				</ul>
				<div className={pageUtils.classes.navInfoWrap}>
					<Link className={pageUtils.classes.navInfoLink} to={'/faq'}>
						{formatStrapiText(pageData?.assets?.sidebar_faq)}
					</Link>
					<Link
						className={pageUtils.classes.navInfoLink}
						to={'/online-services'}
					>
						{formatStrapiText(pageData?.assets?.sidebar_online_services)}
					</Link>
					<Link
						className={pageUtils.classes.navInfoLink}
						to={pageData.assets?.link_contact_us}
						target={'_blank'}
					>
						{formatStrapiText(pageData?.assets?.sidebar_join_us)}
						<Icon src={externalLink} className={pageUtils.classes.navImg} />
					</Link>
				</div>
			</div>
		</div>
	)
}

export default DesktopSidebar
