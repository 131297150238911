import React, { FC, ReactElement } from 'react'
import Link from '@components/ui/link'
import Authentication, {
	AuthenticationType
} from '@components/auth/Authentication'

type Props = {
	text: string | ReactElement
	className?: string
}

const CreateAccountButton: FC<Props> = ({ className, text }) => {
	const { authenticationSignup }: AuthenticationType = Authentication()

	return (
		<Link
			className={className}
			to="#"
			onClick={authenticationSignup}
			key="createRedirect"
			rel="noopener noreferrer"
		>
			{text}
		</Link>
	)
}

export default CreateAccountButton
