import pino from 'pino'
import { SERVICE_NAME } from './_index'

interface LoggerInformation {
	message: string
	method?: string
	timestamp: string
}

export class LoggerService {
	private _logger: pino.Logger
	method: string = ''
	timestamp: string = new Date().toISOString()
	message: string = ''

	constructor() {
		this._logger = pino({
			level: 'info',
			prettyPrint: true,
			base: { service: SERVICE_NAME }
		})
	}

	public info(message: string, method?: string): void {
		Object.assign(this, { message, method })

		this._logger.info(
			this.formatMessage({
				message: this.message,
				method: this.method,
				timestamp: this.timestamp
			})
		)
	}

	public warn(message: string, method?: string): void {
		Object.assign(this, { message, method })

		this._logger.warn(
			this.formatMessage({
				message: this.message,
				method: this.method,
				timestamp: this.timestamp
			})
		)
	}

	public error(message: string, method?: string): void {
		Object.assign(this, { message, method })

		this._logger.error(
			this.formatMessage({
				message: this.message,
				method: this.method,
				timestamp: this.timestamp
			})
		)
	}

	private formatMessage(information: LoggerInformation): string {
		return `[${information.timestamp}] ${information.method}: ${information.message}`
	}
}
