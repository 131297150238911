import { LanguageEnum } from '@services/constants'

export enum CommunicationMeanEnum {
	NO_PREFERENCE = 'no-preference',
	MON_DOSSIER = 'mon-dossier',
	TELEPHONE_DAY = 'telephone-day',
	TELEPHONE_EVENING = 'telephone-evening',
	TELEPHONE_OTHER = 'telephone-other',
	EMAIL = 'email'
}

export enum GenderEnum {
	MALE = 'male',
	FEMALE = 'female'
}

export const GENDERS: string[] = ['', GenderEnum.MALE, GenderEnum.FEMALE]

export const COMMINUCATION_MEANS: string[] = [
	'',
	CommunicationMeanEnum.NO_PREFERENCE,
	CommunicationMeanEnum.TELEPHONE_DAY,
	CommunicationMeanEnum.TELEPHONE_EVENING,
	CommunicationMeanEnum.TELEPHONE_OTHER,
	CommunicationMeanEnum.EMAIL,
	CommunicationMeanEnum.MON_DOSSIER
]

export const ADDRESS_IN_MUNICIPALITY = 'IN_MUNICIPALITY'
export const ADDRESS_LATITUDE = 'latitude'
export const ADDRESS_LONGITUDE = 'longitude'

export const GENDERS_DICTIONARY: Record<GenderEnum, number> = {
	[GenderEnum.MALE]: 1,
	[GenderEnum.FEMALE]: 2
}

export const LANGUAGES_DICTIONARY: Record<LanguageEnum, number> = {
	[LanguageEnum.FR]: 1,
	[LanguageEnum.EN]: 2
}

export const COMMUNICATION_MEAN_DICTIONARY = {
	[CommunicationMeanEnum.NO_PREFERENCE]: 1,
	[CommunicationMeanEnum.TELEPHONE_DAY]: 2,
	[CommunicationMeanEnum.TELEPHONE_EVENING]: 3,
	[CommunicationMeanEnum.TELEPHONE_OTHER]: 4,
	[CommunicationMeanEnum.EMAIL]: 5,
	[CommunicationMeanEnum.MON_DOSSIER]: 717600000
}
