/**
 * Cette page a été créée pour protéger les environnements de pré-production. Elle ne doit jamais être affichée en production.
 */

import React, { useState } from 'react'
import { setSessionPassword } from '@components/passwordProtect/utils'
import { makeClasses } from '@utils/styles'
import { Helmet } from 'react-helmet'

type PasswordProtectClasses = {
	text: string
	input: string
	button: string
	buttonHover: string
}

export const passwordProtectClasses: PasswordProtectClasses = makeClasses({
	text: {
		margin: '10px'
	},
	input: {
		width: '100%',
		height: '48px',
		borderRadius: '4px',
		margin: '10px'
	},
	button: {
		width: '100%',
		height: '48px',
		background: '#222d65',
		color: '#fff',
		border: '1px solid #222d65',
		borderRadius: '4px',
		margin: '10px',
		cursor: 'pointer'
	},
	buttonHover: {
		background: '#fff',
		color: '#222d65'
	}
})

const PasswordProtect = () => {
	const [password, setPassword] = useState('')
	const [isButtonHovered, buttonHover] = useState(false)

	const onSubmit = (event) => {
		event.preventDefault()
		setSessionPassword(password)
		window.location.reload()
	}

	return (
		<>
			<Helmet>
				<meta name="robots" content="noindex" />
			</Helmet>

			<h4 className={passwordProtectClasses.text}>
				Ce site est privé et confidentiel. Veuillez rentrer le mot de passe pour
				y accéder:
			</h4>

			<form onSubmit={onSubmit} style={{ width: '320px' }}>
				<input
					name="password"
					type="password"
					value={password}
					onChange={(event) => setPassword(event.target.value)}
					className={passwordProtectClasses.input}
				/>

				<button
					type="submit"
					className={`${passwordProtectClasses.button} ${
						isButtonHovered ? passwordProtectClasses.buttonHover : 'error'
					}`}
					onMouseEnter={() => buttonHover(true)}
					onMouseLeave={() => buttonHover(false)}
				>
					Entrer
				</button>
			</form>

			<h4 className={passwordProtectClasses.text}>
				Si vous cherchez le site MonDossier de la Ville de Laval, veuillez vous
				rendre à l'endroit suivant:&nbsp;
				<a href="https://mondossier.laval.ca/">https://mondossier.laval.ca/</a>
			</h4>
		</>
	)
}

export default PasswordProtect
