import { AppStateContext } from '@components/layouts/DynamicLayout'
import { mondossierOg } from '@images/icons'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

const SEO: React.FC = () => {
	const { pageData } = useContext(AppStateContext)

	return (
		<Helmet>
			<meta property="og:title" content={pageData?.assets?.seo_title} />
			<meta
				property="og:description"
				content={pageData?.assets?.seo_description}
			/>
			<meta property="og:image" content="/mondossier-OG.png" />
			<meta property="og:image:secure_url" content="/mondossier-OG.png" />
			<meta property="og:image:alt" content={pageData?.assets?.seo_alt_text} />
			<meta property="og:url" content="https://mondossier.laval.ca" />
			<meta property="og:type" content="website" />
		</Helmet>
	)
}

export default SEO
