export enum LanguageEnum {
	EN = 'en',
	FR = 'fr-CA'
}

export const LANGUAGES: string[] = ['', LanguageEnum.FR, LanguageEnum.EN]

export enum StaticApiAddressProvidersEnum {
	THINK_WHERE = 'geoThinkWhere',
	PLACES = 'geoPlaces',
	DISTRICTS = 'geoDistricts',
	LOCATION = 'geoLocation',
}

export enum StaticApiSearchTypeEnum {
	coordinate = 'coordinate',
	address = 'searchAddress',
	addresses = 'searchAddresses',
	district = 'electoralDistrict'
}

export enum URL_PARAMS {
	URL = 'url',
	FORM = 'form',
	STEP = 'step',
	REQUEST = 'request'
}

export enum Direction {
	horizontal = 'horizontal',
	vertical = 'vertical'
}

export enum HiddenEnum {
	OUI = 'Oui',
	NON = 'Non'
}
