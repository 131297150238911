import React, { FC, useContext, useRef } from 'react'
import { logoLavalIcon, userIcon, chevronDownIcon } from '@images/icons'
import { SignOutButton } from '@components/auth/SignOutButton'
import { Icon } from '@components/ui/Icon'
import LanguageSwitcher from '@components/ui/languageSwitcher'
import OutsideClickHandler from 'react-outside-click-handler'
import Link from '@components/ui/link'
import { joinClasses } from '@utils/styles'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import * as pageUtils from '@components/ui/header/__index.utils'
import SearchBoxHeader from '@components/ui/requestSearch/searchBox.header'
import { useIsAuthenticated } from '@azure/msal-react'
import CreateAccountButton from '@components/auth/CreateAccountButton'
import { getIsOpenSidebar } from '@services/store/sidebar/selector'
import { useAppSelector } from '@services/store'
import config from '@utils/config'
import { Colors } from '@utils/css-variables'
import { LoginButton } from '@components/auth/LoginButton'

const Header: FC = () => {
	const isAuthenticated: boolean = useIsAuthenticated()
	const isOpenSidebar: boolean = useAppSelector(getIsOpenSidebar)

	const { pageData, authUser } = useContext(AppStateContext)

	const refDropdown = useRef<HTMLDivElement>(null)

	const onToggleDropDown = () =>
		refDropdown.current?.classList.toggle('dropdownActive')

	const onCloseDropDown = () =>
		refDropdown.current?.classList.remove('dropdownActive')

	return (
		<header
			className={joinClasses([
				pageUtils.classes.root,
				!isOpenSidebar ? pageUtils.classes.rootIndex : ''
			])}
		>
			<div className={pageUtils.classes.leftRoot}>
				<div className={pageUtils.classes.leftRootlogo}>
					<SearchBoxHeader />
					<Link
						to={config.homepage.baseURL}
						className={pageUtils.classes.leftRootlogoLink}
					>
						<Icon
							src={logoLavalIcon}
							className={pageUtils.classes.logoLeftIcon}
						/>
					</Link>
				</div>
			</div>
			<div className={pageUtils.classes.rightRoot}>
				<LanguageSwitcher />
				{!isAuthenticated && (
					<>
						<CreateAccountButton
							className={joinClasses([
								pageUtils.classes.button,
								pageUtils.classes.btnCreateAccount
							])}
							text={formatStrapiText(pageData.assets?.btn_createAccount)}
						/>
						<LoginButton
							className={joinClasses([
								pageUtils.classes.button,
								pageUtils.classes.btnSignIn
							])}
							text={formatStrapiText(pageData.assets?.btn_login)}
						/>
					</>
				)}
				{isAuthenticated && (
					<>
						<OutsideClickHandler onOutsideClick={() => onCloseDropDown()}>
							<div
								className={pageUtils.classes.rightRootIconRoot}
								onClick={() => onToggleDropDown()}
							>
								<Link to="#" className={pageUtils.classes.rightRootLink}>
									<Icon
										src={userIcon}
										className={pageUtils.classes.rightRootUserRootIcon}
									/>
									<div className={pageUtils.classes.userWrapper}>
										{authUser?.profile?.fullName && (
											<span className={pageUtils.classes.userText}>
												{authUser?.profile?.fullName}
											</span>
										)}
										{!authUser?.profile?.fullName && (
											<span className={pageUtils.classes.userText}>
												{formatStrapiText(
													pageData?.assets?.header_dropdownUser
												)}
												{/* Sans nom ? */}
											</span>
										)}
										<Icon
											src={chevronDownIcon}
											className={pageUtils.classes.chevronDownIcon}
										/>
									</div>
								</Link>
							</div>
						</OutsideClickHandler>
						<div className={pageUtils.classes.dropdown} ref={refDropdown}>
							<Link
								to="/auth/profile"
								className={pageUtils.classes.dropdownLink}
								style={{ color: Colors.black }}
								onClick={() => {
									localStorage.setItem(config.localStorage.activeTab, '0')
								}}
							>
								{formatStrapiText(pageData?.assets?.header_dropdownInfo)}
							</Link>
							<Link
								to="/auth/profile"
								className={pageUtils.classes.dropdownLink}
								style={{ color: Colors.black }}
								onClick={() => {
									localStorage.setItem(config.localStorage.activeTab, '1')
								}}
							>
								{formatStrapiText(pageData?.assets?.header_my_preferences)}
							</Link>
							<Link
								to="/auth/profile"
								className={joinClasses([
									pageUtils.classes.dropdownLink,
									pageUtils.classes.dropdownItem
								])}
								style={{ color: Colors.black }}
								onClick={() => {
									localStorage.setItem(config.localStorage.activeTab, '2')
								}}
							>
								{formatStrapiText(
									pageData?.assets?.header_dropdown_my_property
								)}
							</Link>
							<SignOutButton
								text={formatStrapiText(pageData?.assets?.btn_logout)}
								hasIcon
								className={joinClasses([
									pageUtils.classes.dropdownLink,
									pageUtils.classes.dropdownLinkLogout
								])}
							/>
						</div>
					</>
				)}
			</div>
		</header>
	)
}

export default Header
