import { IDetailItemDTO, IInvoiceDTO } from '@services/dtos/invoice.dto'
import { IDetailItem, IInvoice } from '@services/types/invoice'
import {
	Exclude,
	Expose,
	Type,
	instanceToPlain,
	plainToInstance
} from 'class-transformer'

export class DetailItem implements IDetailItem {
	@Expose({ name: 'id' })
	id: string = ''

	@Expose({ name: 'description' })
	description: string = ''

	@Expose({ name: 'amount' })
	amount?: number

	@Expose({ name: 'tax' })
	tax?: number

	@Expose({ name: 'price_per_unit' })
	pricePerUnit?: number

	@Expose({ name: 'quantity' })
	quantity?: number

	@Expose({ name: 'product_description' })
	productDescription?: string

	@Expose({ name: 'total' })
	total?: number

	@Exclude()
	public parse(dto: IDetailItemDTO) {
		Object.assign(this, plainToInstance(DetailItem, dto))
	}

	@Exclude()
	static serialize(detailItem: IDetailItem): IDetailItemDTO {
		const dto = instanceToPlain(detailItem)

		return dto as IDetailItemDTO
	}

	constructor(dto?: IDetailItemDTO) {
		if (dto) {
			this.parse(dto)
		}
	}
}

export class Invoice implements IInvoice {
	@Expose({ name: 'id' })
	id: string = ''

	@Expose({ name: 'name' })
	name: string = ''

	@Expose({ name: 'total_amount' })
	totalAmount?: number

	@Expose({ name: 'total_tax' })
	totalTax?: number

	@Expose({ name: 'grand_total' })
	grandTotal?: number

	@Expose({ name: 'status' })
	status?: number

	@Expose({ name: 'date_du_paiement' })
	paymentDate?: string

	@Expose({ name: 'description' })
	description?: string

	@Expose({ name: 'numero_de_reference_de_la_transaction' })
	transactionReferenceNumber?: string

	@Expose({ name: 'detail_items' })
	@Type(() => DetailItem)
	detailItems: IDetailItem[] = []

	@Exclude()
	public parse(dto: IInvoiceDTO) {
		Object.assign(this, plainToInstance(Invoice, dto))
	}

	@Exclude()
	static serialize(invoice: IInvoice): IInvoiceDTO {
		const dto = instanceToPlain(invoice)

		return dto as IInvoiceDTO
	}

	constructor(dto?: IInvoiceDTO) {
		if (dto) {
			this.parse(dto)
		}
	}
}
