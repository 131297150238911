import * as React from 'react'
// import { css } from '@emotion/css'

type Props = {
	src: string
	className?: string
}

export const Icon: React.FC<Props> = ({ src, className }) => {
	const getName = (src: string): string => {
		if (!src) {
			return ''
		}

		const chunks: string[] = src.split('/')
		const fullName: string = chunks[chunks.length - 1]

		return fullName.split('.')[0]
	}

	return <img className={className} src={src} alt={'icon: ' + getName(src)} />
}
