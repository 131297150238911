import React, { FC, useContext, useState } from 'react'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { CancelRequestReason } from '@services/constants'
import { Request } from '@services/models'
import Modal from '@components/ui/modal'
import Loader from '@components/ui/loader'
import { Field, Form, Formik } from 'formik'
import { joinClasses } from '@utils/styles'
import * as pageRequestDetailsUtils from '@pages/auth/requests/__[id].utils'
import * as pageUtils from '@components/ui/cancelRequestModal/__index.utils'
import Text from '@components/ui/text'

type Props = {
	isOpen: boolean
	request?: Request
	title?: string
	description?: string
	isDefaultCancelReason?: boolean
	isHomePage?: boolean
	onCancel: () => void
}

const CancelRequestModal: FC<Props> = ({
	isOpen,
	request,
	onCancel,
	title,
	description,
	isHomePage,
	isDefaultCancelReason
}) => {
	const { pageData } = useContext(AppStateContext)
	const [requestCanceling, setRequestCanceling] = useState<boolean>(false)

	const cancelRequest = async (request: Request, closeReason) => {
		if (
			closeReason != CancelRequestReason.OTHER &&
			closeReason != CancelRequestReason.PROBLEM_SOLVED &&
			closeReason != CancelRequestReason.REQUEST_NOT_VALID
		) {
			return
		}

		try {
			setRequestCanceling(true)
			request.cancelReason = parseInt(closeReason)

			await request.saveAsync(true)
		} catch (error: any) {
			console.error(error?.message)
		} finally {
			setTimeout(() => {
				setRequestCanceling(false)
				onCancel()
				window.location.reload()
			}, 200)
		}
	}

	const triggerCancelButton = () => {
		document.getElementById('cancel_request_validate')?.click()
	}

	return (
		<Modal
			title={
				title
					? `${title}`
					: `${pageData?.assets?.myRequest_cancel_request} ${request?.code}`
			}
			primaryButtonText={pageData?.assets?.label_confirm}
			cancelButtonText={pageData?.assets?.cancel}
			showExitBtn
			isOpen={isOpen}
			onCancelBtn={onCancel}
			onPrimaryButton={() => triggerCancelButton()}
			onSetIsOpen={(value) => {}}
		>
			{requestCanceling ? (
				<Loader text={pageData?.assets?.loading} />
			) : (
				<div className={pageRequestDetailsUtils.classes.modalContent}>
					<Formik
						initialValues={{
							closeReason: isDefaultCancelReason
								? CancelRequestReason.REQUEST_NOT_VALID
								: 0
						}}
						validationSchema={pageUtils.SignupSchema}
						onSubmit={(values) => {
							cancelRequest(request!, values.closeReason)
						}}
						onReset={(e) => {}}
					>
						{({ errors, touched }) => (
							<Form>
								<Field name="closeReason" as="select">
									{({ field }) => (
										<fieldset
											style={{ display: isDefaultCancelReason ? 'none' : '' }}
										>
											<label
												className={
													errors.closeReason && touched.closeReason
														? pageRequestDetailsUtils.classes.textError
														: ''
												}
											>
												{
													pageData?.assets
														?.myRequest_why_do_you_want_to_cancel_the_request
												}
												<span
													className={pageRequestDetailsUtils.classes.required}
												>
													*
												</span>

												{errors.closeReason && touched.closeReason ? (
													<>
														<br />
														<span
															className={joinClasses([
																pageRequestDetailsUtils.classes.textError,
																pageRequestDetailsUtils.classes.textDescription
															])}
														>
															{pageData.assets.required_text}
														</span>
													</>
												) : null}
											</label>
											<select
												style={{ width: '100%' }}
												className={
													errors.closeReason && touched.closeReason
														? pageRequestDetailsUtils.classes.inputError
														: ''
												}
												{...field}
											>
												<option></option>
												<option value={CancelRequestReason.REQUEST_NOT_VALID}>
													{
														pageData?.assets
															?.myRequest_my_application_is_no_longer_valid
													}
												</option>
												<option value={CancelRequestReason.PROBLEM_SOLVED}>
													{
														pageData?.assets
															?.myRequest_the_problem_solved_itself
													}
												</option>
												<option value={CancelRequestReason.OTHER}>
													{
														pageData?.assets
															?.myRequest_other_i_do_not_wish_to_specify
													}
												</option>
											</select>
										</fieldset>
									)}
								</Field>
								{description ? (
									<div
										style={{
											paddingRight: '25px',
											marginTop: isHomePage == true ? '0px' : '-35px'
										}}
									>
										<Text content={description} />
									</div>
								) : (
									''
								)}
								<button
									style={{ display: 'none' }}
									id="cancel_request_validate"
									type="submit"
								>
									send
								</button>
							</Form>
						)}
					</Formik>
				</div>
			)}
		</Modal>
	)
}

export default CancelRequestModal
