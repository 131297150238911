import config from '@utils/config'
import {
	detect,
	BrowserInfo,
	SearchBotDeviceInfo,
	BotInfo,
	NodeInfo,
	ReactNativeInfo
} from 'detect-browser'
import { useEffect, useState } from 'react'

const Browser = () => {
	const [browser, setBrowser] = useState<string>('')
	const [isMobile, setIsMobile] = useState<boolean>(false)

	const mobileList: string[] = config.browser.mobileOSList

	useEffect(() => {
		const browserInfo:
			| BrowserInfo
			| SearchBotDeviceInfo
			| BotInfo
			| NodeInfo
			| ReactNativeInfo
			| null = detect()

		if (browserInfo && browserInfo.name) {
			setBrowser(`portal-${browserInfo.name.toLowerCase()}`)

			if (mobileList.includes(browserInfo.os?.toLowerCase() || '')) {
				setIsMobile(true)
			}
		}
	})

	return {
		browser,
		isMobile
	}
}

export default Browser
