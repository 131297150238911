import { combineReducers } from 'redux'
import request from '@services/store/request'
import articles from '@services/store/articles'
import sidebar from '@services/store/sidebar'
import requestList from '@services/store/requestList'

const reducer = combineReducers({
	request,
	articles,
	sidebar,
	requestList
})

export default reducer
