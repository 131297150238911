import React, { FC, ReactElement, useEffect } from 'react'
import { joinClasses, makeClasses, makeStyles } from '@utils/styles'
import { Colors, Constants, Indexes } from '@utils/css-variables'
import { useSpring, animated } from 'react-spring'
import Text from '@components/ui/text'
import { Global } from '@emotion/react'
import { Icon } from '@components/ui/Icon'
import { closeIcon, infoIcon, warningIcon } from '@images/icons'
import Breakpoints from '@utils/breakpoints'
import config from '@utils/config'
import { formatStrapiText } from '@utils/methods'
import { warningYellowIcon } from '@images/icons'

type Classes = {
	background: string
	body: string
	warningBody: string
	container: string
	warningContainer: string
	infoContainer: string
	content: string
	infoContent: string
	footer: string
	footerBtnAlone: string
	header: string
	closeBtn: string
	closeImg: string
	btn: string
	headerTitle: string
	primaryBtn: string
	warningIcon: string
	reverseDirection: string
	btnDisabled: string
	loader: string
	submitBtn: string
}

type Styles = {
	root: string
}

const styles: Styles = makeStyles({
	root: `
		body {
			overflow: hidden
		}
	`
})

const classes: Classes = makeClasses({
	background: {
		display: 'flex',
		justifyContent: 'flex',
		alignItems: 'flex',
		paddingLeft: '0px',
		overflowX: 'hidden',
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		overflowY: 'scroll',
		padding: '0px 10px',
		background: Colors.blackTransparent50,
		'z-index': Indexes.index5,
		outline: 0
	},
	body: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '800px',
		'z-index': Indexes.index11,
		[Breakpoints.minWidth('lg')]: {
			left: 0,
			padding: '10px'
		},
		[Breakpoints.minWidth('sm')]: {
			minHeight: 'calc(100% - 3.5rem)',
			margin: '1.75rem auto'
		}
	},
	warningBody: {
		left: 'calc(50% - 250px)',
		maxWidth: '500px',
		[Breakpoints.maxWidth('sm')]: {
			left: 0
		}
	},
	container: {
		width: '100%',
		maxWidth: '800px',
		background: Colors.white,
		'z-index': Indexes.index11,
		border: `1px solid ${Colors.blackTransparent20}`,
		backgroundClip: 'padding-box',
		borderRadius: '0.3rem',
		display: 'flex',
		flexDirection: 'column',
		maxHeight: '99%',
		overflowY: 'auto'
	},
	warningContainer: {
		maxWidth: '500px'
	},
	infoContainer: {
		maxWidth: '400px'
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		padding: '30px 30px 0'
	},
	headerTitle: {
		fontSize: '22px',
		lineHeight: 1.2,
		fontWeight: 700,
		color: Colors.primary,
		'& p': {
			marginTop: 0
		}
	},
	closeImg: {
		display: 'inline-flex',
		width: '100%',
		maxWidth: '25px',
		height: '25px',
		marginLeft: '10px',
		cursor: 'pointer',
		img: {
			width: '100%',
			height: '100%'
		},
		alignSelf: 'flex-start'
	},
	content: {
		padding: '30px',
		flexGrow: 1
	},
	infoContent: {
		textAlign: 'center'
	},
	footer: {
		display: 'flex',
		alignItems: 'center',
		padding: '0 30px 30px',
		button: {
			marginTop: '15px',
			'&:first-child': {
				marginRight: '10px'
			}
		}
	},
	footerBtnAlone: {
		justifyContent: 'center',
		button: {
			'&:first-child': {
				marginRight: '0'
			}
		}
	},
	btn: {
		lineHeight: '1em',
		background: Colors.secondary,
		color: Colors.white,
		textDecoration: 'none',
		padding: '10px 20px',
		borderRadius: '23px',
		border: `1px solid ${Colors.secondary}`,
		fontWeight: 500,
		fontSize: '16px',
		margin: '0 5px',
		display: 'block',
		cursor: 'pointer',
		fontFamily: Constants.fontStack,
		'&:disabled': {
			cursor: 'no-drop',
			background: `${Colors.grey} !important`,
			border: 'none'
		},
		p: {
			margin: 0
		}
	},
	closeBtn: {
		background: Colors.white,
		color: Colors.secondary,
		borderColor: Colors.secondary,
		'&:hover': {
			color: Colors.white,
			background: Colors.darkBlue2,
			borderColor: Colors.darkBlue2
		}
	},
	primaryBtn: {
		background: Colors.secondary,
		color: Colors.white,
		borderColor: Colors.secondary,
		'&:hover': {
			background: Colors.darkBlue2
		}
	},
	warningIcon: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		img: {
			width: '50px',
			height: '50px'
		}
	},
	reverseDirection: {
		flexDirection: 'row-reverse'
	},
	loader: {
		position: 'absolute' as 'absolute',
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '20px',
		height: '20px',
		animation: 'spin 2s linear infinite'
	},
	btnDisabled: {
		opacity: 0.8,
		pointerEvents: 'none',
		cursor: 'not-allowed',
		'&:hover': {
			background: Colors.secondary,
			cursor: 'not-allowed'
		}
	},
	submitBtn: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		maxWidth: '110px',
		height: '40px',
		padding: '15px 20px',
		fontSize: '16px',
		border: '0',
		'&:hover': {
			cursor: 'pointer'
		}
	}
})

type ModalType = {
	default: string
	warning: string
	info: string
}

export type ModalTypeProp = 'default' | 'warning' | 'info'

const modalType: ModalType = {
	default: 'default',
	warning: 'warning',
	info: 'info'
}

type Prop = {
	isOpen: boolean
	disabled?: boolean
	type?: ModalTypeProp
	title?: ReactElement | string
	children?: ReactElement
	primaryButtonText?: string
	cancelButtonText?: string
	showExitBtn?: boolean
	openUsingSetTimeout?: boolean
	extraStyle?: string
	extraBodyStyle?: string
	shouldNotClose?: boolean
	alignBtn?: string
	swapButtonPositions?: boolean
	iconColor?: string
	isPrimaryBtnLoading?: boolean
	onSetIsOpen: (value: boolean) => void
	onCancelBtn?: () => void
	onPrimaryButton?: () => void
	onPrimaryButtonAsync?: () => void
	onCloseModal?: () => void
}

const Modal: FC<Prop> = ({
	isOpen,
	disabled,
	title,
	type = modalType.default,
	children,
	primaryButtonText,
	cancelButtonText,
	showExitBtn,
	openUsingSetTimeout,
	extraStyle,
	extraBodyStyle,
	shouldNotClose,
	alignBtn,
	onCancelBtn,
	onPrimaryButton,
	isPrimaryBtnLoading,
	onPrimaryButtonAsync,
	onSetIsOpen,
	onCloseModal,
	swapButtonPositions,
	iconColor
}) => {
	const isWarningModal: boolean = type === modalType.warning
	const isInfoModal: boolean = type === modalType.info

	useEffect(() => {
		document.addEventListener('keydown', (e) => {
			if (e.key == config.event.escapeKey) {
				onClickCancelBtn()
			}
		})
	}, [])

	const onClickPrimaryBtn = async () => {
		if (onPrimaryButton) {
			onPrimaryButton()
		}

		if (onPrimaryButtonAsync) {
			await onPrimaryButtonAsync()
		}
	}

	const onClickCancelBtn = () => {
		onSetIsOpen(false)

		if (!shouldNotClose && onCancelBtn) {
			onCancelBtn()
		}
	}

	const handleOusideClick = (e) => {
		e.stopPropagation()
		if (
			e.target.className.includes(classes.background) ||
			e.target.className.includes(classes.body)
		) {
			onClickCancelBtn()
		}
	}
	const animation = useSpring({
		config: {
			duration: 350
		},
		opacity: isOpen ? 1 : 0,
		transform: isOpen ? `translateY(0%)` : `translateY(-10%)`,
		transition: 'opacity 100ms ease'
	})

	const onCloseButtonClick = () => {
		if (onCloseModal) return onCloseModal()
		return onClickCancelBtn()
	}

	const sectionHeader = () => {
		if (isWarningModal || isInfoModal) {
			return (
				<div className={classes.header}>
					<div className={classes.warningIcon}>
						<Icon
							src={
								isInfoModal
									? infoIcon
									: iconColor
									? warningYellowIcon
									: warningIcon
							}
						/>
					</div>
				</div>
			)
		}

		return (
			<div className={classes.header}>
				<span className={classes.headerTitle}>
					{title && typeof title === 'string' && <Text content={title} />}
					{title && typeof title !== 'string' && <>{title}</>}
				</span>
				{showExitBtn && (
					<span className={classes.closeImg} onClick={() => onClickCancelBtn()}>
						<Icon src={closeIcon} />
					</span>
				)}
			</div>
		)
	}

	const sectionFooter = () => {
		const isBtnAlone: boolean =
			(primaryButtonText !== '' && !cancelButtonText) ||
			(!primaryButtonText && cancelButtonText !== '')

		return (
			<div
				className={joinClasses([
					classes.footer,
					swapButtonPositions ? classes.reverseDirection : '',
					isBtnAlone ? classes.footerBtnAlone : ''
				])}
				style={alignBtn ? { justifyContent: `${alignBtn}` } : {}}
			>
				{primaryButtonText && (
					<button
						disabled={disabled}
						className={joinClasses([
							classes.btn,
							swapButtonPositions ? classes.closeBtn : classes.primaryBtn,
							isPrimaryBtnLoading
								? joinClasses([classes.btnDisabled, classes.submitBtn])
								: ''
						])}
						onClick={() => onClickPrimaryBtn()}
						type="button"
						style={{ marginLeft: '0px' }}
					>
						{isPrimaryBtnLoading ? (
							<div className={`${classes.loader} spin`} />
						) : (
							<Text content={primaryButtonText} />
						)}
					</button>
				)}
				{cancelButtonText && (
					<button
						disabled={shouldNotClose}
						className={joinClasses([
							classes.btn,
							isBtnAlone
								? classes.primaryBtn
								: swapButtonPositions
								? classes.primaryBtn
								: classes.closeBtn
						])}
						onClick={() => onCloseButtonClick()}
						type="button"
					>
						<Text content={cancelButtonText} />
					</button>
				)}
			</div>
		)
	}

	useEffect(() => {
		if (openUsingSetTimeout) {
			setTimeout(() => {
				onSetIsOpen(true)
			}, 100)
		}
	}, [])

	return (
		<>
			{isOpen && (
				<>
					<Global styles={styles.root} />
					<div className={classes.background} onClick={handleOusideClick}>
						<animated.div
							style={animation}
							className={joinClasses([
								classes.body,
								isWarningModal ? classes.warningBody : '',
								extraBodyStyle || ''
							])}
						>
							<div
								className={joinClasses([
									classes.container,
									isWarningModal ? classes.warningContainer : '',
									isInfoModal ? classes.infoContainer : '',
									extraStyle || ''
								])}
							>
								{(title || showExitBtn || isWarningModal || isInfoModal) &&
									sectionHeader()}
								<div
									style={{ display: !!children ? 'block' : 'none' }}
									className={joinClasses([
										classes.content,
										isInfoModal ? classes.infoContent : ''
									])}
								>
									{children}
								</div>

								{(primaryButtonText || cancelButtonText) && sectionFooter()}
							</div>
						</animated.div>
					</div>
				</>
			)}
		</>
	)
}

export default Modal
