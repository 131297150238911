import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react'
import { navigate } from '@components/ui/link'
import { useAppDispatch, useAppSelector } from '@services/store'
import { getIsSearchHeaderOpen } from '@services/store/articles/selector'
import { doOpenSearchHeader } from '@services/store/articles'
import Collapse from '@components/ui/collapse'
import * as pageUtils from '@components/ui/requestSearch/__requestSearch.utils'
import * as pageClasses from '@pages/auth/requests/create/__index.utils'
import Text from '@components/ui/text'
import { formatStrapiText } from '@utils/methods'
import { joinClasses } from '@utils/styles'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import config from '@utils/config'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	LanguageEnum
} from '@services/constants'
import { authRedirectPage, loginRequest } from '@utils/authConfig'
import { Request as MyRequest } from '@services/models'
import CancelRequestModal from '@components/ui/cancelRequestModal'
import { getRequestList } from '@services/store/requestList/selector'
import { setRequestListStore } from '@services/store/requestList'

const RequestListCard: FC<pageUtils.RequestListCardProps> = ({
	id,
	request,
	index,
	requestThemes,
	isHomePage,
	hasParentGroup = true,
	isCardOpen = false
}) => {
	const { instance } = useMsal()
	const { pageData, language, authUser } =
		useContext<AppContextProps>(AppStateContext)
	const dispatch = useAppDispatch()
	const isSearchHeaderOpen: boolean = useAppSelector(getIsSearchHeaderOpen)
	const isUserConnected: boolean = useIsAuthenticated()
	const [myRequests, setMyRequests] = useState<MyRequest[]>([])
	const [loadingMyRequests, setLoadingMyRequests] = useState<boolean>(false)
	const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
	const requestListStore = useAppSelector(getRequestList)

	const onNavigateToRequest = (requestId: string) => (e) => {
		e.preventDefault()

		if (isSearchHeaderOpen) {
			dispatch(doOpenSearchHeader(false))
		}

		const link: string = `${config.request.create.baseURL}/${requestId}`

		if (!isUserConnected) {
			const authRedirect: string = config.localStorage.authRedirect
			// this will send the right language to display on Azure AD B2C
			const extraQueryParameters = {
				ui_locales: `${language || LanguageEnum.FR}`
			}

			localStorage.setItem(authRedirect, link)

			instance.loginRedirect({
				...loginRequest,
				redirectUri: authRedirectPage,
				extraQueryParameters
			})

			return
		}

		navigate(link)
	}

	const getSubThemes = (subTheme: string, key: number) => {
		let title = ''
		requestThemes.forEach((theme) => {
			let subThm = theme.requestSubThemes?.find(
				(subThm) => subThm.name == subTheme
			)

			if (subThm) {
				title = subThm.title
			}
		})

		return (
			<span key={`${subTheme}-${key}`} className={pageClasses.classes.category}>
				{title}
			</span>
		)
	}

	const getRequestGroup = (requestId: string, attribut: string) => {
		let requestGroup = ''
		for (const group of requestThemes) {
			const req = group?.requests?.find((req) => req?.requestId === requestId)
			if (req) {
				requestGroup = group[attribut]
			}
		}
		if (!requestGroup) {
			requestGroup = requestThemes?.at(2)[attribut]
		}
		return requestGroup
	}

	const fetchRequests = async () => {
		if (
			request?.requestId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS &&
			authUser &&
			!loadingMyRequests
		) {
			if (requestListStore.fetched) {
				setMyRequests(requestListStore.requests)
			} else {
				try {
					setLoadingMyRequests(true)

					const requestsList = await authUser.fetchRequests(false)

					setMyRequests(requestsList)
					dispatch(
						setRequestListStore({
							fetching: false,
							fetched: true,
							requests: requestsList
						})
					)
				} catch (error: any) {
					console.error(error?.message)
				} finally {
					setLoadingMyRequests(false)
					// setTimeout(() => setLoadingMyRequests(false), 200)
				}
			}
		}
	}

	useEffect(() => {
		fetchRequests().catch(console.error)
	}, [authUser, request])

	// useEffect(() => {
	// 	console.log('onMount')
	// 	fetchRequests().catch(console.error)
	// }, [])

	const getPublicAuctionsRequestIfExists = () => {
		const publicAuction = myRequests.filter(
			(request) =>
				request.typeId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS &&
				request.cancelReason == null
		)

		if (publicAuction.length > 0) {
			return publicAuction[0]
		}

		return undefined
	}

	if (request?.requestId == IDENTIFIERS_OF_TN_REQUEST_TYPE.PUBLIC_AUCTIONS) {
		let publicAuction = getPublicAuctionsRequestIfExists()

		return (
			<div key={request?.requestId} className={pageClasses.classes.accordion}>
				<Collapse
					id={id}
					buttonText={
						<>
							<div>
								<span className={pageClasses.classes.btnTitle}>
									{request.title}
								</span>
								<div className={pageClasses.classes.tag}>
									{request.subThemes.map(getSubThemes)}
								</div>
							</div>
						</>
					}
				>
					<div
						className={joinClasses([
							pageClasses.classes.textContent,
							pageClasses.classes.panel
						])}
					>
						<Text content={request.description} />

						{loadingMyRequests ? (
							<button
								onClick={onNavigateToRequest(request?.requestId)}
								className={pageClasses.classes.btn}
							>
								<div className={`${pageClasses.classes.loader} spin`} />
							</button>
						) : publicAuction && publicAuction.cancelReason == null ? (
							<>
								<div className={pageClasses.classes.requestInfoSection}>
									<>
										{formatStrapiText(
											pageData?.assets.request_form_registered_since_text
										)}{' '}
										{publicAuction?.dateAdd?.localDateString}
									</>
									<br />
									<>
										{formatStrapiText(
											pageData?.assets
												.request_form_confirmation_request_number_text
										)}{' '}
										: <span>{publicAuction?.code}</span>
									</>
								</div>
								<button
									onClick={() => {
										setIsOpenModal(true)
									}}
									className={pageClasses.classes.btn}
								>
									{formatStrapiText(
										pageData?.assets.request_form_cancel_my_registration
									)}
								</button>
							</>
						) : (
							<button
								onClick={onNavigateToRequest(request?.requestId)}
								className={pageClasses.classes.btn}
							>
								{formatStrapiText(
									pageData?.assets[
										pageClasses.buttonTextDic[
											`${getRequestGroup(request?.requestId, 'name')}`
										]
									]
								)}
							</button>
						)}
					</div>
				</Collapse>
				<CancelRequestModal
					onCancel={() => setIsOpenModal(false)}
					isOpen={isOpenModal}
					request={publicAuction}
					title={pageData?.assets?.request_form_cancel_registration}
					description={
						pageData?.assets.request_form_publicAuctions_cancel_registration
					}
					isDefaultCancelReason
					isHomePage={isHomePage}
				/>
			</div>
		)
	}

	return (
		<div key={request?.requestId} className={pageClasses.classes.accordion}>
			<Collapse
				key={request?.requestId}
				id={index}
				buttonText={
					<>
						<div>
							<span className={pageClasses.classes.btnTitle}>
								{request.title}
							</span>
							<div className={pageClasses.classes.tag}>
								{hasParentGroup && (
									<span
										key={`requestGroup-${index}`}
										className={pageClasses.classes.category}
									>
										{formatStrapiText(
											getRequestGroup(request?.requestId, 'title')
										)}
									</span>
								)}
								{request.subThemes?.map((subThm, key) =>
									getSubThemes(subThm, key)
								)}
							</div>
						</div>
					</>
				}
				isOpen={isCardOpen}
			>
				<div
					className={joinClasses([
						pageClasses.classes.textContent,
						pageClasses.classes.panel
					])}
				>
					<Text content={request.description} />
					<button
						onClick={onNavigateToRequest(request?.requestId)}
						className={pageClasses.classes.btn}
					>
						{formatStrapiText(
							pageData?.assets[
								pageClasses.buttonTextDic[
									`${getRequestGroup(request?.requestId, 'name')}`
								]
							]
						)}
					</button>
				</div>
			</Collapse>
		</div>
	)
}

export default RequestListCard
