import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'

export type ClassType = {
	paymentContainer: string
}

export const classes: ClassType = makeClasses({
	paymentContainer: {
		margin: '40px 100px',
		[Breakpoints.maxWidth('sidebar')]: {
			margin: '40px 60px'
		},
		[Breakpoints.maxWidth('xxl')]: {
			margin: '40px 40px'
		},
		[Breakpoints.maxWidth('xl')]: {
			margin: '40px 30px'
		},
		[Breakpoints.maxWidth('laptop')]: {
			margin: '20px'
		},
		[Breakpoints.maxWidth('sm')]: {
			marginTop: '0px'
		}
	}
})
