import React, { FC, Fragment, useContext, useRef, useState } from 'react'

import logoLaval from '@images/logo-laval.svg'
import Link, { navigate } from '@components/ui/link'
import OutsideClickHandler from 'react-outside-click-handler'
import { Icon } from '@components/ui/Icon'
import { AppStateContext } from '@components/layouts/DynamicLayout'
import { formatStrapiText } from '@utils/methods'
import { chevronDownIcon, externalLink } from '@images/icons'
import { INavLink } from '.'
import { joinClasses } from '@utils/styles'
import * as pageUtils from '@components/ui/sidebar/__mobile.sidebar.utils'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useAppDispatch } from '@services/store'
import { doOpenSidebar } from '@services/store/sidebar'
import config from '@utils/config'
import { authRedirectPage, loginRequest } from '@utils/authConfig'
import {
	IDENTIFIERS_OF_TN_REQUEST_TYPE,
	LanguageEnum
} from '@services/constants'

const MobileSidebar: FC<pageUtils.ISidebar> = ({ navItems, menuItems }) => {
	const dispatch = useAppDispatch()
	const isAuthenticated: boolean = useIsAuthenticated()
	const isUserConnected: boolean = useIsAuthenticated()
	const { instance } = useMsal()

	const subNavRef = useRef<HTMLLIElement>(null)
	const navRootRef = useRef<HTMLDivElement>(null)

	const { pageData, language } = useContext(AppStateContext)

	const [showMenu, setShowMenu] = useState(false)

	const onToggleMenu = () => {
		doToggleSidebarMenu(!showMenu)
	}

	const doToggleSidebarMenu = (menuAction: boolean) => {
		if (!menuAction) {
			setShowMenu(false)
			navRootRef.current?.classList.remove('fade')
			setTimeout(() => {
				dispatch(doOpenSidebar(false))
			}, 400)
			return
		}
		dispatch(doOpenSidebar(true))

		setTimeout(() => {
			setShowMenu(true)
			navRootRef.current?.classList.add('fade')
		}, 10)
	}

	const onCloseMenu = () => {
		doToggleSidebarMenu(false)
	}

	const onToggleSubMenu = () => subNavRef.current?.classList.toggle('no-hover')
	const onCloseSubMenu = () => subNavRef.current?.classList.add('no-hover')

	const onNavigateToLink =
		(link: string, isTargetBlank: boolean = false) =>
		(event) => {
			doToggleSidebarMenu(false)

			if (isTargetBlank) {
				return
			}

			event.preventDefault()

			navigate(link)
		}

	const onNavigateToRequest = (link: string) => {
		if (
			!isUserConnected &&
			link.includes(IDENTIFIERS_OF_TN_REQUEST_TYPE.CLAIM_NOTICE)
		) {
			const authRedirect: string = config.localStorage.authRedirect
			// this will send the right language to display on Azure AD B2C
			const extraQueryParameters = {
				ui_locales: `${language || LanguageEnum.FR}`
			}

			localStorage.setItem(authRedirect, link)

			instance.loginRedirect({
				...loginRequest,
				redirectUri: authRedirectPage,
				extraQueryParameters
			})

			return
		}

		navigate(link)
	}

	return (
		<>
			<div className={pageUtils.classes.root}>
				<ul className={pageUtils.classes.listRoot}>
					{navItems.map((item: INavLink, index: number) =>
						item.text !== 'Menu' ? (
							<li
								key={index}
								className={joinClasses([
									pageUtils.classes.listItem,
									!isAuthenticated ? pageUtils.classes.noConnectedListItem : ''
								])}
							>
								<Link
									to={item.link}
									onClick={onNavigateToLink(item.link)}
									className={`${pageUtils.classes.listItemLink} ${
										item.active
											? 'list-item-active'
											: pageUtils.classes.listItemNotActive
									}`}
								>
									<Icon
										className={pageUtils.classes.listItemIcon}
										src={item.icon}
									/>
									<span className={pageUtils.classes.listItemLabel}>
										{item.text}
									</span>
								</Link>
							</li>
						) : (
							<li
								key={index}
								className={joinClasses([
									pageUtils.classes.listItem,
									pageUtils.classes.menuButton,
									!isAuthenticated ? pageUtils.classes.noConnectedListItem : ''
								])}
							>
								<OutsideClickHandler
									onOutsideClick={() => onCloseMenu()}
									display="flex"
								>
									<Link
										onClick={onToggleMenu}
										to="#"
										className={`${pageUtils.classes.listItemLink} ${
											showMenu
												? 'list-item-active'
												: pageUtils.classes.listItemNotActive
										}`}
									>
										<Icon
											className={pageUtils.classes.listItemIcon}
											src={item.icon}
										/>
										<span className={pageUtils.classes.listItemLabel}>
											{item.text}
										</span>
									</Link>
									<div ref={navRootRef} className={pageUtils.menuClasses.root}>
										<div className={pageUtils.menuClasses.logoRoot}>
											<Link
												to={config.homepage.baseURL}
												onClick={onNavigateToLink(config.homepage.baseURL)}
												className={pageUtils.menuClasses.logoLink}
											>
												<img
													className={pageUtils.menuClasses.logo}
													src={logoLaval}
													alt="logo"
												/>
											</Link>
										</div>
										<div className={pageUtils.menuClasses.navWrap}>
											<ul className={pageUtils.menuClasses.navList}>
												{menuItems.map(
													(
														{ icon, link, text, items, active }: INavLink,
														index: number
													) => (
														<Fragment key={index}>
															{items ? (
																<li
																	ref={subNavRef}
																	key={index}
																	className={joinClasses([
																		pageUtils.menuClasses.navItem,
																		pageUtils.menuClasses.navLinkHasChild,
																		'no-hover'
																	])}
																	onClick={onCloseMenu}
																>
																	<OutsideClickHandler
																		onOutsideClick={() => onCloseSubMenu()}
																	>
																		<button
																			onClick={onToggleSubMenu}
																			className={joinClasses([
																				pageUtils.menuClasses.navLink,
																				pageUtils.menuClasses.navLinkHasChild
																			])}
																		>
																			<span
																				className={
																					pageUtils.menuClasses.chevron
																				}
																			>
																				<Icon
																					className={`${pageUtils.menuClasses.chevronImg} chevron_up`}
																					src={chevronDownIcon}
																				/>
																			</span>
																			<span
																				className={
																					pageUtils.menuClasses.navLabel
																				}
																			>
																				<Icon
																					src={icon}
																					className={
																						pageUtils.menuClasses.navImg
																					}
																				/>
																				{text}
																			</span>
																		</button>
																		<div
																			className={`${pageUtils.menuClasses.subNav} sub_nav`}
																		>
																			<ul
																				className={
																					pageUtils.menuClasses.subNavList
																				}
																			>
																				{items.map(
																					({ link, text }, index: number) => (
																						<li
																							key={index}
																							className={
																								pageUtils.menuClasses.subNavItem
																							}
																						>
																							<Link
																								to={link}
																								onClick={onNavigateToLink(link)}
																								className={`${pageUtils.menuClasses.subNavLink} sub_nav_link`}
																							>
																								{text}
																							</Link>
																						</li>
																					)
																				)}
																			</ul>
																		</div>
																	</OutsideClickHandler>
																</li>
															) : (
																<li
																	key={index}
																	className={joinClasses([
																		pageUtils.menuClasses.navItem,
																		active
																			? pageUtils.menuClasses.navActive
																			: ''
																	])}
																	onClick={onCloseMenu}
																>
																	<Link
																		to={link}
																		onClick={() => {
																			onNavigateToLink(link)
																			onNavigateToRequest(link)
																		}}
																		className={joinClasses([
																			pageUtils.menuClasses.navLink,
																			'nav-link'
																		])}
																	>
																		<span
																			className={pageUtils.menuClasses.navLabel}
																		>
																			<Icon
																				src={icon}
																				className={pageUtils.menuClasses.navImg}
																			/>
																			{text}
																		</span>
																	</Link>
																</li>
															)}
														</Fragment>
													)
												)}
											</ul>
											<div className={pageUtils.menuClasses.navInfoWrap}>
												<Link
													className={pageUtils.menuClasses.navInfoLink}
													to={'/faq'}
													onClick={onNavigateToLink('/faq')}
												>
													{formatStrapiText(pageData?.assets?.sidebar_faq)}
												</Link>
												<Link
													className={pageUtils.menuClasses.navInfoLink}
													to={'/online-services'}
													onClick={onNavigateToLink('/online-services')}
												>
													{formatStrapiText(
														pageData?.assets?.sidebar_online_services
													)}
												</Link>
												<Link
													className={pageUtils.menuClasses.navInfoLink}
													to={pageData.assets?.link_contact_us}
													onClick={onNavigateToLink(
														pageData.assets?.link_contact_us,
														true
													)}
													target={'_blank'}
												>
													{formatStrapiText(pageData?.assets?.sidebar_join_us)}
													<Icon
														src={externalLink}
														className={pageUtils.menuClasses.navImg}
													/>
												</Link>
											</div>
										</div>
									</div>
								</OutsideClickHandler>
							</li>
						)
					)}
				</ul>
			</div>
		</>
	)
}

export default MobileSidebar
