import localStorage from '@utils/config/localStorage'
import transition from '@utils/config/transition'
import geolocation from '@utils/config/geolocation'
import request from '@utils/config/request'
import sidebar from '@utils/config/sidebar'
import event from '@utils/config/event'
import homepage from '@utils/config/homepage'
import auth from '@utils/config/auth'
import articles from '@utils/config/articles'
import browser from '@utils/config/browser'

export default {
	localStorage,
	transition,
	geolocation,
	request,
	sidebar,
	event,
	homepage,
	auth,
	articles,
	browser
}
