import React, { FC, MouseEvent, useContext, useEffect, useState } from 'react'
import { Icon } from '@components/ui/Icon'
import * as pageUtils from '@components/ui/requestSearch/__requestSearch.utils'
import { closeWhiteIconV2, magnifierGreyIcon } from '@images/icons'
import { formatStrapiText } from '@utils/methods'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { Request } from 'typings/shared'
import { useAppDispatch, useAppSelector } from '@services/store'
import {
	getIsPageArticles,
	getIsSearchHeaderOpen,
	getSearchText
} from '@services/store/articles/selector'
import { doOpenSearchHeader } from '@services/store/articles'
import { joinClasses } from '@utils/styles'
import { Collapse as ReactCollapse } from 'react-collapse'
import { navigate } from '@components/ui/link'
import Loader from '../loader'
import SearchRequests from '@components/ui/requestSearch/search'
import RequestListCard from '@components/ui/requestSearch/RequestListCard'
import Text from '@components/ui/text'

const SearchBoxHeader: FC = () => {
	const { pageData } = useContext<AppContextProps>(AppStateContext)

	const dispatch = useAppDispatch()

	const [searchText, setSearchText] = useState<string>('')
	const isSearchBoxOpen: boolean = useAppSelector(getIsSearchHeaderOpen)
	const isPageArticles: boolean = useAppSelector(getIsPageArticles)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isInAction, setIsInAction] = useState<boolean>(false)

	const [requests, setRequests] = useState<Request[]>([])

	const onToggleSearchBox = (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()
		if (isPageArticles) {
			return false
		}

		// if (isPageRequests) {
		// 	return false
		// }

		if (isInAction) {
			return false
		}

		const searchInput = document.getElementById(
			'homePageSearchInput'
		) as HTMLInputElement
		if (searchInput) {
			searchInput.value = ''
		}

		setIsInAction(true)

		dispatch(doOpenSearchHeader(!isSearchBoxOpen))
	}

	const onNavigateToRequests = () => {
		if (searchText.length > 2) {
			dispatch(doOpenSearchHeader(false))

			navigate('/results-requests', { state: { searchText } })
		}
	}

	useEffect(() => {
		setIsInAction(false)
	}, [isSearchBoxOpen])

	return (
		<div className={pageUtils.searchBoxClasses.root}>
			<button
				className={joinClasses([
					pageUtils.searchBoxClasses.searchBoxBtn,
					isSearchBoxOpen ? pageUtils.searchBoxClasses.searchBoxBtnOpen : ''
				])}
				onClick={onToggleSearchBox}
			>
				{!isSearchBoxOpen && (
					<>
						<Icon src={magnifierGreyIcon} />
						<span>
							{formatStrapiText(pageData?.assets?.header_inputSearch)}
						</span>
					</>
				)}
				{isSearchBoxOpen && <Icon src={closeWhiteIconV2} />}
			</button>
			<div
				className={joinClasses([pageUtils.searchBoxClasses.searchContainer])}
				style={{ background: '#F4F4F4' }}
			>
				<ReactCollapse isOpened={isSearchBoxOpen}>
					<section
						className={joinClasses([
							pageUtils.searchBoxClasses.section,
							requests.length === 0
								? pageUtils.searchBoxClasses.sectionBottom
								: ''
						])}
					>
						<SearchRequests
							isLoading={isLoading}
							isHeader
							showClearTextIcon
							onSetIsLoading={setIsLoading}
							onSetRequests={setRequests}
							onNavigateToRequests={onNavigateToRequests}
							searchText={searchText}
							setSearchText={setSearchText}
							isSearchBoxOpen={isSearchBoxOpen}
						/>
					</section>
					<ReactCollapse isOpened={isLoading}>
						<section>
							<Loader text={pageData?.assets?.loading} />
						</section>
					</ReactCollapse>

					<ReactCollapse
						isOpened={requests.length === 0 && !isLoading && searchText !== ''}
					>
						<section
							className={joinClasses([
								pageUtils.searchBoxClasses.section,
								pageUtils.searchBoxClasses.sectionMobile,
								pageUtils.searchBoxClasses.sectionNoRequestFound
							])}
						>
							<Text
								content={
									pageData?.assets?.page_resultsRequests_no_request_found
								}
							/>
						</section>
					</ReactCollapse>

					<ReactCollapse isOpened={requests.length > 0 && !isLoading}>
						<section
							className={joinClasses([
								pageUtils.searchBoxClasses.section,
								pageUtils.searchBoxClasses.sectionMobile
							])}
						>
							{requests.slice(0, 5).map((request: Request, key: number) => (
								<RequestListCard
									id={key}
									request={request}
									index={key}
									requestThemes={pageData?.collections?.requestThemes}
								/>
							))}

							<button
								onClick={onNavigateToRequests}
								className={pageUtils.searchBoxClasses.btnSeeAllResults}
							>
								{formatStrapiText(pageData?.assets?.seeAllResults)}
							</button>
						</section>
					</ReactCollapse>
				</ReactCollapse>
			</div>
		</div>
	)
}

export default SearchBoxHeader
