import React, { FC } from 'react'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'
import Breakpoints from '@utils/breakpoints'

type LoaderProps = {
	text: string
}

type ClassType = {
	container: string
	loader: string
}

const classes: ClassType = makeClasses({
	container: {
		display: 'flex',
		flexDirection: 'column' as any,
		width: '100%',
		maxWidth: '700px',
		padding: '40px 70px',
		margin: 'auto',
		color: '#0a0a0a',
		[Breakpoints.maxWidth('lg')]: {
			padding: '50px 20px 20px 20px'
		}
	},
	loader: {
		border: `4px solid ${Colors.lightGrey}`,
		borderTop: `4px solid ${Colors.blue}`,
		borderRadius: '50%',
		width: '26px',
		height: '26px',
		margin: '15px',
		animation: 'spin 2s linear infinite'
	}
})

const Loader: FC<LoaderProps> = ({ text }) => {
	return (
		<div className={classes.container}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<div className={`${classes.loader} spin`} />
				<span style={{ fontSize: '1rem' }}>{text}</span>
			</div>
		</div>
	)
}

export default Loader
