import React, { FC, useContext, useEffect, useState } from 'react'
import { joinClasses, makeClasses } from '@utils/styles'
import * as formUtils from '@templates/requests/__create.utils'
import parse from 'html-react-parser'
import Collapse from '@components/ui/collapse'
import {
	AppContextProps,
	AppStateContext
} from '@components/layouts/DynamicLayout'
import { formatHtmlForStrapiText, formatStrapiText } from '@utils/methods'
import Modal from '@components/ui/modal'
import {
	classes,
	getSessionConsent,
	setSessionConsent
} from './__cookiesConsentBanner.utils'
declare global {
    interface Window {
        gtag?: (...args: any[]) => void;
    }
}


const CookiesConsentBanner: FC = ({}) => {
	const [isPreferencesOpen, setIsPreferencesOpen] = useState<boolean>(false)
	const { pageData } = useContext<AppContextProps>(AppStateContext)
	const [cookiesEnabled, setCookiesEnabled] = useState<boolean>(
		getSessionConsent()
	)
	const [analyticsConsent, setAnalyticsConsent] = useState<boolean>(false)

	useEffect(() => {
		let consentGiven = null;
		const consentString = localStorage.getItem('consent');

		if(consentString){
			consentGiven = JSON.parse(consentString);
		}

        if (consentGiven !== null) {
            setAnalyticsConsent(consentGiven);
        }
    }, []);

	//sets cookie value without use effect. The cookies are enabled only if true. Else the cookie isnt created.
	const onSelectCookiesEnabled = (value: boolean) => {
		setCookiesEnabled(value)

		if (value) {
			setSessionConsent()
		}
		// Reload the page to update GA
		setTimeout(() => {window.location.reload()}, 100)
	}

	const onSelectAnalyticsConsent = (value: boolean) => {
		setAnalyticsConsent(value)

		localStorage.setItem('consent', JSON.stringify(value));
	}

	return (
		<>
			{!cookiesEnabled && (
				<div className={classes.consentWraper}>
					{!isPreferencesOpen && (
						<div className={classes.consentBanner}>
							<section className={classes.flexContainer}>
								<div className={classes.textContainer}>
									<b>{pageData?.assets?.label_consent_banner_title}</b>
									<br />
									{parse(
										formatHtmlForStrapiText(
											pageData?.assets?.label_consent_banner_message
										)
									)}
								</div>

								<div className={classes.buttonContainer}>
									<a
										className={joinClasses([
											formUtils.classes.btn,
											formUtils.classes.button,
											formUtils.classes.submitBtn
										])}
										onClick={() => {
											onSelectCookiesEnabled(true)
										}}
									>
										{pageData?.assets?.label_accept_cookies}
									</a>
									<a
										className={joinClasses([
											formUtils.classes.btn,
											formUtils.classes.button,
											formUtils.classes.buttonOutline
										])}
										style={{ marginLeft: '10px' }}
										onClick={() => {
											setIsPreferencesOpen(true)
										}}
									>
										{pageData?.assets?.page_preferences_my_prefs}
									</a>
								</div>
							</section>
						</div>
					)}
					<Modal
						isOpen={isPreferencesOpen}
						onCancelBtn={() => {
							setIsPreferencesOpen(false)
						}}
						onCloseModal={() => setIsPreferencesOpen(false)}
						onSetIsOpen={() => {}}
						showExitBtn
						title={<b>{pageData?.assets?.label_consent_banner_title}</b>}
					>
						<>
							{parse(
								formatHtmlForStrapiText(
									pageData?.assets?.label_consent_banner_message
								)
							)}
							<div className={classes.checkboxWrapper}>
								<label className={classes.checkBoxLabel}>
									<input
										type="checkbox"
										checked
										disabled
										className={joinClasses([
											classes.checkBox,
											classes.checkboxChecked
										])}
									/>
									<b>
										{pageData?.assets?.label_consent_banner_functionnal_tools}
									</b>
									<span>
										{pageData?.assets?.label_cookies_consent_always_active}
									</span>
								</label>
								<div>
									<p>
										{
											pageData?.assets
												?.label_consent_banner_functionnal_tools_description
										}
									</p>
								</div>
							</div>
							<div className={classes.checkboxWrapper}>
								<label className={classes.checkBoxLabel}>
									<input
										type="checkbox"
										checked={analyticsConsent}
										onClick={(e: any) => {
											onSelectAnalyticsConsent(e.target.checked)
										}}
										className={classes.checkBox}
									/>
									<b> {pageData?.assets?.label_consent_banner_analytics}</b>
								</label>
								<div>
									<p>
										{
											pageData?.assets
												?.label_consent_banner_analytics_description
										}
									</p>
								</div>
							</div>

							<div
								className={classes.buttonContainer}
								style={{ marginTop: '20px', gap: '10px' }}
							>
								<a
									className={joinClasses([
										formUtils.classes.btn,
										formUtils.classes.button,
										formUtils.classes.buttonOutline
									])}
									onClick={() => {
										onSelectCookiesEnabled(true)
									}}
									style={{ margin: '0' }}
								>
									{pageData?.assets?.label_accept_all}
								</a>
								<a
									className={joinClasses([
										formUtils.classes.btn,
										formUtils.classes.button,
										formUtils.classes.submitBtn
									])}
									onClick={() => {
										setIsPreferencesOpen(!isPreferencesOpen)
									}}
									style={{ margin: '0' }}
								>
									{pageData?.assets?.label_save_preferences}
								</a>
							</div>
						</>
					</Modal>
				</div>
			)}
		</>
	)
}

export default CookiesConsentBanner
