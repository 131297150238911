import React, { FC, ReactElement } from 'react'
import Link from '@components/ui/link'
import Authentication, {
	AuthenticationType
} from '@components/auth/Authentication'

type Props = {
	text: string | ReactElement
	className?: string
}

export const LoginButton: FC<Props> = ({ className, text }) => {
	const { authenticationSignin }: AuthenticationType = Authentication()

	return (
		<Link
			className={className}
			to="#"
			onClick={authenticationSignin}
			key="loginRedirect"
			rel="noopener noreferrer"
		>
			{text}
		</Link>
	)
}
