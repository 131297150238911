// BOUNDARY_BOX OF LAVAL
export default {
	MUNICIPALITY_BOUNDARY_BOX: {
		latitude: {
			min: 45.5109049,
			max: 45.7007485
		},
		longitude: {
			min: -73.8953597,
			max: -73.5235036
		}
	},
	MUNICIPALITY_ADDRESS: {
		city: 'Laval',
		country: 'Canada',
		state: 'QC'
	}
}
