import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IRequestListStore } from './type'

const initialState: IRequestListStore = {
	fetching: false,
	fetched: false,
	requests: []
}

export const slice = createSlice({
	name: 'requestList',
	initialState,
	reducers: {
		setRequestsFetching: (state, action: PayloadAction<boolean>) => {
			state.fetching = action.payload
		},
		setRequestsFetched: (state, action: PayloadAction<boolean>) => {
			state.fetched = action.payload
		},
		setRequestListStore: (
			state,
			action: PayloadAction<Partial<IRequestListStore>>
		) => {
			Object.assign(state, action.payload)
		}
	}
})

export default slice.reducer

export const { setRequestsFetching, setRequestsFetched, setRequestListStore } =
	slice.actions
