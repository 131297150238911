import Breakpoints from '@utils/breakpoints'
import { makeClasses } from '@utils/styles'
import { ButtonStyle, Colors } from '@utils/css-variables'
import { QuickAccess } from 'typings/shared'

export type HomepageBannerProps = {
	isUserConnected?: boolean
}

export type HomepageQuickAccessProps = {
	quickAccesses: QuickAccess[]
}

export type HomepageSidebarContentProps = {}

export type HomepageSidebarActivitiesProps = {}

export type HomepageInfoProps = {}

type HomepageBannerClassesType = {
	root: string
	sectionTitle: string
	titleColor: string
	buttonWrapper: string
	button: string
	btnTextNoMobile: string
	btnTextMobile: string
	breakTextMobile: string
	searchResultsSection: string
	searchResults: string
}

export const homepageBannerClasses: HomepageBannerClassesType = makeClasses({
	root: {
		p: {
			margin: 0
		}
	},
	sectionTitle: {
		padding: '70px 40px!important',
		h1: {
			fontSize: '46px',
			lineHeight: '1.1',
			fontWeight: 700
		},
		[Breakpoints.maxWidth('xl')]: {
			padding: '70px 30px!important'
		},
		[Breakpoints.maxWidth('laptop')]: {
			justifyContent: 'center'
		},
		[Breakpoints.maxWidth('md')]: {
			padding: '40px 20px!important',
			h1: {
				fontSize: '42px'
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			h1: {
				fontSize: '26px',
				textAlign: 'center'
			}
		}
	},
	titleColor: {
		color: Colors.lightBlue
	},
	buttonWrapper: {
		display: 'flex',
		alignItems: 'center',
		marginTop: '25px',
		'& > a:first-child': {
			marginRight: '10px'
		},
		[Breakpoints.maxWidth('laptop')]: {
			justifyContent: 'center'
		},
		[Breakpoints.minWidth('md')]: {
			justifyContent: 'start'
		}
	},
	button: {
		...ButtonStyle,
		borderColor: Colors.white,
		color: Colors.white,
		fontSize: '16px',
		padding: '15px 30px',
		'&:hover': {
			background: Colors.white,
			color: Colors.secondary
		},
		'&:active': {
			borderColor: Colors.white,
			color: Colors.white
		},
		'&:visited': {
			borderColor: Colors.white,
			color: Colors.white
		}
	},
	btnTextNoMobile: {
		display: 'block',
		[Breakpoints.maxWidth('sm')]: {
			display: 'none'
		}
	},
	btnTextMobile: {
		display: 'none',
		[Breakpoints.maxWidth('sm')]: {
			display: 'block'
		}
	},
	breakTextMobile: {
		[Breakpoints.maxWidth('sm')]: {
			display: 'block'
		}
	}
})

type SearchResultSectionClassesType = {
	searchResults: string
	searchResultsSection: string
	sectionPadding: string
	btnSeeAllResults: string
	sectionNoRequestFound: string
}

export const searchResultSectionClasses: SearchResultSectionClassesType =
	makeClasses({
		searchResultsSection: {
			position: 'relative',
			width: '100%'
		},
		searchResults: {
			position: 'absolute',
			top: '-40px',
			left: '25px',
			width: 'calc(95%)',
			zIndex: 1,
			backgroundColor: '#F4F4F4',
			borderRadius: '5px',
			boxShadow:
				'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
			overflow: 'auto',
			maxHeight: 'calc(400px)',
			[Breakpoints.maxWidth('md')]: {
				top: '-10px',
				left: '18px',
				width: 'calc(95%)'
			},
			[Breakpoints.maxWidth('sm')]: {
				width: 'calc(93%)'
			}
		},
		sectionPadding: {
			padding: '32px 25px'
		},
		btnSeeAllResults: {
			...ButtonStyle,
			marginTop: '30px',
			borderColor: Colors.secondary,
			background: Colors.secondary,
			color: Colors.white,
			padding: '15px 30px',
			fontSize: '16px',
			'&:hover': {
				cursor: 'pointer',
				background: Colors.darkBlue2,
				borderColor: Colors.darkBlue2
			}
		},
		sectionNoRequestFound: {
			padding: '35px 30px',
			'& h3': {
				margin: '0px 0 15px '
			}
		}
	})

type QuickLinksClassesType = {
	quickLinksContainer: string
	quickLinks: string
}

const quickLinksClasses = {
	quickLinksContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		margin: '0 0 30px',
		'&.quick-access': {
			[Breakpoints.maxWidth('xl')]: {
				display: 'none'
			}
		},
		'&.activities': {
			display: 'none',
			[Breakpoints.maxWidth('xl')]: {
				position: 'relative',
				top: '-30px',
				display: 'flex',
				width: '100%',
				maxWidth: 'calc(50% - 30px)'
			},
			[Breakpoints.maxWidth('md')]: {
				flexDirection: 'column',
				maxWidth: '100%',
				top: 'auto'
			}
		}
	},
	quickLinks: {
		background: Colors.white,
		textDecoration: 'none',
		padding: '30px 30px',
		width: 'calc(50% - 10px)',
		display: 'flex',
		alignItems: 'center',
		gap: '20px',
		cursor: 'pointer',
		transition: '0.3s',
		marginBottom: '20px',
		fontSize: '20px',
		fontWeight: 500,
		minHeight: '107px',
		color: `${Colors.black}!important`,
		img: {
			width: '40px'
		},
		[Breakpoints.maxWidth('xxl')]: {
			fontSize: '18px',
			minHeight: '102px'
		},
		'&:hover': {
			background: Colors.secondary,
			color: `${Colors.white}!important`,
			transition: '0.3s'
		},
		'&.activites-item': {
			[Breakpoints.maxWidth('xl')]: {
				width: '100%',
				padding: '20px 30px',
				minHeight: 'auto',
				img: {
					width: '30px'
				}
			}
		},
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '16px'
		}
	}
}

type HomepageQuickAccessClassesType = QuickLinksClassesType & {
	root: string
	sectionTitle: string
	list: string
	item: string
	loader: string
}

export const homepageQuickAccessClasses: HomepageQuickAccessClassesType =
	makeClasses({
		root: {
			flex: '0 0 calc((8/12)*100%)',
			maxWidth: 'calc((8/12)*100%)',
			padding: '0 15px',
			[Breakpoints.maxWidth('xl')]: {
				flex: '0 0 100%',
				maxWidth: '100%'
			},
			p: {
				margin: 0
			}
		},
		sectionTitle: {
			color: Colors.darkBlue1,
			fontSize: '28px',
			lineHeight: '31px',
			fontWeight: 500,
			padding: 0,
			margin: '0 0 30px'
		},
		list: {
			display: 'flex',
			flexWrap: 'wrap',
			width: '100%',
			maxWidth: '100%',
			justifyContent: 'flex-start',
			gap: '15px',
			marginBottom: '35px'
		},
		item: {
			width: '100%',
			maxWidth: 'calc(33.33% - 10px)',
			padding: '30px 20px',
			background: Colors.white,
			cursor: 'pointer',
			textDecoration: 'none',
			img: {
				display: 'block',
				width: '100%',
				maxWidth: '40px',
				height: 'auto',
				margin: '0 auto 25px'
			},
			p: {
				textAlign: 'center',
				margin: 0,
				'&.title': {
					fontSize: '20px',
					fontWeight: 500,
					color: Colors.black2
				},
				'&.description': {
					color: Colors.darkGrey,
					fontSize: '12px',
					lineHeight: 1.6,
					marginTop: '10px'
				}
			},
			'&:hover': {
				background: Colors.secondary,
				transition: '0.3s',
				p: {
					color: Colors.white
				}
			},
			[Breakpoints.widthBetween('xl', 'xxl')]: {
				maxWidth: 'calc(33.33% - 10px)'
			},
			[Breakpoints.maxWidth('md')]: {
				maxWidth: 'calc(50% - 10px)'
			},
			[Breakpoints.maxWidth('sm')]: {
				maxWidth: 'calc(50% - 10px)',
				img: {
					margin: '0 auto 15px',
					maxWidth: '32px'
				},
				p: {
					'&.title': {
						fontSize: '18px'
					}
				}
			}
		},
		loader: {
			position: 'absolute' as 'absolute',
			border: `4px solid ${Colors.lightGrey}`,
			borderTop: `4px solid ${Colors.blue}`,
			borderRadius: '50%',
			width: '26px',
			height: '26px',
			animation: 'spin 2s linear infinite'
		},
		...quickLinksClasses
	})

type HomepageSidebarContentClassesType = {
	root: string
	container: string
	title: string
	content: string
	ul: string
	li: string
	liImg: string
	link: string
}

const sidebarContentDefaultStyle = {
	root: {
		position: 'relative',
		flex: '0 0 calc((4/12)*100%)',
		maxWidth: 'calc((4/12)*100%)',
		padding: '0 15px',
		[Breakpoints.maxWidth('xl')]: {
			flex: '0 0 100%',
			maxWidth: '100%'
		},
		p: {
			margin: 0
		}
	},
	container: {
		position: 'absolute',
		top: '-140px',
		left: '15px',
		width: '100%',
		maxWidth: 'calc(100% - 30px)',
		[Breakpoints.maxWidth('xxl')]: {
			top: '-110px'
		},
		[Breakpoints.maxWidth('xl')]: {
			// 	maxWidth: 'calc(50% - 30px)',
			// 	left: 'auto',
			// 	right: '15px'
			// },
			// [Breakpoints.maxWidth('md')]: {
			position: 'relative',
			top: 'auto',
			left: 'auto',
			right: 'auto',
			maxWidth: '100%'
		}
	},
	title: {
		display: 'flex',
		alignItems: 'center',
		color: Colors.white,
		background: Colors.secondary,
		padding: '30px',
		gap: '15px',
		fontSize: '20px',
		fontWeight: 500,
		img: {
			width: '100%',
			maxWidth: '40px'
		},
		[Breakpoints.maxWidth('xxl')]: {
			padding: '20px 30px',
			fontSize: '17px',
			img: {
				maxWidth: '30px'
			}
		},
		[Breakpoints.maxWidth('md')]: {
			fontSize: '20px'
		},
		[Breakpoints.maxWidth('sm')]: {
			fontSize: '16px'
		}
	},
	content: {
		color: Colors.black2,
		background: Colors.white,
		padding: '30px',
		p: {
			lineHeight: '1.62em',
			margin: 0
		}
	}
}

export const homepageSidebarContentClasses: HomepageSidebarContentClassesType =
	makeClasses({
		...sidebarContentDefaultStyle,
		container: {
			...sidebarContentDefaultStyle.container,
			[Breakpoints.maxWidth('xl')]: {
				position: 'relative',
				maxWidth: '100%',
				top: 'unset',
				left: 'unset',
				margin: 0
			}
		},
		ul: {
			listStyle: 'none',
			padding: 0,
			margin: '20px 0',
			fontSize: '14px',
			lineHeight: '22px',
			background: Colors.white
		},
		li: {
			display: 'flex',
			alignItems: 'flex-start',
			marginBottom: '8px',
			paddingLeft: 0
		},
		liImg: {
			width: '20px',
			marginTop: '1px',
			marginRight: '5px'
		},
		link: {
			color: Colors.secondary,
			cursor: 'pointer',
			'&:hover': {
				textDecoration: 'underline'
			}
		}
	})

type HomepageSidebarActivitiesClassesType = QuickLinksClassesType & {
	root: string
	rootActivities: string
	containerActivities: string
	container: string
	title: string
	content: string
	list: string
	item: string
	date: string
	link: string
	contentLoading: string
	collapseContent: string
}

export const homepageSidebarActivitiesClasses: HomepageSidebarActivitiesClassesType =
	makeClasses({
		...sidebarContentDefaultStyle,
		...quickLinksClasses,
		rootActivities: {
			display: 'flex',
			flexDirection: 'column-reverse'
		},
		containerActivities: {
			marginBottom: '30px'
		},
		item: {
			padding: '0 0 30px',
			lineHeight: '1.62em'
		},
		date: {
			fontSize: '14px',
			fontWeight: 600,
			color: Colors.darkGrey2,
			marginBottom: '5px'
		},
		link: {
			color: Colors.secondary,
			textDecoration: 'none',
			'&:hover': {
				textDecoration: 'underline'
			}
		},
		contentLoading: {
			padding: '0 0 30px'
		},
		collapseContent: {
			maxHeight: '650px',
			overflow: 'auto',
			[Breakpoints.maxWidth('xl')]: {
				maxHeight: '300px'
			}
		}
	})

export type strapiRes = {
	id: number
	attributes: {
		cacher: string
		createdAt: string
		description: string
		locale: string
		order: number
		publishedAt: string
		title: string
		updatedAt: string
	}
}

export type strapiDataRes = {
	data: strapiRes[]
	meta: {
		pagination: {
			pageCount: number
		}
	}
}
export const REQUEST_HIDDEN = {
	Oui: true,
	Non: false
}
type HomepageInformationClassesType = {
	root: string
	rootImg: string
}

export const homepageInformationClasses: HomepageInformationClassesType =
	makeClasses({
		root: {
			padding: '20px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			gap: '10px'
		},
		rootImg: {
			width: '25px'
		},
		Info: {
			background: Colors.backgroundConnected
		},
		Alert: {
			background: `${Colors.lightYellow}80`
		}
	})

export enum STATUS_ID {
	CREATION = '1e8aaa1c-ec9f-e811-a972-000d3af49637',
	ASSIGNATION = 'b054ed2f-099c-e811-a971-000d3af49373',
	RESOLUTION = '0946c292-18b1-e811-a977-000d3af49637',
	IN_THE_PROCESS = '45fc51c5-28b6-e811-a970-000d3af49c44',
	CLOSE = '256fbd5e-149c-e811-a96c-000d3af49211'
}

export const PageAssetsStatusLabels = {
	[STATUS_ID.CREATION]: 'homepage_recent_activities_creation',
	[STATUS_ID.ASSIGNATION]: 'homepage_recent_activities_summons',
	[STATUS_ID.RESOLUTION]: 'homepage_recent_activities_resolution',
	[STATUS_ID.IN_THE_PROCESS]: 'homepage_recent_activities_inTheProcess',
	[STATUS_ID.CLOSE]: 'homepage_recent_activities_finished'
}

export const TimelineLabel = {
	[STATUS_ID.CREATION]: 'label_you_have_submitted_the_request',
	[STATUS_ID.ASSIGNATION]:
		'label_the_status_of_the_request_has_been_changed_to_processing',
	[STATUS_ID.RESOLUTION]: 'label_a_treatment_update_is_available',
	[STATUS_ID.IN_THE_PROCESS]: 'label_a_treatment_update_is_available',
	[STATUS_ID.CLOSE]:
		'label_the_status_of_the_request_has_been_changed_to_closed'
}
