import axios, { AxiosError } from 'axios'
import apiClient from '@services/api.client'
import { acquireAccessToken } from '@utils/authConfig'
import { IRequestDTO } from '@services/dtos/requests.dto'
import { ITimelineDTO } from '@services/dtos/timeline.dto'
import { AxiosResponse, ITimelineRepository } from '@services/types'
import { BaseRepository } from '@services/repositories/_miscellaneous.repository'
import { ICommentsAttachmentDTO } from '@services/dtos/comments.dto'

export default class TimelineRepository
	extends BaseRepository
	implements ITimelineRepository
{
	private _secondaryResource

	constructor() {
		super()
		this.resource = 'timeline'
		this._secondaryResource = {
			attachments: 'attachments'
		}
	}

	public getTimeline = async (
		id: IRequestDTO['incident_id']
	): Promise<ITimelineDTO[]> => {
		try {
			const response: AxiosResponse<ITimelineDTO[]> = await apiClient.get('', {
				params: {
					resource: this.resource,
					params: this.stringify({
						incident_id: id!,
						include_attachments_metadata: 'true'
					})
				},
				headers: {
					token: await acquireAccessToken()
				}
			})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching the timeline: ' + error.message)
			}

			throw error
		}
	}

	public getAttachmentsFiles = async (id) => {
		try {
			const response: AxiosResponse<ICommentsAttachmentDTO> =
				await apiClient.get('', {
					params: {
						resource: this._secondaryResource.attachments,
						resourceId: id
					},
					headers: {
						token: await acquireAccessToken()
					}
				})

			return response.data
		} catch (error: unknown | Error | AxiosError) {
			if (axios.isAxiosError(error)) {
				throw new Error('Error while fetching the file: ' + error.message)
			}

			throw error
		}
	}
}
