// A contact is a folder containing all the information of a user in Dynamics
// we access it through our API.
// TODO: update the verisoning
import assert from 'assert'
import apiClient from '@services/api.client'
import Case from 'case'
import { CONTACT_FIELDS_SET, IContactDTO } from '@services/dtos/contacts.dto'
import {
	AxiosResponse,
	BackendPostResponseType,
	ContactResponseErrorType
} from '@services/types'
import { acquireAccessToken } from '@utils/authConfig'
import { BaseRepository } from '@services/repositories/_miscellaneous.repository'

export default class ContactsRepository extends BaseRepository {
	constructor() {
		super()
		this.resource = 'contacts'
	}

	public postContact = async (contactDTO: IContactDTO): Promise<string> => {
		if (!contactDTO.external_user_identifier) {
			throw new Error(
				'Can not perform contact update without an external_user_identifier'
			)
		}

		const response: AxiosResponse<
			BackendPostResponseType | ContactResponseErrorType
		> = await apiClient.post('', contactDTO, {
			params: {
				resource: this.resource
			},
			headers: {
				token: await acquireAccessToken()
			}
		})

		let content: BackendPostResponseType | ContactResponseErrorType =
			response?.data

		if (
			!(content as ContactResponseErrorType).IsValid &&
			(content as ContactResponseErrorType).Results?.length
		) {
			const contentError = content as ContactResponseErrorType
			let errorMessage = ''

			for (const errorResult of contentError.Results) {
				errorMessage += errorResult.ErrorMessage + '; '
			}

			throw new Error(errorMessage)
		}

		return (content as BackendPostResponseType).resourceid
	}

	public getContact = async (id: string): Promise<IContactDTO> => {
		const response: AxiosResponse<IContactDTO> = await apiClient.get('', {
			params: {
				resource: this.resource,
				resourceId: id
			},
			headers: {
				token: await acquireAccessToken()
			}
		})

		return response?.data
	}

	public searchContactsBy = async (
		field: string,
		value: number | string
	): Promise<IContactDTO[]> => {
		const fieldSnake: string = Case.snake(field)

		assert(CONTACT_FIELDS_SET.has(fieldSnake))

		const response: AxiosResponse<IContactDTO[]> = await apiClient.get('', {
			params: {
				resource: this.resource,
				params: this.stringify({
					[fieldSnake]: value
				})
			},
			headers: {
				token: await acquireAccessToken()
			}
		})

		return response?.data
	}

	public patchContact = async (
		id: string,
		contactDTO: IContactDTO
	): Promise<IContactDTO> => {
		const response: AxiosResponse<IContactDTO> = await apiClient.patch(
			'',
			contactDTO,
			{
				params: {
					resource: this.resource,
					resourceId: id
				},
				headers: {
					token: await acquireAccessToken()
				}
			}
		)

		return response?.data
	}
}
