import * as Yup from 'yup'
import { CancelRequestReason } from '@services/constants'

export const SignupSchema = Yup.object().shape({
	closeReason: Yup.number()
		.required('required_text')
		.positive()
		.integer()
		.oneOf([
			CancelRequestReason.OTHER,
			CancelRequestReason.PROBLEM_SOLVED,
			CancelRequestReason.REQUEST_NOT_VALID
		])
})
