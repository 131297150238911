import React, { FC, useEffect, useRef, useState, useLayoutEffect } from 'react'

import '@components/layouts/Fonts.css'
import '@components/layouts/global.scss'

import Breakpoints from '@utils/breakpoints'
import { Icon } from '@components/ui/Icon'
import { monDossierLogoIcon } from '@images/icons'
import { makeClasses } from '@utils/styles'
import { Colors } from '@utils/css-variables'

type ClassType = {
	root: string
	sideLogoWrapper: string
	logo: string
	content: string
	container: string
}

const classes: ClassType = makeClasses({
	root: {
		display: 'flex',
		width: '100vw',
		[Breakpoints.maxWidth('lg')]: {
			width: '100%',
			flexDirection: 'column'
		}
	},
	container: {
		marginTop: '70px',
		background: Colors.white,
		[Breakpoints.minWidth('lg')]: {
			marginLeft: '17rem',
			maxWidth: 'calc(100% - 17rem)',
			minHeight: 'calc(100vh - 80px)'
		},
		[Breakpoints.maxWidth('lg')]: {
			marginBottom: '66px'
		}
	},
	sideLogoWrapper: {
		width: 'calc(50vw - 30px)',
		backgroundColor: Colors.darkBlue1,
		display: 'flex',
		padding: '0 15px',
		[Breakpoints.maxWidth('lg')]: {
			width: 'calc(100% - 30px)',
			margin: 'auto',
			height: '25vh'
		},
		[Breakpoints.maxWidth('xs')]: {
			display: 'none'
		}
	},
	logo: {
		maxWidth: '600px',
		width: '100%',
		margin: 'auto',
		[Breakpoints.maxWidth('lg')]: {
			width: '350px'
		},
		[Breakpoints.maxWidth('sm')]: {
			width: '250px'
		}
	},
	content: {
		height: '100vh',
		overflowY: 'scroll',
		width: '50%',
		display: 'flex',
		backgroundColor: 'white',
		[Breakpoints.maxWidth('lg')]: {
			width: '100%',
			overflowY: 'unset',
			height: 'unset'
		}
	}
})

const useWindowSize = () => {
	const [size, setSize] = useState([0, 0])

	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight])
		}

		window.addEventListener('resize', updateSize)
		updateSize()

		return () => window.removeEventListener('resize', updateSize)
	}, [])

	return size
}

const LayoutWithSideContentWithProvider: FC = ({ children }) => {
	const [width, height] = useWindowSize()
	const [sideLogoWrapperHeight, setLogoWrapperHeight] = useState(
		height / 2 - 30
	)
	const [sideSideContentHeight, setSideSideContentHeight] = useState(height)
	const sideLogoWrapperRef = useRef<HTMLDivElement>(null)
	const sideSideContentRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		setLogoWrapperHeight(
			sideLogoWrapperRef?.current ? sideLogoWrapperRef.current?.clientHeight : 0
		)

		setSideSideContentHeight(
			sideSideContentRef?.current ? sideSideContentRef.current?.clientHeight : 0
		)
	}, [width, height])

	const customHeight = (currentHeight: number): number => {
		if (width >= Breakpoints.values.lg) {
			return height
		}

		if (currentHeight < height - sideLogoWrapperHeight) {
			return height - sideLogoWrapperHeight
		}

		return currentHeight
	}

	return (
		<div className={classes.root}>
			<div className={classes.sideLogoWrapper} ref={sideLogoWrapperRef}>
				<Icon src={monDossierLogoIcon} className={classes.logo} />
			</div>
			<div
				className={classes.content}
				ref={sideSideContentRef}
				style={{ height: customHeight(sideSideContentHeight) }}
			>
				{children}
			</div>
		</div>
	)
}

export default LayoutWithSideContentWithProvider
